import React, { useState } from "react";
import { Grid, FormControl, Select, MenuItem} from "@mui/material";
import { useTheme } from "../assets/themeContext";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { BarChart} from "@mui/x-charts";
import { CircleDollarSign } from "lucide-react";

const RewardsBarChart: React.FC = () => {
  const { theme } = useTheme();
  // Example data
  const data = [100, 200, 300, 400, 500, 600, 700];
  const months = ["Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"];
  // Menu Options
  const filterOptions = [
    { id: "This Month" },
    { id: "Past 3 Months" },
    { id: "Past 6 Months" },
  ];

  const [filter, setFilter] = useState<string>(filterOptions[0].id);
  // Percentage Color
  const percentage = 2.3;
  const percentageColor = percentage > 0 ? "#05CD99" : "red";
  const percentageSymbol = percentage > 0 ? "+" : "";

  return (
    <Grid
      lg={12}
      md={12}
      sm={12}
      xs={12}
      xl={12}
      sx={{
        padding: "20px",
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
        marginBottom:"20px"
      }}
    >
      <Grid
        lg={12}
        sm={12}
        md={12}
        xl={12}
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid>
          <label
            style={{
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "24px",
              color: theme.palette.info.main,
            }}
          >
            Referral Rewards
          </label>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <Grid
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            {percentage > 0 ? (
              <ArrowDropUpIcon sx={{ color: "#1ED47B", fontSize: "18px" }} />
            ) : (
              <ArrowDropDownIcon color="error" sx={{ fontSize: "18px" }} />
            )}
            <label
              style={{
                fontSize: "12px",
                fontWeight: 700,
                lineHeight: "20px",
                color: percentageColor,
                padding: "1px 0px",
              }}
            >
              {percentageSymbol}
              {percentage}
              {"%"}
            </label>
          </Grid>
          <Grid>
            <FormControl size="small">
              <Select
                style={{ color: theme.palette.info.main, fontFamily: "Inter" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="standard"
                value={filter}
                label="month"
                onChange={(e) => setFilter(e.target.value)}
                disableUnderline
                sx={{
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&:hover": {
                    backgroundColor: "transparent", // Prevents background color change on hover
                  },
                  "& .MuiSelect-select": {
                    "&:focus": {
                      backgroundColor: "transparent", // Ensures the background color doesn't change on focus within the select
                    },
                  },
                  "& .MuiSelect-icon": {
                    color: theme.palette.info.main, // Change to desired color
                  },

                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  color: theme.palette.info.main,
                }}
              >
                {filterOptions.map((item) => (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                    sx={{
                      borderRadius: "1px",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: 600,
                      color: theme.palette.info.main,
                      backgroundColor: theme.palette.info.light,
                      "&.Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        color: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.main,
                          color: "#FFFFFF",
                        },
                      },
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    {item.id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid lg={12} sm={12} md={12} xl={12} xs={12} sx={{display:'flex', flexDirection:'row', gap:'10px', alignItems:'center', marginY:'5px'}}>
        <label style={{
          fontSize:'34px',
          lineHeight:'42px',
          fontWeight:700,
          color:theme.palette.primary.main,
        }}>₹ 117.5</label>
        <CircleDollarSign
          style={{
            width: "20px",
            height: "20px",
            color: "rgba(109, 30, 212, 1)",
          }}
          strokeWidth={1.6}
        />
      </Grid>
      <Grid lg={12} md={12} sm={12} xs={12} xl={12} sx={{display:"flex", flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
        <BarChart
          series={[{ data: data, type: "bar", color: '#A36AFF'}]}
          xAxis={[
            {
              scaleType: "band",
              data: months,
            },
          ]}
          slotProps={{
            bar: {
              clipPath: `inset(4px round 10px 10px 0px 0px)`,
            },
          }}
          leftAxis={null}
          height={350}
          width={380}
          sx={{
            rx: 15,
            //change left yAxis label styles
            "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
              fill: "#FFFFFF",
            },
            // change bottom label styles
            "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
              strokeWidth: 1,
            },
            // bottomAxis Line Styles
            "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
              strokeWidth: 0,
            },
            "& .MuiChartsAxis-tick": {
              strokeWidth: 0,
            },
            "& .MuiChartsAxis-tickLabel tspan": {
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 700,
              lineHeight: "20px",
              color: theme.palette.info.main,
              fill: theme.palette.info.main,
            }
          }}
        />
      </Grid>
    </Grid>
  );
};
export default RewardsBarChart;

///// TODO: A Diff Approach

// import * as React from "react";
// import {
//   BarPlot,
//   ChartsReferenceLine, ChartsTooltip,
//   ChartsXAxis,
//   ChartsYAxis,
//   useYScale,
//   ResponsiveChartContainer
// } from '@mui/x-charts';

// export default function Bars() {
//   const SlotBarElement = (props) =>{
//     const yAxisScale = useYScale('left_axis_id')
//     const yAxisValue = yAxisScale.invert(props.style.y.animation.to)
//     const isBelowBar = yAxisValue < 8.5
//     const color = isBelowBar ? '#ff0000' : '#00ff00'

//     // work around export of BarElement
//     return <rect
//       fill={color}
//       height={props.style.height.animation.to}
//       width={props.style.width.animation.to}
//       x={props.style.x.animation.to}
//       y={props.style.y.animation.to}
//     />
//   }

//   return (
//     <ResponsiveChartContainer
//         height={350}
//         series={[
//           { type: 'bar', data: [11, 7, 1, 9], yAxisKey: 'left_axis_id'},
//         ]}
//         xAxis={[{ scaleType: 'band', data: ['11/15', '11/16', '11/17', '11/18'] }]}
//         yAxis={[{ id: 'left_axis_id'}]}
//     >
//       <BarPlot slots={{bar: SlotBarElement}}/>
//       <ChartsXAxis />
//       <ChartsYAxis axisId="left_axis_id" position="left"/>
//       <ChartsReferenceLine label={'8.5'} labelAlign="end" y={8.5}/>
//       <ChartsTooltip/>
//     </ResponsiveChartContainer>
//   )