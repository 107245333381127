// reducers.js
import { combineReducers } from "redux";
import chooseRecSlice from "../slices/chooseRecSlice";


const rootReducer = combineReducers({
  chooseRecipient: chooseRecSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
