import * as React from "react";
import { useTheme } from "../assets/themeContext";
import RecipientsTable from "./RecipientsTable";
import {
  Grid,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddrecipientsModal from "./AddrecipientsModal";
const Addrecipients: React.FC = () => {
  const { theme } = useTheme();
  const [openRecipients, setOpenRecipients] = React.useState<boolean>(false);
  

  return (
    <Grid
      lg={12}
      sm={12}
      md={12}
      xs={12}
      xl={12}
      sx={{
        padding: "20px",
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <label
          style={{
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "32px",
            color: theme.palette.text.primary,
          }}
        >
          Recipients
        </label>
        <Button
          sx={{
            textAlign: "center",
            borderRadius: "30px",
            paddingX: " 18px",
            textTransform: "initial",
            cursor: "pointer",
            paddingY: "6px",
            bgcolor: "#F4F7FE",
            "&:hover": {
              backgroundColor: "#F4F7FE",
            },
          }}
          onClick={() => setOpenRecipients(true)}
        >
          <label
            style={{
              fontSize: "15px",
              fontWeight: 500,
              lineHeight: "32px",
              color: theme.palette.primary.main,
              cursor: "pointer",
            }}
          >
            Add Recipient
          </label>
        </Button>
      </Grid>
      <Grid marginTop={"20px"} lg={12} md={12} sm={12} xs={12} xl={12}>
        <RecipientsTable />
      </Grid>
      <Dialog
        open={openRecipients}
        onClose={() => setOpenRecipients(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{ sx: { borderRadius: "20px" } }}
      >
        <Box
          sx={{
            bgcolor: theme.palette.primary.dark,
            textAlign: "center",
            p: 2,
          }}
        >
          <label
            style={{
              color: theme.palette.primary.main,
              fontSize: "19px",
              fontWeight: 700,
            }}
          >
            {" "}
            Add Recipient
          </label>
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => setOpenRecipients(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon style={{color:theme.palette.text.primary}}/>
        </IconButton>
        <DialogContent sx={{ bgcolor: theme.palette.primary.dark}}>
          <AddrecipientsModal   setOpenRecipients={ setOpenRecipients}/>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default Addrecipients;
