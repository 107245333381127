import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#6D1ED4",
      light: "#D6B6FF",
      dark: "#f6f4f9",
    },
    secondary: {
      main: "#1F1F1F",
      light: "#474747",
    },
    error: {
      main: "#d32f2f",
    },

    info: {
      main: "#9CAEB8",
      light: "#fff",
      dark: "rgba(156, 174, 184, 0.1)",
    },

    text: {
      primary: "#1F1F1F",
      secondary: "#FFFFFF",
    },
    divider: "rgba(156, 174, 184, 0.5)",
    background: {
      default: "#111C44",
      paper: "#FFFFFF",
    },
    action: {
      selected: "0px 10px 10px rgba(0, 0, 0, 0.1)",
      active: "rgba(109, 30, 212, 0.6)",
      hover: "0px 18px 40px 0px rgba(112, 144, 176, 0.12)",
      disabled:'0px 2px 2px 0px rgba(109, 30, 212, 0.1)'
    },
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
});

export default lightTheme;
