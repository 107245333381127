const url = process.env.REACT_APP_API_URL

export const getUserDetails = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      if (!token) {
        throw new Error('Authentication token not found');
      }
      const response = await fetch(
        `${url}/user-api/api/user/`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization:`Bearer ${token}`
          },
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Could not fetch user details:", error);
      // Handle errors as appropriate for your application
      throw error;
    }
  };