import React, { useEffect, useState } from "react";
import { Grid, Box, Button, IconButton, useMediaQuery, useTheme as T } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import { useNavigate } from "react-router-dom";
import RefreshIcon from "../assets/icons/RefreshIcon";
import getExchangeRate from "../services/ExchangeApi";
import { setRate } from "../redux/slices/chooseRecSlice";
import { useDispatch } from "react-redux";
import ProgressLoader from "./Loader";

const LivePriceCard: React.FC = () => {
  const { theme } = useTheme();
  const t = T();
  const dispatch = useDispatch();
  const isMedium = useMediaQuery(t.breakpoints.down('lg'))
  const navigate = useNavigate();
  const [updateTime, setUpdateTime] = useState("");
  const [forexPrice, setForexPrice] = useState(82.75);
  const [loading, setLoading] = useState(false);
  const [lastSeconds, setLastSeconds] = useState<string>("0m");
  const [error, setError] = useState("");

  function convertIsoToUserLocal(isoTimestamp: string | number | Date) {
    // Create a Date object from the ISO timestamp
    const date = new Date(isoTimestamp);
 
    // Get the date components
    // const year = date.getFullYear();
    // const month = date.toLocaleString('default', { month: 'short' });
    // const day = date.getDate();
 
    // Get the time components
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
 
    // Get the timezone offset in minutes
    const offsetMinutes = date.getTimezoneOffset();
 
    // Adjust the time by the offset
    date.setMinutes(date.getMinutes() - offsetMinutes);
 
    // Format the date as a string with the desired format
    const localTimeString = `${hours}:${minutes} (${Intl.DateTimeFormat().resolvedOptions().timeZone})`;
 
    return localTimeString;
}

function getTimeDifferenceInMinutes(timeString: string) {
  const now:any = new Date(); // Get the current date and time
  const timeParts = timeString.split(':'); // Split the input string into hours and minutes

  // Create a new date object for the given time on the current date
  const timeDate: any = new Date(now.getFullYear(), now.getMonth(), now.getDate(), parseInt(timeParts[0]), parseInt(timeParts[1]));

  // Calculate the difference in minutes
  const diffMs = timeDate - now; // Difference in milliseconds
  const diffMins = Math.round(diffMs / 60000); // Convert milliseconds to minutes

  return -diffMins-1;
}

  const fetchExchangeRate = async () => {
    setLoading(true);
    try {
      const data = await getExchangeRate();
      const updateRate = data.rate.toFixed(2);
      setForexPrice(parseFloat(updateRate));
      dispatch(setRate(parseFloat(updateRate)+1))
      const isoTimestamp = data.timestamp;
      const localTimestamp = convertIsoToUserLocal(isoTimestamp+"Z");
      //console.log(getTimeDifferenceInMinutes(localTimestamp.split(" ")[0]))
      const seconds = getTimeDifferenceInMinutes(localTimestamp)
      // console.log(seconds)
      setUpdateTime(localTimestamp);
      setLastSeconds(`${seconds}m`);
    } catch (err) {
      setError("Failed to fetch exchange rate");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExchangeRate();
  }, []);

  const handleClick = () => {
    fetchExchangeRate();
  };
  return (
    <Grid
      sx={{
        paddingTop: "20px",
        paddingBottom: "10px",
        paddingX: "20px",
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
      }}
      lg={12}
      md={12}
      xs={12}
      sm={12}
      xl={12}
    >
      <Grid
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingX={'50px'}
        paddingY={'60px'}
        sx={{
          background: "linear-gradient(to bottom , #6D1ED4,#613E8E )",
          borderRadius: "18px",
        }}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        xl={12}
      >
        {loading ? (
          <Grid
            width={"100%"}
            height={"100%"}
            lg={12}
            sm={12}
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProgressLoader strokeColor="#CF9FFF" fillColor="#CF9FFF" />
          </Grid>
        ) : (
          <Grid display={"flex"} flexDirection={"column"} textAlign={"center"}>
            <label
              style={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px",
                color: "#ffffff",
                padding: "10px",
              }}
            >
              Every $ 1 exchanges at
            </label>
            <label
              style={{
                fontSize: "40px",
                fontWeight: 700,
                lineHeight: "24px",
                color: "#FFFFFF",
                padding: "8px",
              }}
            >
              ₹ {forexPrice + 1}
            </label>
            <label
              style={{
                fontSize: "10px",
                fontWeight: 500,
                lineHeight: "24px",
                color: "#FFFFFF",
                marginTop: "10px",
                padding: "2px 10px",
                borderRadius: "20px",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
              }}
            >
              Other Apps best offer: ₹ {forexPrice}
            </label>
          </Grid>
        )}
      </Grid>

      <Grid marginTop={"20px"} lg={11} md={11} sm={11} xs={11}>
        <label
          style={{
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "30px",
            color: theme.palette.primary.main,
          }}
        >
          Current CAD Exchange Rate
        </label>
        <Grid
          display={"flex"}
          flexDirection={"row"}
          gap={"12px"}
          alignItems={"center"}
        >
          <Box>
            <label
              style={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                color: theme.palette.info.main,
              }}
            >
              Updated at {updateTime}
            </label>
          </Box>
          <IconButton
            sx={{
              cursor: "pointer",
              padding: "5px",
              "&:active": {
                bgcolor:'rgba(109, 30, 212, 0.3)', // Change the background color here
              },
            }}
            onClick={handleClick}
          >
            <RefreshIcon />
          </IconButton>
          <Box>
            <label
              style={{
                fontSize: "10px",
                fontWeight: 400,
                lineHeight: "20px",
                color: theme.palette.primary.main,
                backgroundColor: "rgba(109, 30, 212, 0.3)",
                padding: "5px 10px",
                borderRadius: "20px",
              }}
            >
              {lastSeconds}
            </label>
          </Box>
        </Grid>
      </Grid>

      <Grid
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box>
          <label
            style={{
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "24px",
              color: theme.palette.text.primary,
            }}
          >
            Forex price: ₹ {forexPrice}
          </label>
        </Box>
        <Button
          variant="contained"
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            color: "#FFFFFF",
            lineHeight: "24px",
            textAlign: "center",
            borderRadius: "16px",
            paddingX: " 20px",
            paddingY: "5px",
            marginY: "10px",
            textTransform: "initial",
            bgcolor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          onClick={() => navigate("/PaymentMethods")}
        >
          Transfer Now
        </Button>
      </Grid>
    </Grid>
  );
};
export default LivePriceCard;
