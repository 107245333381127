import React, { useEffect, useState } from "react";
import { Grid, Avatar, Divider } from "@mui/material";
import { UserRoundCog } from "lucide-react";
import { format } from "date-fns";
import { useTheme } from "../assets/themeContext";
import { getNotifications } from "../services/Notificationapis";
import ProgressLoader from "./Loader";

interface Activity {
  activityId: number;
  title: string;
  created_at: string; // Assuming it's a string representation of date
  notification_type: string; // Assuming it's a string
}

type MappedResult = {
  activityId: number;
  title: string;
  date: string;
  time: string;
  activityType: string;
};

const RecentActivity: React.FC = () => {
  const { theme } = useTheme();
  const [loading, setLoading] = useState(false);
  const [activityData, setActivityData] = useState<MappedResult[]>([]);

  const fetchActivity = async () => {
    setLoading(true);
    try {
      const result = await getNotifications();
      const mappedResult: MappedResult[] = result.map(
        (activity: Activity, index: number) => ({
          activityId: index,
          title: activity.title,
          date: format(new Date(activity.created_at), "dd MMM yyyy"),
          time: format(new Date(activity.created_at), "hh:mm a"),
          activityType: activity.notification_type,
        })
      );
      console.log(mappedResult);
      setActivityData(mappedResult);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivity();
  }, []);

  return (
    <Grid
      sx={{
        padding: "15px",
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
      }}
      lg={12}
      md={12}
      xs={12}
      sm={12}
      xl={12}
    >
      <Grid
        lg={12}
        md={12}
        xs={12}
        xl={12}
        sm={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <label
          style={{
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "32px",
            color: theme.palette.primary.main,
          }}
        >
          Recent Activity
        </label>
        <label
          style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
            color: theme.palette.primary.main,
          }}
        >
          See all
        </label>
      </Grid>
      {loading ? (
        <Grid
          width={"100%"}
          height={"100%"}
          lg={12}
          sm={12}
          xs={12}
          xl={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <ProgressLoader strokeColor="#6D1ED4" fillColor="#6D1ED4" />
        </Grid>
      ) : (
        <Grid marginTop={"20px"}>
          {activityData.map((item) => (
            <>
              <Grid
                key={item.activityId}
                sx={{
                  padding: "18px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <Avatar
                    sx={{
                      width: 66,
                      height: 66,
                      bgcolor: "rgba(109, 30, 212, 0.3)",
                    }}
                  >
                    <UserRoundCog
                      style={{
                        color: theme.palette.action.active,
                        height: "41px",
                        width: "41px",
                      }}
                    />
                  </Avatar>
                  <Grid sx={{ display: "flex", flexDirection: "column" }}>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "16px",
                        color: theme.palette.text.primary,
                        padding: "5px 0px",
                      }}
                    >
                      {item.title}
                    </label>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: 450,
                        lineHeight: "16px",
                        color: theme.palette.info.main,
                        padding: "5px 0px",
                      }}
                    >
                      {item.activityType}
                    </label>
                  </Grid>
                </Grid>
                <Grid sx={{ display: "flex", flexDirection: "column" }}>
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: theme.palette.info.main,
                      padding: "3px 0px",
                    }}
                  >
                    {item.date}
                  </label>
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: theme.palette.info.main,
                      display: "flex",
                      flexDirection: "row",
                      gap: "2px",
                      padding: "3px 0px",
                    }}
                  >
                    {item.time}
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "15px",
                        color: theme.palette.info.main,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      EST
                    </span>
                  </label>
                </Grid>
              </Grid>
              <Divider
                sx={{
                  borderColor:
                    item.activityId === 6 ? "null" : theme.palette.divider,
                  border: item.activityId === 6 ? "0px" : "1px light",
                  marginTop: "2px",
                }}
              />
            </>
          ))}
        </Grid>
      )}
    </Grid>
  );
};
export default RecentActivity;
