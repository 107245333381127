//const token = sessionStorage.getItem("access_token");
const url = process.env.REACT_APP_API_URL;

export const fetchPaymentMethods = async () => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(
      `${url}/payment-methods-api/api/payments/?skip=0&limit=4&is_active=true&is_deleted=false`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch payment methods");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching payment methods:", error);
    return null;
  }
};


  export const deletePaymentMethodApi = async (selectedPaymentMethodId) => {
    try {
      const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
      const response = await fetch(
        `${url}/payment-methods-api/api/payments/${selectedPaymentMethodId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
   
      if (!response.ok) {
        throw new Error('Failed to delete payment method');
      }
      return true; // Indicates successful deletion
    } catch (error) {
      console.error('Error deleting payment method:', error);
      throw error;
    }
  };
   
