import React from "react";
import { Grid, Box, Button } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import { Copy } from "lucide-react";
import { Share2 } from "lucide-react";

const ReferralLink: React.FC = () => {
  const { theme } = useTheme();

  // coppy to clipboard function
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(ReferLink)
      //   .then(() => alert(`Link copied to clipboard: ${ReferLink}`))
      .then(() =>
        setTimeout(function () {
          alert("Link copied to clipboard");
        }, 500)
      )
      .catch((error) => console.error("Error copying link: ", error));
  };

  const ReferLink = "www.aptremit.com/r/abc3456ycfarra";

  return (
    <Grid
      sx={{
        padding: "20px",
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
      }}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      xl={12}
    >
      <Grid sx={{ display: "flex", flexDirection: "column" }}>
        <label
          style={{
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "30px",
            color: theme.palette.primary.main,
          }}
        >
          Referral Link
        </label>
        <label
          style={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            color: theme.palette.info.main,
          }}
        >
          Share your referral link and start earning rewards every times your
          referral transfers money
        </label>
      </Grid>
      <Grid
        lg={12}
        md={12}
        sm={12}
        xs={12}
        xl={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          alignItems: "center",
          flexWrap: "wrap",
          marginTop: "20px",
        }}
      >
        <Grid lg={6} md={12} sm={12} xs={12} xl={8}>
          <Box
            sx={{
              bgcolor: theme.palette.info.dark,
              padding: "15.5px",
              borderRadius: "10px",
              marginY: "5px",
              maxWidth: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              [theme.breakpoints.down("sm")]: {
                maxWidth: "80%", // Adjust the maximum width for small devices
              },
            }}
          >
            <label
              style={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "19.36px",
                color: theme.palette.text.primary,
              }}
            >
              {ReferLink}
            </label>
          </Box>
        </Grid>
        <Grid sx={{ marginY: "5px" }}>
          <Button
            onClick={handleCopyClick}
            sx={{
              borderRadius: "10px",
              bgcolor: "#F4F7FE",
              padding: "15.5px",
              display: "flex",
              flexDirection: "row",
              gap: "15px",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "19.36px",
              color: "#6D1ED4",
              textTransform: "initial",

              "&:hover": {
                bgcolor: "#F4F7FE",
              },
            }}
          >
            <Copy
              style={{
                width: "15px",
                height: "15px",
                color: "rgba(109, 30, 212, 1)",
              }}
              strokeWidth={2.5}
            />
            Copy Link
          </Button>
        </Grid>
        <Grid sx={{ marginY: "5px" }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "10px",
              bgcolor: theme.palette.primary.main,
              paddingY: "15.5px",
              paddingX: "25px",
              display: "flex",
              flexDirection: "row",
              gap: "15px",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "19.36px",
              color: "#FFFFFF",
              textTransform: "initial",
              "&:hover": {
                bgcolor: theme.palette.primary.main,
              },
            }}
          >
            <Share2
              style={{
                width: "24px",
                height: "24px",
                color: "#FFFFFF",
              }}
              strokeWidth={2.5}
            />
            Share
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ReferralLink;
