import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  FormHelperText,
  FormLabel,
  Checkbox,
  useTheme as T,
  useMediaQuery,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTheme } from "../assets/themeContext";
import AuthBackground from "../components/AuthBackground";
import { Login } from "../services/Authapis";
import {toast, ToastContainer} from 'react-toastify'
import { persistor } from "../redux/store";

interface Errors {
  mail?: string | null;
  password?: string | null; // field for password validation error
}

const LoginForm: React.FC = () => {
  const { theme } = useTheme();
  const t = T();
  const is900Screen = useMediaQuery(t.breakpoints.down(900));
  const isSmallScreen = useMediaQuery(t.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<Errors>({});

  useEffect(()=>{
    document.title='AptRemit-Login'
    persistor.purge()
  })



  /* Validations */
  // Email Validation
  const validateEmail = (text: string): boolean => {
    // if there is no text on blur event
    if (!text) {
      setErrors((prev) => ({ ...prev, mail: "Email is required" }));
      return false;
      // check the mail pattern
    } else {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      // if mail pattern is incorrect
      if (!emailPattern.test(text)) {
        setErrors((prev) => ({ ...prev, mail: "Invalid email format" }));
        return false;
        // if mail pattern is correct
      } else {
        setErrors((prev) => ({ ...prev, mail: null }));
        return true;
      }
    }
  };

  // Password Validation
  const validatePassword = (text: string): boolean => {
    // if there is no text on blur event
    if (!text) {
      setErrors((prev) => ({ ...prev, password: "Password is required" }));
      return false;
    } else {
      if (text.length < 8) {
        // Password length validation
        setErrors((prev) => ({
          ...prev,
          password: "Password must be at least 8 characters long",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, password: null }));
        return true;
      }
    }
  };

  // password visability on click event
  function handleTogglePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  const handleLogin=async(event: React.FormEvent)=>{
    event.preventDefault();
    persistor.purge()
    if(!email || !password){
      toast.error('Fill the Fields')
      return;
    }
    try {
      const response = await Login({username: email, password: password});
      console.log('Response:', response);

      if (response.success) {
        sessionStorage.setItem('access_token', response.access_token);
        sessionStorage.setItem('refresh_token', response.refresh_token);
        setTimeout(() => {
          navigate("/home");
        }, 1000);
        toast.success('Login Successfull', {autoClose:2000})
        // console.log('token access:', response.access_token);
        // console.log('token refresh:', response.refresh_token);
        
        console.log( 
          'message:', response.message,
        );
      } else {
        // Check for specific error messages related to incorrect credentials
        console.log(
          'message:', response.message,
        );
        toast.error(response.message, {autoClose:2000})
      }
    } catch (error) {
      console.error('Login error:', error);
      // showMessage({
      //   message: 'An error occurred. Please try again later.',
      //   type: 'danger',
      // });
    }
  };
  

  return (
    <>
    <Grid
      container
      lg={12}
      sm={12}
      md={12}
      display={"flex"}
      flexDirection={"row"}
      sx={{ justifyContent: is900Screen ? "center" : null }}
    >
      <Grid
        lg={6}
        md={8}
        sm={12}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        height={is900Screen ? "100vh" : "null"}
      >
        <Grid
          lg={7}
          md={10}
          sm={12}
          width={isSmallScreen ? "90%" : "60%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Grid lg={12} display={"flex"} flexDirection={"column"}>
            <label
              style={{
                fontSize: "36px",
                fontWeight: 700,
                lineHeight: "56px",
                color: theme.palette.secondary.main,
                paddingBottom: "10px",
              }}
            >
              Sign In
            </label>
            <label
              style={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "16px",
                color: "rgba(156, 174, 184, 1)",
              }}
            >
              Enter your email and password to sign in!
            </label>
          </Grid>

          <Grid lg={12} marginY={"25px"}>
            {/* Input Elements */}
            <form onSubmit={handleLogin}>
              <Grid marginBottom={"20px"}>
                <FormLabel
                  htmlFor="Email"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "14px",
                    fontWeight: 500,
                    color: theme.palette.secondary.main,
                  }}
                >
                  Email
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      fontSize: "16px",
                      lineHeight: "16px",
                      fontWeight: 500,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <TextField
                  fullWidth
                  id="Email"
                  size="small"
                  variant="outlined"
                  name="email"
                  value={email}
                  type="email"
                  placeholder="mail@simmmple.com"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    validateEmail(e.target.value);
                  }}
                  onBlur={() => validateEmail(email)}
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                      padding: "3px",
                      marginTop: "5px",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: "1px  rgba(156, 174, 184, 0.5)",
                    },
                  }}
                />
                {errors.mail && (
                  <FormHelperText
                    sx={{ color: theme.palette.error.main, fontWeight: 520 }}
                  >
                    {errors.mail}
                  </FormHelperText>
                )}
              </Grid>
              <Grid>
                <FormLabel
                  htmlFor="Password"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "14px",
                    fontWeight: 500,
                    color: theme.palette.secondary.main,
                  }}
                >
                  Password
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      fontSize: "14px",
                      lineHeight: "14px",
                      fontWeight: 500,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <TextField
                  fullWidth
                  id="Password"
                  size="small"
                  variant="outlined"
                  name="password"
                  value={password}
                  type={showPassword ? "text" : "password"}
                  placeholder="Min. 8 characters"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    validatePassword(e.target.value);
                  }}
                  onBlur={() => validatePassword(password)}
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                      padding: "3px",
                      marginTop: "5px",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: "1px rgba(156, 174, 184, 0.5)",
                    },
                    endAdornment: (
                      <Button onClick={handleTogglePasswordVisibility}>
                        {showPassword ? (
                          <Visibility sx={{ color: "#9CAEB8" }} />
                        ) : (
                          <VisibilityOff sx={{ color: "#9CAEB8" }} />
                        )}
                      </Button>
                    ),
                  }}
                />
                <FormHelperText
                  sx={{ color: theme.palette.error.main, fontWeight: 520 }}
                >
                  {errors.password}
                </FormHelperText>
              </Grid>

              <Grid
                lg={12}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                pt={"15px"}
              >
                <Grid item lg={6} md={6} alignItems={"center"}>
                  <Checkbox
                    defaultChecked
                    id="checkBox"
                    size="small"
                    sx={{
                      paddingRight: "15px",
                      paddingLeft: "0px",
                      "&.Mui-checked": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                  <label
                    htmlFor="checkBox"
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: theme.palette.secondary.main,
                      lineHeight: "20px",
                    }}
                  >
                    Keep me logged in
                  </label>
                </Grid>
                <Grid
                  lg={6}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                >
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: theme.palette.primary.main,
                      lineHeight: "20px",
                      textAlign: "center",
                    }}
                  >
                    Forget password?
                    {/* should change to link or navigate on futher steps */}
                  </label>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                  color: theme.palette.text.secondary,
                  lineHeight: "16px",
                  textAlign: "center",
                  width: "100%",
                  borderRadius: "16px",
                  paddingX: "8px",
                  paddingY: "15px",
                  marginY: "20px",
                  bgcolor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                onClick={handleLogin}
                type="submit"
              >
                Sign In
              </Button>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "26px",
                  color: theme.palette.secondary.main,
                }}
              >
                Not registered yet?
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "26px",
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/SignUp")}
                >
                  {" "}
                  Create an Account
                </span>
              </label>
            </form>
            <Grid lg={12} textAlign={"center"} mt={"40px"}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "24px",
                }}
              >
                © 2024 AptRemit. All Rights Reserved.
              </label>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {is900Screen ? null : (
        <Grid lg={6} md={4}>
          <AuthBackground />
        </Grid>
      )}
       <ToastContainer/>
    </Grid>
    </>
  );
};

export default LoginForm;

