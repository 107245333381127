import React from 'react'
import { useNavigate, redirect } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import { getAccess } from '../services/Authapis';


interface JWT {
  exp: number;
  nbf: number;
  iat: number;
  sub: string;
}

interface AuthGuardProps {
    component: React.ComponentType<any>;
  }

  // Helper function to decode JWT and check if it's still active
const isTokenActive = (token: string): boolean => {
  const decoded = jwtDecode<JWT>(token);
  const currentTime = Date.now() 
  const tokenTime = decoded.exp * 1000
  // console.log(currentTime)
  // console.log(tokenTime)
  return tokenTime > currentTime 
};

const refreshAccessToken = async (refreshToken: string):Promise<void>=>{
  try {
      const response = await getAccess(refreshToken)
      // console.log(response)
      sessionStorage.setItem('access_token', response.accessToken);
  } catch (error) {
      console.error('Error refreshing access token:', error);
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('refresh_token');
      // navigate('/login', { replace: true }); // Redirect to login page on failure
  }
};
  
  const CheckAuthentication = () => {
    // Assume true for authenticated user. Replace with your actual logic.
    const accessToken = sessionStorage.getItem('access_token');
    const refreshToken  =sessionStorage.getItem('refresh_token')
   
    //condition for access token 
    if(accessToken){
      if(isTokenActive(accessToken)){
        return true
      }else if(refreshToken){
        if(isTokenActive(refreshToken)){
          refreshAccessToken(refreshToken)
          return true
        }else{
          redirect('/login')
          return false
          
        }
      }
    }
    redirect('/login')
    return false
  };

  const AuthGuard: React.FC<AuthGuardProps> = ({ component: Component, ...rest }) => {
    const navigate = useNavigate();
    React.useEffect(() => {
      if (!CheckAuthentication()) {
        // Redirect the user to the login page if not authenticated
        navigate('/login');
      }
    });
    return CheckAuthentication() ? <Component {...rest} /> : null;
  };
  
  export default AuthGuard;