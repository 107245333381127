import React, { useState } from "react";
import { useTheme } from "../assets/themeContext";
import {
  Stack,
  Button,
  Grid,
  useTheme as T,
  FormLabel,
  FormHelperText,
  TextField,
} from "@mui/material";
import { addPayMethod, fetchCardApi } from "../services/RecipientApis";
import { toast } from "react-toastify";
import { Email } from "@mui/icons-material";


interface Errors {
  // cardNumber?: string | null;
  // cardHolder?: string | null;
  // CvcCvv?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  accountNumber?: string | null;
  routingNumber?: string | null;
  bankName?: string | null;
  option?: string | null;
  mail?: string | null;
  password?: string | null;
  phone?: string | null;
  expireDate?: string | null;
}

interface AddrecipientsModalProps {
  setOpenRecipients: React.Dispatch<React.SetStateAction<boolean>>;
}



const AddpaymentMethod: React.FC<AddrecipientsModalProps> = ({setOpenRecipients}) => {
  const t = T();
  const { theme } = useTheme();
  // const [expireDate, setexpireDate] = useState("");
  // const [cardNumber, setcardNumber] = useState("");
  // const [CvcCvv, setCvcCvv] = useState("");
  // const [cardHolder, setcardHolder] = useState("");
  const [accountNumber, setaccountNumber] = useState("");
  const [routingNumber, setroutingNumber] = useState("");
  const [bankName, setbankName] = useState("");
  const [errors, setErrors] = useState<Errors>({});
  const [inputType, setInputType] = useState<"Cards" | "Account">("Cards");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mail, setEmail] = useState("");
  const bankdata = {
    account_number: accountNumber,
    routing_number: routingNumber,
    bank_name: bankName,
  }

 

  const handlePaymethod = async (event:React.FormEvent) => {
    if(inputType==='Account' && (!accountNumber || !routingNumber || !bankName)){
      toast.error('Fill All Required Fields ',  {
        autoClose: 2000
      });
      return
    }

    try {
      const response = await addPayMethod(bankdata);
      toast.success('Payment Method Added successfully' , {
        autoClose: 2000
      });
      setaccountNumber('');
      setroutingNumber('');
      setbankName('');
      
      setTimeout(() => {
        setOpenRecipients(false);
      }, 1000);
    } catch (error) {
    }
  };

  const rawData = {
    firstName: firstName,
    lastName: lastName,
    email: mail,
  }



  const handlePaymethods=async(event:React.FormEvent)=>{
    if(inputType==='Cards' && (!firstName || !lastName || !mail)){
      toast.error('Fill All Required Fields', {
        autoClose: 2000
      });
      return
    }
    try{
      await fetchCardApi( rawData );
      toast.success('Payment Method Added successfully', {
        autoClose: 2000
      });
    setFirstName('');
    setLastName('');
    setEmail('');
      setTimeout(() => {
        setOpenRecipients(false);
      }, 1000);
    }catch (error) {
    }
  }
  
  
  const validateFirstName = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, firstName: "First name is required" }));
      return false;
    } else {
      if (text.length < 3) {
        setErrors((prev) => ({
          ...prev,
          firstName: "First Name must be at least 3 characters",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, firstName: null }));
        return true;
      }
    }
  };
  
  const validateLastName = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, lastName: "Last name is required" }));
      return false;
    } else {
      if (text.length < 3) {
        setErrors((prev) => ({
          ...prev,
          lastName: "Last Name must be at least 3 characters",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, lastName: null }));
        return true;
      }
    }
  };

  const validateEmail = (text: string): boolean => {
    // if there is no text on blur event
    if (!text) {
      setErrors((prev) => ({ ...prev, mail: "Email is required" }));
      return false;
      // check the mail pattern
    } else {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z.]{2,4}$/i;
      // if mail pattern is incorrect
      if (!emailPattern.test(text)) {
        setErrors((prev) => ({ ...prev, mail: "Invalid email format" }));
        return false;
        // if mail pattern is correct
      } else {
        setErrors((prev) => ({ ...prev, mail: null }));
        return true;
      }
    }
  };
  const BankName = (text: string) => {
    if (!text) {
      setErrors((prev) => ({ ...prev, bankName: "BankName is required" }));
      return false;
    } 
  };


  const CardHolder = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, cardHolder: "CardHolder is required" }));
      return false;
    } else {
      const phonePattern = /^\d{10}$/;
      if (!phonePattern.test(text)) {
        setErrors((prev) => ({ ...prev, cardHolder: "Invalid CardHolder number" }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, cardHolder: null }));
        return true;
      }
    }
  };


  const Accountnumber = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, accountNumber: "accountNumber is required" }));
      return false;
    } else {
      const accountNumber = /^\d{16}$/;
      if (!accountNumber.test(text)) {
        setErrors((prev) => ({ ...prev, accountNumber: "Invalid AccountNumber number" }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, accountNumber: null }));
        return true;
      }
    }
  };

  const RoutingNumber = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, routingNumber: "Routing number is required" }));
      return false;
    } else {
      const routingNumber = /^\d{10}$/;
      if (!routingNumber.test(text)) {
        setErrors((prev) => ({ ...prev, routingNumber: "Invalid routing number number" }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, routingNumber: null }));
        return true;
      }
    }
  };

  const date = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, expireDate: "Date is required" }));
      return false;
    } else {
      // Regular expression to match YYYY-MM-DD format
      const datePattern = /^\d{4}-\d{2}-\d{2}$/;
      if (!datePattern.test(text)) {
        setErrors((prev) => ({ ...prev, expireDate: "Invalid Date" }));
        return false;
      } else {
        // Additional validation to check if it's a real date
        const [year, month, day] = text.split('/').map(Number);
        const dateObject = new Date(year, month - 1, day);
        if (
          dateObject.getFullYear() !== year ||
          dateObject.getMonth() + 1 !== month ||
          dateObject.getDate() !== day
        ) {
          setErrors((prev) => ({ ...prev, expireDate: "Invalid Date" }));
          return false;
        } else {
          setErrors((prev) => ({ ...prev, expireDate: null }));
          return true;
        }
      }
    }
  };
  

  return (
    <Grid lg={12} md={12} sm={12} xs={12}>
      {/* Form */}
      <Grid
          lg={12}
          md={12}
          xs={12}
          sm={12}
          xl={12}
          marginBottom={"16px"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
              backgroundColor: theme.palette.info.light,
              borderRadius: "25px",
            }}
            width={"300px"}
            padding={"7px"}
          >
            <Button
              size="large"
              variant={inputType === "Cards" ? "contained" : "outlined"}
              sx={{
                fontSize: "16px",
                fontWeight: 700,
                width: "150px",
                borderRadius: '25px',
                backgroundColor:
                  inputType === "Cards" ? theme.palette.primary.main : "none",
                color:
                  inputType === "Cards" ? "#FFFFFF" : theme.palette.text.primary,
                borderColor:
                  inputType === "Cards" ? "none" : theme.palette.primary.main,
                "&:hover": {
                  backgroundColor:
                    inputType === "Cards" ? theme.palette.primary.main : "none",
                  borderColor:
                    inputType === "Cards" ? "none" : theme.palette.primary.main,
                },
              }}
              onClick={() => {
                setInputType("Cards");
              }}
            >
              Interac
            </Button>
            <Button
              variant={inputType === "Account" ? "contained" : "outlined"}
              sx={{
                fontSize: "16px",
                fontWeight: 700,
                width: "150px",
                borderRadius: '25px',
                backgroundColor:
                  inputType === "Account" ? theme.palette.primary.main : "none",
                color:
                  inputType === "Account" ? "#FFFFFF" : theme.palette.text.primary,
                borderColor:
                  inputType === "Account" ? "none" : theme.palette.primary.main,
                "&:hover": {
                  backgroundColor:
                    inputType === "Account" ? theme.palette.primary.main : "none",
                  borderColor:
                    inputType === "Account" ? "none" : theme.palette.primary.main,
                },
              }}
              onClick={() => {
                setInputType("Account");
              }}
            >
              Accounts
            </Button>
          </Stack>
        </Grid>
        {inputType === "Cards" ? (
      <form onSubmit={handlePaymethods}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        autoComplete="off"
      >
        
        {/* First name and Middle Name */}
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"2px"}
        >
          <Grid
            lg={5.8}
            md={5.8}
            sm={5.8}
            xs={5.5}
            paddingY={"15px"}
            display={"flex"}
            flexDirection={"column"}
          >
            {/* First Name */}
            <FormLabel
                    htmlFor="First Name"
                    sx={{
                      fontSize: "14px",
                      lineHeight: "14px",
                      fontWeight: 500,
                      color: theme.palette.secondary.main,
                    }}
                  >
                    First Name
                  </FormLabel>
                  <TextField
                    id="First Name"
                    size="small"
                    name="First Name"
                    variant="outlined"
                    type="text"
                    value={firstName}
                    placeholder="Enter Firstname"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      validateFirstName(e.target.value);
                    }}
                    onBlur={() => validateFirstName(firstName)}
                    InputProps={{
                      style: {
                        borderRadius: "16px",
                        padding: "3px",
                        marginTop: "5px",
                        fontWeight: 400,
                        fontSize: "14px",
                        border: "1px  rgba(156, 174, 184, 0.5)",
                      },
                    }}
                  />
                  {errors.firstName && (
                    <FormHelperText
                      sx={{
                        color: theme.palette.error.main,
                        fontWeight: 520,
                      }}
                    >
                      {errors.firstName}
                    </FormHelperText>
                  )}
          </Grid>
          {/* Middle Name */}

          <Grid
            lg={5.8}
            md={5.8}
            sm={5.8}
            xs={5.5}
            display={"flex"}
            flexDirection={"column"}
          >
             <FormLabel
                      htmlFor="Last Name"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "14px",
                        fontWeight: 500,
                        color: theme.palette.secondary.main,
                      }}
                    >
                      Last Name
                    </FormLabel>
                    <TextField
                      fullWidth
                      id="Last Name"
                      size="small"
                      name="Last Name"
                      variant="outlined"
                      type="text"
                      value={lastName}
                      placeholder="Enter Lastname"
                      onChange={(e) => {
                        setLastName(e.target.value);
                        validateLastName(e.target.value);
                      }}
                      onBlur={() => validateLastName(lastName)}
                      InputProps={{
                        style: {
                          borderRadius: "16px",
                          padding: "3px",
                          marginTop: "5px",
                          fontWeight: 400,
                          fontSize: "14px",
                          border: "1px  rgba(156, 174, 184, 0.5)",
                        },
                      }}
                    />
                    {errors.lastName && (
                      <FormHelperText
                        sx={{
                          color: theme.palette.error.main,
                          fontWeight: 520,
                        }}
                      >
                        {errors.lastName}
                      </FormHelperText>
                    )}
          </Grid>
        </Grid>
        {/* Last Name and reason */}
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"2px"}
        >
          <Grid
            lg={5.8}
            md={5.8}
            sm={5.8}
            xs={5.5}
            paddingY={"15px"}
            display={"flex"}
            flexDirection={"column"}
          >
           <FormLabel
                  htmlFor="Email"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "14px",
                    fontWeight: 500,
                    color: theme.palette.secondary.main,
                  }}
                >
                  Email
                </FormLabel>
                <TextField
                  fullWidth
                  id="Email"
                  size="small"
                  variant="outlined"
                  name="email"
                  value={mail}
                  type="email"
                  placeholder="mail@simmmple.com"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    validateEmail(e.target.value);
                  }}
                  onBlur={() => validateEmail(mail)}
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                      padding: "3px",
                      marginTop: "5px",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: "1px  rgba(156, 174, 184, 0.5)",
                    },
                  }}
                />
                {errors.mail && (
                  <FormHelperText
                    sx={{ color: theme.palette.error.main, fontWeight: 520 }}
                  >
                    {errors.mail}
                  </FormHelperText>
                )}
          </Grid>
     
          
        
         
        </Grid>
        {/* Email and phoneNumber */}
        <Grid
          sx={{ display: "flex", justifyContent: "center", paddingTop: "25px" }}
        >
          <Button
            variant="contained"
            size="small"
            type="submit"
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              width: "170px",
              height: "41px",
              borderRadius: "20px",
              bgcolor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            }}
            onClick={handlePaymethods}
          >
            Add Payment
          </Button>
        </Grid>
        
      </form>
        ): inputType === "Account" ? (
          <form
            style={{
              

            }}
            autoComplete="off"
            onSubmit={handlePaymethod}
          >
            <Grid sx={{display: "flex",flexDirection: "column",
          }}>
            <Grid
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            marginY={'10px'}
          >
            {/* Email Input Field */}
            <FormLabel
              htmlFor="Card Holder"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              Account Number
            </FormLabel>
            <TextField
              fullWidth
              id="accountNumber"
              size="small"
              variant="outlined"
              name="accountNumber"
              value={accountNumber}
              type="AccountNumber"
              placeholder="Enter Account Number"
              onChange={(e) => {
                setaccountNumber(e.target.value);
                Accountnumber(e.target.value);
              }}
              onBlur={() => Accountnumber(accountNumber)}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  // - The Input-root, inside the TextField-root
                  "& fieldset": {
                    // - The <fieldset> inside the Input-root
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
            />
            {errors.accountNumber && (
              <FormHelperText sx={{ color:theme.palette.error.main, fontWeight: 520 }}>
                {errors.accountNumber}
              </FormHelperText>
            )}
          </Grid>
          <Grid
            lg={12}
            md={12}
            sm={12}
            xs={12}
            xl={12}
            display={"flex"}
            flexDirection={"column"}
            marginY={'10px'}
          >
            {/* Email Input Field */}
            <FormLabel
              htmlFor="Card Holder"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              Routing Number
            </FormLabel>
            <TextField
              fullWidth
              id="routingNumber"
              size="small"
              variant="outlined"
              name="routingNumber"
              value={routingNumber}
              type="routingNumber"
              placeholder="Enter RoutingNumber"
              onChange={(e) => {
                setroutingNumber(e.target.value);
                RoutingNumber(e.target.value);
              }}
              onBlur={() => RoutingNumber(routingNumber)}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  // - The Input-root, inside the TextField-root
                  "& fieldset": {
                    // - The <fieldset> inside the Input-root
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
            />
            {errors.routingNumber && (
              <FormHelperText sx={{ color:theme.palette.error.main, fontWeight: 520 }}>
                {errors.routingNumber}
              </FormHelperText>
            )}
          </Grid>
          <Grid
            lg={12}
            md={12}
            sm={12}
            xs={12}
            xl={12}
            display={"flex"}
            flexDirection={"column"}
            marginY={'10px'}
          >
            {/* Email Input Field */}
            <FormLabel
              htmlFor="Card Holder"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              Bank Name
            </FormLabel>
            <TextField
              fullWidth
              id="bankName"
              size="small"
              variant="outlined"
              name="bankName"
              value={bankName}
              type="bankName"
              placeholder="Enter BankName"
              onChange={(e) => {
                setbankName(e.target.value);
                BankName(e.target.value);
              }}
              onBlur={() => BankName(bankName)}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  // - The Input-root, inside the TextField-root
                  "& fieldset": {
                    // - The <fieldset> inside the Input-root
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
            />
            {errors.bankName && (
              <FormHelperText sx={{ color:theme.palette.error.main, fontWeight: 520 }}>
                {errors.bankName}
              </FormHelperText>
            )}
          </Grid>
          </Grid>
         
          <Grid
          sx={{ display: "flex", justifyContent: "center", paddingTop: "25px" }}
        >
          <Button
            variant="contained"
            size="small"
            type="submit"
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              width: "170px",
              height: "41px",
              borderRadius: "20px",
              bgcolor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            }}
            onClick={handlePaymethod}
          >
            Add Payment
          </Button>
            </Grid>
           
          </form>
          
        ) : null}
    </Grid>
  );
};

export default AddpaymentMethod;