const url = process.env.REACT_APP_API_URL;

export const getAllTransactions = async () => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(
      `${url}/transactions-api/api/transactions/?skip=0&limit=4`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not fetch transactions:", error);
    // Handle errors as appropriate for your application
    throw error;
  }
};

export const getRecentTransaction = async () => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(
      `${url}/transactions-api/api/transactions/?skip=0&limit=1`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not fetch Transaction", error);
    // Handle errors as appropriate for your application
    throw error;
  }
};

export const getTotalTransactions = async (page) => {
  try {
    const pages = (page-1)*10;
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(
      `${url}/transactions-api/api/transactions/?skip=${pages}&limit=10`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not fetch transactions:", error);
    // Handle errors as appropriate for your application
    throw error;
  }
};


export const makeOrder = async (transactionData) => {
  // console.log(transactionData);
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const raw = JSON.stringify(transactionData);
    console.log(raw)
    const response = await fetch(
      `${url}/transactions-api/api/transactions`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: raw,
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Could not make transaction:", error);
    // Handle errors as appropriate for your application
    throw error;
  }
};
