import React,{useState} from "react";
import { Grid, Select, MenuItem, FormControl, useTheme as T, useMediaQuery } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import PieCharts from "./PieCharts";

const RewardsPieChart: React.FC = () => {
  const { theme } = useTheme();
  const t = T()
  const isSmallScreen=useMediaQuery(t.breakpoints.down('sm'))
  const filterOptions = [
    { id: "This Month" },
    { id: "Past 3 Months" },
    { id: "Past 6 Months" },
  ];

  const [filter, setFilter] = useState<string>(filterOptions[0].id);
  return (
    <Grid
      lg={12}
      md={12}
      sm={12}
      xs={12}
      xl={12}
      sx={{
        marginTop:isSmallScreen? '20px':null,
        padding: "20px",
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
        boxShadow: "rgba(109, 30, 212, 0.12)",
      }}
    >
      <Grid lg={12} md={12} sm={12} xs={12} xl={12} sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
        <Grid>
          <label
            style={{
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "28px",
              color: theme.palette.primary.main,
            }}
          >
            Top Referrals
          </label>
        </Grid>
        <Grid>
        <FormControl size="small">
              <Select
                style={{ color: theme.palette.info.main, fontFamily: "Inter" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="standard"
                value={filter}
                label="month"
                onChange={(e) => setFilter(e.target.value)}
                disableUnderline
                sx={{
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&:hover": {
                    backgroundColor: "transparent", // Prevents background color change on hover
                  },
                  "& .MuiSelect-select": {
                    "&:focus": {
                      backgroundColor: "transparent", // Ensures the background color doesn't change on focus within the select
                    },
                  },
                  "& .MuiSelect-icon": {
                    color: theme.palette.info.main, // Change to desired color
                  },

                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  color: theme.palette.info.main,
                }}
              >
                {filterOptions.map((item) => (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                    sx={{
                      borderRadius: "1px",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: 600,
                      color: theme.palette.info.main,
                      backgroundColor: theme.palette.info.light,
                      "&.Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        color: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.main,
                          color: "#FFFFFF",
                        },
                      },
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    {item.id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
        </Grid>
      </Grid>
     <Grid marginY={'25px'}>
        <PieCharts/>
     </Grid>
    </Grid>
  );
};
export default RewardsPieChart;
