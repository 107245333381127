import React, { useCallback, useEffect } from "react";
import NavBar from "../components/HeaderBar"; // Import your NavBar component
import { Box, Button, Grid, Typography } from "@mui/material";
import { useTheme } from "../assets/themeContext";
// Define an interface for the Hyperverge KYC result
interface HyperKycResult {
  Cancelled?: boolean;
  Failure?: string;
  Success?: string;
}

const KYC: React.FC = () => {
  const accessToken: string =
    "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Inlka2k4NSIsImhhc2giOiJlZjk2MTc0MGZmMGYwNDAxMjVlNGZkMDUyN2ZhMWY4ZDVjNDA2NWZhODdiMGEyMzM0N2FjN2VhOGRhYWVmYTMwIiwiaWF0IjoxNzE4NjI4MTczLCJleHAiOjE3MTg3MTQ1NzMsImp0aSI6IjgxNGNlMjhhLWNjMDktNGEzMC1iYjZhLWNjMDhjYTUzY2QzZSJ9.GYFCRmBYtfj-QVOGRNvEvpVbMZ3KBhvueYqhqH23PbF9DfKDpy_i_fuAWIvP-GUbmZDW32f1fHX2HBCVsfSfu_vqJL8HiweTBA9N5ie2U7gr9E920wMZZptsL_c-ZrYdhUIJnJCWI3iazbJ2qdACybaoElfhFBLAWZf5stilI78"; // Replace with your actual access token
  const workflowID: string = "workflow_teaEQdx"; // Replace with your actual workflow ID
  const { theme } = useTheme();
  useEffect(() => {
    document.title = "AptRemit-KYC Verification";
  });
  const startOnboarding = useCallback(() => {
    // Check if the SDK classes are available on the window object
    if (window.HyperKycConfig && window.HyperKYCModule) {
      // Create a new configuration object for the KYC process
      const hyperKycConfig = new window.HyperKycConfig(
        accessToken,
        workflowID,
        "abc123" // Generate or define a transaction ID as needed
      );

      // Define a handler for the KYC result
      const handler = (result: HyperKycResult) => {
        if (result.Cancelled) {
          console.log("Onboarding Cancelled:", result.Cancelled);
        } else if (result.Failure) {
          console.log("Onboarding Failure:", result.Failure);
        } else if (result.Success) {
          console.log("Onboarding Success:", result.Success);
        }
      };

      // Launch the KYC module with the provided configuration and handler
      window.HyperKYCModule.launch(hyperKycConfig, handler);
    } else {
      console.error("Hyperverge KYC SDK not loaded");
    }
  }, [accessToken, workflowID]);

  return (
    <Grid
      xl={12}
      lg={12}
      md={12}
      sx={{ backgroundColor: theme.palette.primary.dark, height: "100vh" }}
    >
      <NavBar highlightDashboard={true} />
      <Grid
        sx={{
          padding: "15px",
          borderRadius: "20px",
          height: "80%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        xl={12}
      >
        <Grid
          sx={{
            bgcolor: theme.palette.info.light,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "16px",
          }}
          height={"250px"}
          width={"40%"}
        >
          <Typography sx={{ fontSize: "20px", color:theme.palette.text.primary, fontWeight:500 }}>
            Send money online cross-border, Instantly
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: "10px",
              bgcolor: theme.palette.primary.main,
              paddingY: "15.5px",
              paddingX: "25px",
              display: "flex",
              flexDirection: "row",
              gap: "15px",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "19.36px",
              color: "#FFFFFF",
              marginTop:'20px',
              textTransform: "initial",
              "&:hover": {
                bgcolor: theme.palette.primary.main,
              },
            }}
            onClick={startOnboarding}
          >
            Start KYC
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KYC;
