import React,{useState} from 'react'
import {Grid, Box, Button} from '@mui/material'
import {OtpInput} from "reactjs-otp-input"; // Ensure correct import, might need default or named based on export
import { useTheme } from "../assets/themeContext";

interface VerifyMobileProps {
    setOpenPhoneDialog: React.Dispatch<React.SetStateAction<boolean>>;
  }

const VerifyMobile:React.FC<VerifyMobileProps>=({setOpenPhoneDialog})=>{
    const { theme } = useTheme();
  const [otp, setOtp] = useState<string>("");  // Changed type to string for general use-case

  const handleChange = (otp: string) => setOtp(otp);  // Directly set the OTP value


    return(
        <Grid padding={'20px'}>
      <Grid>
        <label style={{ fontSize: "25px", color: theme.palette.text.primary }}>
          One-Time Password
        </label>
      </Grid>
        <Box marginTop="15px" >
          <OtpInput
            value={otp}
            onChange={handleChange}
            isInputNum={true}  // Set true to bring up numeric keypad on mobile devices
            numInputs={4}
            separator={<span></span>}
            inputStyle={{
              width: "45px", 
              height: "45px", 
              marginTop:'10px',
              marginBottom:"25px",
              marginRight:'30px',
              fontFamily: "Inter", 
              fontSize: "15px", 
              borderRadius: "6px",
              border:`0.5px solid ${theme.palette.text.primary}`
            }}
          />
        </Box>
        <Grid sx={{display:'flex',flexDirection:'column'}}>
        <label style={{ fontSize: "18px", color: theme.palette.text.primary, fontFamily:'Inter' }}>
          Enter the OTP sent to your Mobile Number
        </label>
        <label style={{fontSize: "16px", color: theme.palette.primary.main, fontFamily:'Inter',fontWeight:600,paddingTop:'20px', cursor:'pointer'}}>
            Resend OTP
        </label>
      </Grid>
       <Grid sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:'15px'}}>
       <Button
              variant="contained"
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#1F1F1F",
                lineHeight: "24px",
                textAlign: "center",
                borderRadius: "16px",
                paddingX: "35px",
                paddingY: "10px",
                textTransform: "initial",
                bgcolor: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                },
              }}
              onClick={()=>setOpenPhoneDialog(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color:"#FFFFFF",
                lineHeight: "24px",
                textAlign: "center",
                borderRadius: "16px",
                paddingX: "35px",
                paddingY: "10px",
                textTransform: "initial",
                bgcolor:theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
              
            >
              Verify
            </Button>
       </Grid>
     
    </Grid>
    )
}
export default VerifyMobile;