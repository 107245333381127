import React from 'react';

const CAFlagIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
    return (
        <svg 
            width="21px" 
            height="15px" 
            viewBox="0 0 55.2 38.4" 
            xmlns="http://www.w3.org/2000/svg"
            className="ca-flag-icon"  // Add a unique class name for specific styling
        >
            <style>
                {`
                .ca-flag-icon { fill: currentColor; }
                .ca-flag-icon .st0 { fill: #FF0000; } 
                .ca-flag-icon .st1 { fill: #FFFFFF; }
                `}
            </style>
            <g>
                <path className="st0" d="M3.03,0h49.13c1.67,0,3.03,1.36,3.03,3.03v32.33c0,1.67-1.36,3.03-3.03,3.03H3.03C1.36,38.4,0,37.04,0,35.37V3.03C0,1.36,1.36,0,3.03,0z" />
                <polygon className="st1" points="8.4,0 46.8,0 46.8,38.4 8.4,38.4" />
                <path className="st0" d="M27.6,3.6l-2.62,4.89c-0.3,0.53-0.83,0.48-1.37,0.18l-1.9-0.98l1.41,7.5c0.3,1.37-0.66,1.37-1.13,0.78l-3.31-3.7l-0.53,1.88c-0.06,0.25-0.33,0.51-0.74,0.45l-4.18-0.88l1.1,4c0.24,0.89,0.42,1.26-0.24,1.49l-1.49,0.7l7.21,5.85c0.29,0.22,0.43,0.62,0.32,0.98l-0.63,2.07c2.48-0.29,4.71-0.72,7.19-0.98c0.22-0.02,0.58,0.33,0.58,0.59L26.95,36h1.2l-0.19-7.56c0-0.26,0.33-0.63,0.55-0.61c2.48,0.27,4.71,0.7,7.19,0.98l-0.64-2.08c-0.11-0.36,0.04-0.75,0.32-0.98l7.21-5.85l-1.49-0.7c-0.66-0.24-0.48-0.6-0.24-1.49l1.1-4l-4.18,0.88c-0.41,0.06-0.68-0.2-0.74-0.45l-0.53-1.88l-3.31,3.7c-0.47,0.59-1.42,0.59-1.13-0.78l1.41-7.5l-1.9,0.98c-0.53,0.3-1.07,0.34-1.37-0.18L27.6,3.6z" />
            </g>
        </svg>
    );
};

export default CAFlagIcon;
