import React from "react";
import { Grid, Avatar } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import { BarChart2, CircleArrowOutUpRight } from "lucide-react";

const RewardsTotal: React.FC = () => {
  const { theme } = useTheme();
  const percentage = 23;
  const percentageColor = percentage > 0 ? "#05CD99" : "red";
  const percentageSymbol = percentage > 0 ? "+" : "";
  return (
    <Grid
      lg={12}
      md={12}
      xl={12}
      sm={12}
      xs={12}
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap:'wrap',
        gap: "20px",
        justifyContent:'space-between'
      }}
      marginTop={"20px"}
    >
      <Grid
        lg={12}
        md={5.5}
        sm={5.5}
        xs={12}
        xl={12}
        sx={{
          padding: "20px",
          borderRadius: "20px",
          bgcolor: theme.palette.info.light,
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ height: 56, width: 56, bgcolor: "#F4F7FE" }}>
          <BarChart2
            style={{
              width: "32.84px",
              height: "32.84px",
              color: "rgba(109, 30, 212, 1)",
            }}
            strokeWidth={2}
          />
        </Avatar>
        <Grid sx={{ display: "flex", flexDirection: "column" }}>
          <label
            style={{
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "24px",
              color: theme.palette.info.main,
              padding: "3px 0px",
            }}
          >
            Total Referrals
          </label>
          <label
            style={{
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "32px",
              color: theme.palette.primary.main,
              padding: "3px 0px",
            }}
          >
            10
          </label>
        </Grid>
      </Grid>
      <Grid
        sx={{
          padding: "20px",
          borderRadius: "20px",
          bgcolor: theme.palette.info.light,
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          alignItems: "center",
        }}
        lg={12}
        md={5.5}
        sm={5.5}
        xs={12}
        xl={12}
      >
        <Avatar sx={{ height: 56, width: 56, bgcolor: "#F4F7FE" }}>
          <BarChart2
            style={{
              width: "32.84px",
              height: "32.84px",
              color: "rgba(109, 30, 212, 1)",
            }}
            strokeWidth={2}
          />
        </Avatar>
        <Grid sx={{ display: "flex", flexDirection: "column" }}>
          <label
            style={{
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "24px",
              color: theme.palette.info.main,
              padding: "3px 0px",
            }}
          >
            Total Rewards
          </label>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <label
              style={{
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "32px",
                color: theme.palette.primary.main,
                padding: "3px 0px",
              }}
            >
              $ 34
            </label>
            <CircleArrowOutUpRight
              style={{
                width: "20px",
                height: "20px",
                color: "rgba(109, 30, 212, 1)",
              }}
              strokeWidth={2.5}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{
          padding: "20px",
          borderRadius: "20px",
          bgcolor: theme.palette.info.light,
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          alignItems: "center",
        }}
        lg={12}
        md={5.5}
        sm={5.5}
        xs={12}
        xl={12}
      >
        <Avatar sx={{ height: 56, width: 56, bgcolor: "#F4F7FE" }}>
          <BarChart2
            style={{
              width: "32.84px",
              height: "32.84px",
              color: "rgba(109, 30, 212, 1)",
            }}
            strokeWidth={2}
          />
        </Avatar>
        <Grid sx={{ display: "flex", flexDirection: "column" }}>
          <label
            style={{
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "24px",
              color: theme.palette.info.main,
              padding: "3px 0px",
            }}
          >
            Available Rewards
          </label>
          <label
            style={{
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "32px",
              color: theme.palette.primary.main,
              padding: "3px 0px",
            }}
          >
            $ 30.25
          </label>
        </Grid>
      </Grid>
      <Grid
        sx={{
          padding: "20px",
          borderRadius: "20px",
          bgcolor: theme.palette.info.light,
          display: "flex",
          flexDirection: "column",
        }}
        lg={12}
        md={5.5}
        sm={5.5}
        xs={12}
        xl={12}
      >
        <label
          style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
            color: theme.palette.info.main,
            padding: "1px 0px",
          }}
        >
          Referral Earnings
        </label>
        <label
          style={{
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "32px",
            color: theme.palette.primary.main,
            padding: "1px 0px",
          }}
        >
          $ 14.34
        </label>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <label
            style={{
              fontSize: "12px",
              fontWeight: 700,
              lineHeight: "20px",
              color: percentageColor,
              padding: "1px 0px",
            }}
          >
            {percentageSymbol}
            {percentage}
            {"%"}
          </label>
          <label
            style={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "20px",
              color: theme.palette.info.main,
              padding: "1px 0px",
            }}
          >
            since last month
          </label>
        </Grid>
      </Grid>
      <Grid
        sx={{
          padding: "20px",
          borderRadius: "20px",
          bgcolor: theme.palette.info.light,
          display: "flex",
          flexDirection: "column",
        }}
        lg={12}
        md={5.5}
        sm={5.5}
        xs={12}
        xl={12}
      >
        <label
          style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
            color: theme.palette.info.main,
            padding: "1px 0px",
          }}
        >
          Used up Rewards
        </label>
        <label
          style={{
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "32px",
            color: theme.palette.primary.main,
            padding: "1px 0px",
          }}
        >
          $ 3.75
        </label>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <label
            style={{
              fontSize: "12px",
              fontWeight: 700,
              lineHeight: "20px",
              color: percentageColor,
              padding: "1px 0px",
            }}
          >
            {percentageSymbol}
            {percentage}
            {"%"}
          </label>
          <label
            style={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "20px",
              color: theme.palette.info.main,
              padding: "1px 0px",
            }}
          >
            since last month
          </label>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default RewardsTotal;
