import React, { useEffect } from "react";
import { Grid, Box,Avatar , useTheme as T, useMediaQuery} from "@mui/material";
import { useTheme } from "../assets/themeContext";
import { getRecentRecipients } from "../services/RecipientApis";
import { useNavigate } from "react-router-dom";

const FrequentRecipient = () => {
  const { theme } = useTheme();
  const navigate = useNavigate()
  const t =T()
  const isMedium = useMediaQuery(t.breakpoints.down('sm'))

  const transfer = () => {
    alert('go')
  }

  const rows = [
  { name: "Jane's", bankAccountNum: "*****7890", transferNow: "Transfer Now", manage: "Manage" },
  { name: "Jane Smith", bankAccountNum: "*****4321", transferNow: "Transfer Now", manage: "Manage" },
  { name: "John Doe", bankAccountNum: "*****4455", transferNow: "Transfer Now", manage: "Manage" },
  { name: "Alice Johnson", bankAccountNum: "*****8899", transferNow: "Transfer Now", manage: "Manage" },
  ];

  const getFrequentRecipients=async()=>{
    try{
      const response = await getRecentRecipients()
    }catch(err){
      console.log(err)
    }
  }

  useEffect(()=>{
    getFrequentRecipients()
  },[])
  
  return (
    <Grid
      sx={{
        padding: "20px",
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
        marginTop:isMedium?'20px':null
      }}
      lg={12}
      md={12}
      xs={12}
      sm={12}
    >
  <Grid sx={{ display: 'flex' , flexDirection:'row', justifyContent:'space-between',paddingX: '10px' }}>
   <Grid>
    <label
       style={{
       flex: 1,
       fontSize: "20px",
       fontWeight: 500,
       color: theme.palette.primary.main,
    }}
    >
   Frequent Recipients
   </label>
  </Grid>
   <Grid onClick={()=>navigate('/recipients')}>
    <label
        style={{
            paddingTop: '2px',
        flex: 1,
        fontSize: "16px",
        fontWeight: 500,
        color: theme.palette.primary.main,
        cursor: 'pointer'
    }}
    >
    See all
    </label>
</Grid>
</Grid>
      <Grid paddingTop={2}>

        {rows.map((row, index) => (
          <Grid key={index} sx={{ paddingY: "0px" }}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              gap={'10px'}
              sx={{
                padding: "15px",
                backgroundColor: 'white',
                borderRadius: '10px',
                marginBottom: '10px',
                 bgcolor: theme.palette.info.light,
                transition: 'box-shadow 0.3s ease',
                '&:hover': {
                    boxShadow: theme.palette.action.selected ,
                 
                },
              }}
            >

              <Avatar
                sx={{ width: 40, height: 40, bgcolor: "rgba(109, 30, 212, 0.3)" }}
              />
             
             <Grid sx={{ display: "flex", flexDirection:'column', flex: 1, paddingLeft: '4px' }}>
               <Grid>
                <label
                 
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                    color: theme.palette.text.primary,
                  }}
                >
                  {row.name}
                </label>
                </Grid>
                <Grid>
                <label
                 
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: theme.palette.info.main,
                  }}
                >
                   {row.bankAccountNum}
                </label>
                </Grid>
                </Grid>
                <Grid sx={{ display: "flex", justifyContent: "space-between", flex: 1, paddingTop: 1, paddingLeft: '4px' , gap:'10px'}}>
                <label
                 
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: theme.palette.primary.main,
                    cursor: 'pointer'
                  }}
                  onClick={transfer}
                >
                  {row.transferNow}
                </label>
  
                <label
                 
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: theme.palette.info.main,
                    cursor: 'pointer'
                  }}
                  //onClick={()}
                >
                  {row.manage}
                </label>
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default FrequentRecipient;














// <Grid sx={{ display: 'flex' , flexDirection:'row', justifyContent:'space-between',paddingX: '10px' }}>
// <Grid>
// <label
// style={{
// flex: 1,
// fontSize: "16px",
// fontWeight: 700,
// color: theme.palette.primary.main,
// }}
// >
// Frequent Recipients
// </label>
// </Grid>
// <Grid>
// <label
// style={{
// flex: 1,
// fontSize: "16px",
// fontWeight: 500,
// color: theme.palette.primary.main,
// }}
// >
// See all
// </label>
// </Grid>
// </Grid>

// <Grid sx={{ paddingY: "30px",paddingX: "5px", backgroundColor: 'white', borderRadius: '10px', boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)' }}>
// <Grid
// sx={{
// display: "flex",
// flexDirection: "row",
// justifyContent: "space-evenly",
// alignItems: "center",

// }}
// >
// <Avatar
// sx={{ width: 40, height: 40, bgcolor: "rgba(109, 30, 212, 0.3)" }}
// />
// <label
// style={{
// flex: 1,
// fontSize: "16px",
// fontWeight: 700,
// color: theme.palette.text.primary,
// }}
// >
// Jane Smith
// </label>

// <label
// style={{
// flex: 1,
// fontSize: "16px",
// fontWeight: 500,
// color: theme.palette.primary.main,
// }}
// >
// Transfer Now
// </label>

// <label
// style={{
// fontSize: "16px",
// fontWeight: 500,
// color: theme.palette.info.main,
// }}
// >
// Manage
// </label>
// </Grid>
// </Grid>