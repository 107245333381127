import React, { useState } from "react";
import { useTheme } from "../assets/themeContext";

import {
  Grid,
  Button,
  FormLabel,
  FormHelperText,
  Stack,
  Select,
  MenuItem,
  TextField,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import { addRecipient } from "../services/RecipientApis";
import { toast } from "react-toastify";

interface Errors {
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  option?: string | null;
  mail?: string | null;
  phone?: string | null;
  upi?: string | null;
  account?: string | null;
  code?: string | null;
  holder?: string | null;
}

interface AddrecipientsModalProps {
  setOpenRecipients: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddrecipientsModal: React.FC<AddrecipientsModalProps> = ({setOpenRecipients}) => {
  const { theme } = useTheme();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [mail, setEmail] = useState("");
  const [phone, setPhoneNumber] = React.useState("");
  const [errors, setErrors] = useState<Errors>({});
  const [value, setValue] = useState<string | "">("");
  const [upi, setupi] = useState("");
  const [isUpi, setIsUpi] = useState(true);
  const [code, setCode] = useState("");
  const [account, setAccount] = useState("");
  const [holderBank, setHolderBank] = useState("");
  const [inputType, setInputType] = useState<"UPI" | "Bank">("UPI");

  const options = [
    "My NRE/NRO Account",
    "Savings & Family Support",
    "Real Estate/Housing Societies",
    "Educational Institutions",
    "Tax Payment",
    "Hospitals/Healthcare Providers",
    "Travel/Tourism Partners",
    "Utility Bill Payments",
    "Loan Account Payment",
  ];


  const rawData = {
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    relation: value,
    is_kyc_verified: true,
    mobile_number:phone,
    email: mail,
    is_upi: isUpi,
    upi_id: upi,
    account_number: account,
    ifsc_code: code,
    bank_name: holderBank,
    penny_drop_verified: false
  }

  const handleChange = (event: SelectChangeEvent<string>) => {
    setValue(event.target.value);
  };


  const validateFirstName = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, firstName: "First name is required" }));
      return false;
    } else {
      if (text.length < 3) {
        setErrors((prev) => ({
          ...prev,
          firstName: "First Name must be at least 3 characters",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, firstName: null }));
        return true;
      }
    }
  };
  // last Name Validation
  const validateLastName = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, lastName: "Last name is required" }));
      return false;
    } else {
      if (text.length < 3) {
        setErrors((prev) => ({
          ...prev,
          lastName: "Last Name must be at least 3 characters",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, lastName: null }));
        return true;
      }
    }
  };
  // Middle Name Validation
  const validateMiddleName = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, middleName: "Middle name is required" }));
      return false;
    } else {
      if (text.length < 3) {
        setErrors((prev) => ({
          ...prev,
          middleName: "Middle Name must be at least 3 characters",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, middleName: null }));
        return true;
      }
    }
  };
  // autocomplete validation
  const validateSelection = (text: string | null): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, option: "Selection is required" }));
      return false;
    } else {
      setErrors((prev) => ({ ...prev, option: null }));
      return true;
    }
  };
  // mail Validation
  const validateEmail = (text: string): boolean => {
    // if there is no text on blur event
    if (!text) {
      setErrors((prev) => ({ ...prev, mail: "Email is required" }));
      return false;
      // check the mail pattern
    } else {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      // if mail pattern is incorrect
      if (!emailPattern.test(text)) {
        setErrors((prev) => ({ ...prev, mail: "Invalid email format" }));
        return false;
        // if mail pattern is correct
      } else {
        setErrors((prev) => ({ ...prev, mail: null }));
        return true;
      }
    }
  };

  // Phone Number Validation
  const validatePhone = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, phone: "Phone number is required" }));
      return false;
    } else {
      const phonePattern = /^\d{10}$/;
      if (!phonePattern.test(text)) {
        setErrors((prev) => ({ ...prev, phone: "Invalid phone number" }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, phone: null }));
        return true;
      }
    }
  };

  const validateUpi = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, upi: "Upi is required" }));
      return false;
    } else {
      setErrors((prev) => ({ ...prev, upi: null }));
      return true;
    }
  };

  const validateAccount = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, account: "Account Number is required" }));
      return false;
    } else {
      setErrors((prev) => ({ ...prev, account: null }));
      return true;
    }
  };

  const validateCode = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, code: "IFSC code is required" }));
      return false;
    } else {
      setErrors((prev) => ({ ...prev, code: null }));
      return true;
    }
  };

  const validateHolder = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, holder: "Bank name is required" }));
      return false;
      } else {
        setErrors((prev) => ({ ...prev, holder: null }));
        return true;
      }
  };

  const handleAddRecipient=async()=>{
    // Check if any required fields are empty
    if (inputType === "UPI" && (!firstName || !lastName || !middleName || !mail || !phone || !value || !upi )) {
      toast.error("Please fill in all fields");
      return;
    } else if (inputType === "Bank" && (!firstName || !lastName || !middleName || !mail || !phone || !value || !account || !code || !holderBank)) {
      toast.error("Please fill in all fields");
      return;
    }

    try {
      const response = await addRecipient(rawData);
      if (response) { // Check if the API call was successful
        console.log("Recipient successfully added");
        toast.success("Recipient successfully added", {
          autoClose: 3000
        });
        setTimeout(() => {
          setOpenRecipients(false); // Close the modal after 2 seconds
        }, 2000);
      } else {
        // Handle response not okay here (e.g., API errors)
        toast.error("Failed to add recipient. Please try again.");
        console.error("Error adding recipient:", response.status);
      }
    } catch (err) {
      console.error("Error adding recipient:", err);
    }
  }

  return (
    <Grid lg={12} md={12} sm={12} xs={12}>
      {/* Form */}
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {/* First name and Middle Name */}
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"2px"}
        >
          <Grid
            lg={5.8}
            md={5.8}
            sm={5.8}
            xs={5.5}
            paddingY={"15px"}
            display={"flex"}
            flexDirection={"column"}
          >
            {/* First Name */}
            <FormLabel
              htmlFor="First Name"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              First Name
            </FormLabel>
            <TextField
              id="First Name"
              size="small"
              name="First Name"
              variant="outlined"
              type="text"
              value={firstName}
              placeholder="Enter FirstName"
              onChange={(e) => {
                setFirstName(e.target.value);
                validateFirstName(e.target.value);
              }}
              onBlur={() => validateFirstName(firstName)}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  // - The Input-root, inside the TextField-root
                  "& fieldset": {
                    // - The <fieldset> inside the Input-root
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
            />
            {errors.firstName && (
              <FormHelperText
                sx={{
                  color: theme.palette.error.main,
                  fontWeight: 520,
                }}
              >
                {errors.firstName}
              </FormHelperText>
            )}
          </Grid>
          {/* Middle Name */}

          <Grid
            lg={5.8}
            md={5.8}
            sm={5.8}
            xs={5.5}
            display={"flex"}
            flexDirection={"column"}
          >
            <FormLabel
              htmlFor="Middle Name"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              Middle Name
            </FormLabel>
            <TextField
              id="Middle Name"
              size="small"
              name="Middle Name"
              variant="outlined"
              type="text"
              value={middleName}
              placeholder="Enter MiddleName"
              onChange={(e) => {
                setMiddleName(e.target.value);
                validateMiddleName(e.target.value);
              }}
              onBlur={() => validateMiddleName(middleName)}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  // - The Input-root, inside the TextField-root
                  "& fieldset": {
                    // - The <fieldset> inside the Input-root
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
            />
            {errors.middleName && (
              <FormHelperText
                sx={{
                  color: theme.palette.error.main,
                  fontWeight: 520,
                }}
              >
                {errors.middleName}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        {/* Last Name and reason */}
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"10px"}
        >
          <Grid
            lg={5.8}
            md={5.8}
            sm={5.8}
            xs={5.5}
            display={"flex"}
            flexDirection={"column"}
          >
            <FormLabel
              htmlFor="Middle Name"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              Last Name
            </FormLabel>
            <TextField
              id="Last Name"
              size="small"
              name="Last Name"
              variant="outlined"
              type="text"
              value={lastName}
              placeholder="Enter MiddleName"
              onChange={(e) => {
                setLastName(e.target.value);
                validateLastName(e.target.value);
              }}
              onBlur={() => validateLastName(lastName)}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  // - The Input-root, inside the TextField-root
                  "& fieldset": {
                    // - The <fieldset> inside the Input-root
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
            />
            {errors.lastName && (
              <FormHelperText
                sx={{
                  color: theme.palette.error.main,
                  fontWeight: 520,
                }}
              >
                {errors.lastName}
              </FormHelperText>
            )}
          </Grid>
          <Grid
            lg={5.8}
            md={5.8}
            sm={5.8}
            xs={5.5}
            display={"flex"}
            flexDirection={"column"}
          >
            <FormLabel
              htmlFor="Reason for sending"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              Reason for sending
            </FormLabel>
            <FormControl fullWidth>
              <Select
                size="small"
                labelId="select-label"
                value={value}
                onChange={handleChange}
                onBlur={() => validateSelection(value)}
                style={{
                  borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
                  backgroundColor: "inherit",
                }}
                sx={{
                  color: "white",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.text.primary,
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.text.primary,
                  },
                  ".MuiSvgIcon-root ": {
                    fill: theme.palette.text.primary,
                  },
                }}
              >
                {options.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option}
                    sx={{
                      bgcolor: theme.palette.info.light,
                      color: theme.palette.text.primary,
                      margin:'0px',
                      "&.Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        color: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.main,
                          color: "#FFFFFF",
                        },
                      },
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.option && (
              <FormHelperText
                sx={{
                  color: theme.palette.error.main,
                  fontWeight: 520,
                }}
              >
                {errors.option}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        {/* Email and phoneNumber */}
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"2px"}
        >
          <Grid
            lg={5.8}
            md={5.8}
            sm={5.8}
            xs={5.5}
            display={"flex"}
            flexDirection={"column"}
          >
            {/* Email Input Field */}
            <FormLabel
              htmlFor="Email"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              Email
            </FormLabel>
            <TextField
              fullWidth
              id="Email"
              size="small"
              variant="outlined"
              name="email"
              value={mail}
              type="email"
              placeholder="Enter Email"
              onChange={(e) => {
                setEmail(e.target.value);
                validateEmail(e.target.value);
              }}
              onBlur={() => validateEmail(mail)}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  // - The Input-root, inside the TextField-root
                  "& fieldset": {
                    // - The <fieldset> inside the Input-root
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
            />
            {errors.mail && (
              <FormHelperText sx={{ color:theme.palette.error.main, fontWeight: 520 }}>
                {errors.mail}
              </FormHelperText>
            )}
          </Grid>
          <Grid
            lg={5.8}
            md={5.8}
            sm={5.8}
            xs={5.5}
            display={"flex"}
            flexDirection={"column"}
          >
            {/* Phone Number Input Field */}
            <FormLabel
              htmlFor="Email"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              Phone Number
            </FormLabel>
            <TextField
              fullWidth
              id="phone"
              size="small"
              variant="outlined"
              name="phone"
              value={phone}
              placeholder="Enter Phone Number"
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                validatePhone(e.target.value);
              }}
              onBlur={() => validatePhone(phone)}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  // - The Input-root, inside the TextField-root
                  "& fieldset": {
                    // - The <fieldset> inside the Input-root
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
            />
            {errors.phone && (
              <FormHelperText sx={{ color:theme.palette.error.main, fontWeight: 520 }}>
                {errors.phone}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid
          lg={12}
          md={12}
          xs={12}
          sm={12}
          xl={12}
          marginTop={"20px"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
              backgroundColor: theme.palette.info.light,
              borderRadius: "25px",
            }}
            width={"300px"}
            padding={"7px"}
          >
            <Button
              size="large"
              variant={inputType === "UPI" ? "contained" : "outlined"}
              sx={{
                fontSize: "16px",
                fontWeight: 700,
                width: "150px",
                borderRadius: "25px",
                backgroundColor:
                  inputType === "UPI" ? theme.palette.primary.main : "none",
                color:
                  inputType === "UPI" ? "#FFFFFF" : theme.palette.text.primary,
                borderColor:
                  inputType === "UPI" ? "none" : theme.palette.primary.main,
                "&:hover": {
                  backgroundColor:
                    inputType === "UPI" ? theme.palette.primary.main : "none",
                  borderColor:
                    inputType === "UPI" ? "none" : theme.palette.primary.main,
                },
              }}
              onClick={() => {
                setIsUpi(true);
                setInputType("UPI");
              }}
            >
              UPI
            </Button>
            <Button
              variant={inputType === "Bank" ? "contained" : "outlined"}
              sx={{
                fontSize: "16px",
                fontWeight: 700,
                width: "150px",
                borderRadius: "25px",
                backgroundColor:
                  inputType === "Bank" ? theme.palette.primary.main : "none",
                color:
                  inputType === "Bank" ? "#FFFFFF" : theme.palette.text.primary,
                borderColor:
                  inputType === "Bank" ? "none" : theme.palette.primary.main,
                "&:hover": {
                  backgroundColor:
                    inputType === "Bank" ? theme.palette.primary.main : "none",
                  borderColor:
                    inputType === "Bank" ? "none" : theme.palette.primary.main,
                },
              }}
              onClick={() => {
                setIsUpi(false);
                setInputType("Bank");
              }}
            >
              Bank
            </Button>
          </Stack>
        </Grid>
        {inputType === "UPI" ? (
          <Grid marginY={"15px"}>
            <FormLabel
              htmlFor="upi"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              Recipient UPI
            </FormLabel>
            <TextField
              fullWidth
              id="upi"
              size="small"
              variant="outlined"
              name="upi"
              value={upi}
              placeholder="Example@abc"
              onChange={(e) => {
                setupi(e.target.value);
                validateUpi(e.target.value);
              }}
              onBlur={() => validateUpi(upi)}
              InputProps={{
                style: {
                  borderRadius: "14px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  // - The Input-root, inside the TextField-root
                  "& fieldset": {
                    // - The <fieldset> inside the Input-root
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
            />
            {errors.upi && (
              <FormHelperText sx={{ color: theme.palette.error.main, fontWeight: 520 }}>
                {errors.upi}
              </FormHelperText>
            )}
          </Grid>
        ) : (
          <Grid lg={12} md={12} xs={12} sm={12} xl={12}>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginY: "15px",
              }}
              lg={12}
              md={12}
              xs={12}
              sm={12}
              xl={12}
            >
              <Grid
                lg={7}
                md={7}
                xs={7}
                sm={7}
                xl={7}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <FormLabel
                  htmlFor="accNo"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "14px",
                    fontWeight: 500,
                    color: theme.palette.text.primary,
                  }}
                >
                  Account Number
                </FormLabel>
                <TextField
                  id="accNo"
                  size="small"
                  variant="outlined"
                  name="accNo"
                  value={account}
                  placeholder="1234 5678 1234"
                  onChange={(e) => {
                    setAccount(e.target.value);
                    validateAccount(e.target.value);
                  }}
                  onBlur={() => validateAccount(account)}
                  InputProps={{
                    style: {
                      borderRadius: "14px",
                      padding: "3px",
                      marginTop: "5px",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: "1px",
                      color: theme.palette.text.primary,
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      // - The Input-root, inside the TextField-root
                      "& fieldset": {
                        // - The <fieldset> inside the Input-root
                        borderColor: theme.palette.text.primary,
                      },
                      "&:hover fieldset": {
                        borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                      },
                      "&.Mui-focused fieldset": {
                        // - Set the Input border when parent is focused
                        borderColor: theme.palette.text.primary,
                      },
                    },
                  }}
                />
                {errors.account && (
                  <FormHelperText sx={{ color: theme.palette.error.main, fontWeight: 520 }}>
                    {errors.account}
                  </FormHelperText>
                )}
              </Grid>

              <Grid
                lg={4.5}
                md={4.5}
                xs={4.5}
                sm={4.5}
                xl={4.5}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <FormLabel
                  htmlFor="ifscode"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "14px",
                    fontWeight: 500,
                    color: theme.palette.text.primary,
                  }}
                >
                  IFSC Code
                </FormLabel>
                <TextField
                  id="ifscode"
                  size="small"
                  variant="outlined"
                  name="ifscode"
                  value={code}
                  placeholder="Enter IFSC Code"
                  onChange={(e) => {
                    setCode(e.target.value);
                    validateCode(e.target.value);
                  }}
                  onBlur={() => validateCode(code)}
                  InputProps={{
                    style: {
                      borderRadius: "14px",
                      padding: "3px",
                      marginTop: "5px",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: "1px",
                      color: theme.palette.text.primary,
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      // - The Input-root, inside the TextField-root
                      "& fieldset": {
                        // - The <fieldset> inside the Input-root
                        borderColor: theme.palette.text.primary,
                      },
                      "&:hover fieldset": {
                        borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                      },
                      "&.Mui-focused fieldset": {
                        // - Set the Input border when parent is focused
                        borderColor: theme.palette.text.primary,
                      },
                    },
                  }}
                />
                {errors.code && (
                  <FormHelperText sx={{ color: theme.palette.error.main, fontWeight: 520 }}>
                    {errors.code}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid marginY={"15px"}>
              <FormLabel
                htmlFor="holdName"
                sx={{
                  fontSize: "14px",
                  lineHeight: "14px",
                  fontWeight: 500,
                  color: theme.palette.text.primary,
                }}
              >
                Bank Name
              </FormLabel>
              <TextField
                fullWidth
                id="bankName"
                size="small"
                variant="outlined"
                name="bankName"
                value={holderBank}
                placeholder="Name of Bank"
                onChange={(e) => {
                  setHolderBank(e.target.value);
                  validateHolder(e.target.value);
                }}
                onBlur={() => validateHolder(holderBank)}
                InputProps={{
                  style: {
                    borderRadius: "14px",
                    padding: "3px",
                    marginTop: "5px",
                    fontWeight: 400,
                    fontSize: "14px",
                    border: "1px",
                    color: theme.palette.text.primary,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    // - The Input-root, inside the TextField-root
                    "& fieldset": {
                      // - The <fieldset> inside the Input-root
                      borderColor: theme.palette.text.primary,
                    },
                    "&:hover fieldset": {
                      borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                    },
                    "&.Mui-focused fieldset": {
                      // - Set the Input border when parent is focused
                      borderColor: theme.palette.text.primary,
                    },
                  },
                }}
              />
              {errors.holder && (
                <FormHelperText sx={{ color: theme.palette.error.main, fontWeight: 520 }}>
                  {errors.holder}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        )}
        <Button
        type="submit"
          variant="contained"
          sx={{
            textAlign: "center",
            alignSelf: "center",
            borderRadius: "26px",
            paddingX: "8px",
            paddingY: "15px",
            marginY: "15px",
            width:'150px',
            bgcolor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          onClick={handleAddRecipient}
        >
          <label
            style={{
              fontSize: "16px",
              fontWeight: 500,
              color: '#FFFFFF',
              lineHeight: "16px",
              textTransform:'initial'
            }}
          >
            Add Recipient
          </label>
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddrecipientsModal;
