import React, { useEffect, useState } from "react";
import { Grid, Chip, Box, Pagination } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import { format } from "date-fns";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProgressLoader from "./Loader";
import { getTotalTransactions } from "../services/Transactionapis";

interface MappedTransaction {
  rowId: any;
  name: string;
  usdAmount: number;
  inrAmount: number;
  status: string;
}

interface Transaction {
  _id: string;
  recipients_id: string;
  usd_transfer_amount: number;
  inr_transfer_amount: number;
  transaction_initiation_at: string;
  is_completed: boolean;
  is_error: boolean;
  is_pending: boolean;
  is_refunded: boolean;
  notes: string;
  transaction_completed_time: Date | null;
  refund_initiated_at: Date | null;
  refund_completed_at: Date | null;
  inr_transaction_number: string | null;
  usd_transaction_number: string | null;
  refund_transaction_number: string | null;
}

export function SortedDescendingIcon() {
  const { theme } = useTheme();
  return <ExpandMoreIcon sx={{ color: theme.palette.info.main }} />;
}

export function SortedAscendingIcon() {
  const { theme } = useTheme();
  return <ExpandLessIcon sx={{ color: theme.palette.info.main }} />;
}

const TransactionTable: React.FC = () => {
  const { theme } = useTheme();
  const [trasactionData, setTransactionData] = useState<MappedTransaction[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = React.useState(1);

  function getStatus(transaction: Transaction): string {
    if (transaction.is_pending) {
      return "pending";
    } else if (transaction.is_refunded) {
      return "refunded";
    } else if (transaction.is_error) {
      return "error";
    } else if (transaction.is_completed) {
      return "completed";
    } else {
      return "unknown"; // Optional: Handle other cases if needed
    }
  }

  const fetchTransactions = async (page: number) => {
    setLoading(true);
    try {
      const result = await getTotalTransactions(page);
      setCount(result.count);
      const mappedData: MappedTransaction[] = result.data.map(
        (transaction: Transaction, index: number) => ({
          rowId: index,
          name: transaction.recipients_id,
          usdAmount: transaction.usd_transfer_amount,
          inrAmount: transaction.inr_transfer_amount,
          date: format(
            new Date(transaction.transaction_initiation_at),
            "dd/MM/yyyy"
          ),
          status: getStatus(transaction),
        })
      );

      // console.log(mappedData);
      setTransactionData(mappedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions(page);
  }, [page]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <label style={{ color: theme.palette.text.primary }}>
          {params.value}
        </label>
      ),
    },
    {
      field: "usdAmount",
      headerName: "USD Amount",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <label style={{ color: theme.palette.text.primary }}>
          {`$${params.value}`}
        </label>
      ),
    },
    {
      field: "inrAmount",
      headerName: "INR Amount",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <label style={{ color: theme.palette.text.primary, outline: "none" }}>
          {`₹${params.value}`}
        </label>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <label style={{ color: theme.palette.text.primary, outline: "none" }}>
          {params.value}
        </label>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        let statusColor: string;

        switch (params.value.toLowerCase()) {
          case "pending":
            statusColor = "#EEDC82";
            break;
          case "refunded":
            statusColor = "blue";
            break;
          case "error":
            statusColor = "red";
            break;
          case "completed":
            statusColor = "success";
            break;
          default:
            statusColor = "inherit";
        }

        return (
          <Chip
            label={params.value}
            sx={{
              color: "#FFFFFF",
              backgroundColor: statusColor,
              fontSize: "14px",
              cursor: "default",
              fontWeight: 500,
            }}
          />
        );
      },
    },
  ];

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  return (
    <Grid
      sx={{
        padding: "20px",
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
      }}
      lg={12}
      md={12}
      xs={12}
      sm={12}
      xl={12}
    >
      <Grid>
        <label
          style={{
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "32px",
            color: theme.palette.text.primary,
          }}
        >
          Transaction History
        </label>
      </Grid>
      <Grid marginTop={"15px"}>
        {loading ? (
          <Grid
            width={"100%"}
            height={"100%"}
            lg={12}
            sm={12}
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProgressLoader strokeColor="#6D1ED4" fillColor="#6D1ED4" />
          </Grid>
        ) : (
          <DataGrid
            rows={trasactionData}
            columns={columns}
            autoHeight
            getRowId={(row) => row.rowId}
            autoPageSize
            disableColumnMenu // disable column menu filter options
            disableColumnResize // disable column resizing
            // pagination
            hideFooterPagination={true}
            disableColumnSelector={true}
            hideFooter={true}
            disableRowSelectionOnClick // disable row selection on click
            slots={{
              columnSortedDescendingIcon: SortedDescendingIcon,
              columnSortedAscendingIcon: SortedAscendingIcon,
            }}
            sx={{
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-row": {
                border: "none", // Remove borders for rows
                flex: "1",
                // bgcolor: theme.palette.info.light
              },
              "& .MuiDataGrid-cell": {
                border: "none",
                bgcolor: theme.palette.info.light, // Adjust cell padding as needed
                flex: "1",
              },
              "& .MuiDataGrid-columnHeader": {
                border: "none", // Remove border from column headers
                bgcolor: theme.palette.info.light,
                fontFamily: "Inter",
                color: theme.palette.info.main,
                fontWeight: 500,
                fontSize: "15px",
                lineHeight: "24px",
                flex: "1",
              },
              "& .MuiDataGrid-iconButtonContainer": {
                visibility: "visible",
                outline: "none",
              },
              "& .MuiDataGrid-sortIcon": {
                opacity: "inherit !important",
                color: theme.palette.info.main,
                outline: "none",
              },
              "&, [class^=MuiDataGrid]": { border: "none" },
              border: "none",
              fontFamily: "Inter",
              color: theme.palette.info.main,
              fontWeight: 500,
              bgcolor: theme.palette.info.light,
              fontSize: "15px",
              lineHeight: "24px",
              flex: "1",
            }}
          />
        )}
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Pagination
            count={Math.ceil(count / 10)}
            page={page}
            onChange={handleChange}
            sx={{
              "& .MuiPaginationItem-root": {
                color:theme.palette.text.primary,
                "&.Mui-selected":{
                  bgcolor:theme.palette.primary.main,
                  color:'#FFFFFF'
                }
              },
              
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
export default TransactionTable;
