import React from "react";
import "./App.css";
import { useRoutes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ThemeProvider, useTheme } from "./assets/themeContext";
// import { CssBaseline } from '@mui/material';
import routes from "./routes/index";
import ScrollTop from "./components/ScrollTop";
import ThemeSwitch from "./components/themeSwitch";
import SmoothScroll from "./components/SmoothScroll";

function App() {
  let element = useRoutes(routes);
  return (
    <ThemeProvider>
      {/* <CssBaseline /> TODO: if added fonts will change to deafult --->so remove it*/}
      {/* <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
          rel="stylesheet"
        />
        <link href="https://fonts.cdnfonts.com/css/arista-pro-alternate" rel="stylesheet"></link>
      </Helmet> */}

      <SmoothScroll> 
        <ScrollTop>
          <div>
            <ThemeSwitch />
            {element}
            </div>
        </ScrollTop>
      </SmoothScroll>
    </ThemeProvider>
  );
}

export default App;
