import React from "react";
import { Grid, Box, Avatar } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import { useNavigate } from "react-router-dom";

const TopRefferels = () => {
  const { theme } = useTheme();
  const navigate = useNavigate()

  const transfer = () => {
    alert("go");
  };

  const rows = [
    { name: "Jane's", value: 45 },
    { name: "Jane Smith", value: 66.09 },
    { name: "John Doe", value: 55.0 },
    { name: "Alice Johnson", value: 77 },
  ];

  return (
    <Grid
      sx={{
        paddingX: "20px",
        paddingTop: "20px",
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
      }}
      lg={12}
      md={12}
      xs={12}
      sm={12}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid>
          <label
            style={{
              fontSize: "20px",
              fontWeight: 500,
              paddingRight: "20px",
              color: theme.palette.primary.main,
            }}
          >
            Top Referrals
          </label>
        </Grid>
        <Grid onClick={()=>navigate('/rewardsPage')}>
          <label
            style={{
              paddingTop: "2px",
              flex: 1,
              fontSize: "16px",
              fontWeight: 500,
              color: theme.palette.primary.main,
              cursor: "pointer",
            }}
          >
            See all
          </label>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingTop: "25px",
          paddingBottom: "10px",
        }}
      >
        <Grid>
          <label
            style={{
              flex: 1,
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.info.main,
              paddingLeft: "15px",
            }}
          >
            Name
          </label>
        </Grid>
        <Grid>
          <label
            style={{
              paddingTop: "2px",
              flex: 1,
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.info.main,
              cursor: "pointer",
              paddingRight: "10px",
            }}
          >
            Referral earnings
          </label>
        </Grid>
      </Grid>
      <Grid
        paddingTop={2}
        sx={{
          padding: "10px",
          //backgroundColor: 'white',
          borderRadius: "10px",
          bgcolor: theme.palette.info.light,
          //transition: 'box-shadow 0.3s ease',
          // '&:hover': {
          //     boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)' ,
          //   marginBottom: '10px'
          // },
        }}
      >
        {rows.map((row, index) => (
          <Grid key={index} sx={{ paddingY: "0px" }}>
            <Box
              sx={{
                padding: "10px 8px",
                // backgroundColor: 'white',
                borderRadius: "10px",
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
                bgcolor: theme.palette.info.light,
                transition: "box-shadow 0.3s ease",
                marginBottom: "10px",
                "&:hover": {
                  boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              <Avatar
                sx={{
                  width: "40px",
                  height: "40px",
                  bgcolor: "rgba(109, 30, 212, 0.3)",
                }}
              />

              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flex: 1,
                  paddingLeft: "4px",
                }}
              >
                <Grid>
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: theme.palette.text.primary,
                    }}
                  >
                    {row.name}
                  </label>
                </Grid>

                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: theme.palette.info.main,
                    cursor: "pointer",
                  }}
                  //onClick={()}
                >
                  ${row.value}
                </label>
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default TopRefferels;
