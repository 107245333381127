import React, { useEffect } from "react";
import NavBar from "../components/HeaderBar"; // Import your NavBar component
import { Grid } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import FrequentReceipient from "../components/FrequentReceipients";
import CurrencyConverter from "../components/CurrencyConverter";
import PaymentsMethods from "../components/PaymentsMethods";
import ReferralCard from "../components/ReferralCard";

const PaymentMethods: React.FC = () => {
  const { theme } = useTheme();

  useEffect(() => {
    document.title = "AptRemit-Payment Methods";
  });

  return (
    <Grid
      //lg={12}
      sx={{ backgroundColor: theme.palette.primary.dark, height: "100vh" }}
    >
      <NavBar highlightDashboard={true} /> {/* Render NavBar component */}
      <Grid
        sx={{ backgroundColor: theme.palette.primary.dark }}
        paddingX={"20px"}
      >
        <label
          style={{
            color: theme.palette.primary.main,
            fontSize: "34px",
            fontWeight: 700,
            lineHeight: "42px",
          }}
        >
          Payment Methods
        </label>
        <Grid
          container
          lg={12}
          xl={12}
          sm={12}
          md={12}
          xs={12}
          paddingY={"20px"}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent:'space-around',
            gap: "20px",
            flexWrap: "wrap",
          }}
        >
          
          <Grid lg={4} md={5.8} xl={4} sm={12} xs={12}>
            <PaymentsMethods/>
          </Grid>
          <Grid lg={4} md={5.8} xl={4} sm={12} xs={12}>
            <CurrencyConverter />
          </Grid>
          <Grid lg={3.5} md={12} xl={3.5} sm={12} xs={12} sx={{display:'flex', flexDirection:"row", flexWrap:'wrap', gap:'20px', justifyContent:'space-between'}}>
            <Grid lg={12} md={5.8} xl={12} sm={12} xs={12}>
            <FrequentReceipient/>
            </Grid>
            <Grid lg={12} md={5.8} xl={12} sm={12} xs={12}>
              <ReferralCard/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentMethods;
