const authApi = "https://api-dev.aptremit.io/auth-api";
//const token = sessionStorage.getItem('access_token')

export const  Login = async userData => {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Content-Type', 'application/json');
  
    const raw = JSON.stringify({
      username: userData.username,
      password: userData.password,
    });
  
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
  
    const response = await fetch(`${authApi}/api/auth/login`,requestOptions);
    const result = await response.json();
    if (result.access_token) {
      return {
        success: true,
        access_token: result.access_token,
        refresh_token: result.refresh_token,
        message: 'Login Success',
      };
    } else {
      return {
        success: false,
        message: result.detail,
      };
    }
  };


  export const  SignUp = async userData => {
    const myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Content-Type', 'application/json');

    console.log(userData)
  
    const raw = JSON.stringify({
      first_name: userData.firstname,
      middle_name: userData.middlename,
      last_name: userData.lastname,
      email: userData.username,
      // eslint-disable-next-line no-dupe-keys
      password: userData.password,
      dob: userData.dob,
      us_mobile_number:userData.phone
    });
  
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
  
    const response = await fetch(`${authApi}/api/auth/register`,requestOptions);
    const result = await response.json();
    if (result.access_token) {
      return {
        success: true,
        access_token: result.access_token,
        refresh_token: result.refresh_token,
        message: 'Login Success',
      };
    } else {
      return {
        success: false,
        message: result.detail,
      };
    }
  };


  export const getAccess =async(refreshToken)=>{
    try{
      const response = await fetch(`${authApi}/api/auth/refresh`,{
        method:'GET',
        headers:{
          Accept: "application/json",
          Authorization:`Bearer ${refreshToken}`
        }
      })
      if(!response.ok){
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json()
      return data 
    }catch(error){
      console.error("could not fetch access token",error)
      return error
    }
  }