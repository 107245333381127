import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  FormLabel,
  TextField,
  FormHelperText,
  Dialog,
  DialogContent,
} from "@mui/material";
import { getUserDetails } from "../services/Profileapis";
import { useTheme } from "../assets/themeContext";
import VerifyEmail from "./verifyEmail";
import VerifyMobile from "./verifyMobile";

interface MappedUser {
  email: string;
  usMobileNumber: string;
}

interface Errors {
  email?: string | null;
  phone?: string | null;
}

const UpdatePersonal: React.FC = () => {
  const { theme } = useTheme();
  const [userData, setUserData] = useState<MappedUser>({} as MappedUser);
  const [personData, setPersonData] = useState<MappedUser>({} as MappedUser);
  const [errors, setErrors] = useState<Errors>({});
  const [openEmailDialog, setOpenEmailDialog] =React.useState<boolean>(false);
  const [openPhoneDialog, setOpenPhoneDialog] =React.useState<boolean>(false);

  

  console.log(personData);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await getUserDetails();
      const mappedUser: MappedUser = {
        email: response.email,
        usMobileNumber: response.us_mobile_number,
      };
      setUserData(mappedUser);
      setPersonData(mappedUser);
    } catch (error) {
      console.log(error);
    }
  };

  // mail Validation
  const validateEmail = (text: string): boolean => {
    // if there is no text on blur event
    if (!text) {
      setErrors((prev) => ({ ...prev, email: "Email is required" }));
      return false;
      // check the mail pattern
    } else {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      // if mail pattern is incorrect
      if (!emailPattern.test(text)) {
        setErrors((prev) => ({ ...prev, email: "Invalid email format" }));
        return false;
        // if mail pattern is correct
      } else {
        setErrors((prev) => ({ ...prev, email: null }));
        return true;
      }
    }
  };

  // Phone Number Validation
  const validatePhone = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, phone: "Phone number is required" }));
      return false;
    } else {
      const phonePattern = /^\d{10}$/;
      if (!phonePattern.test(text)) {
        setErrors((prev) => ({ ...prev, phone: "Invalid phone number" }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, phone: null }));
        return true;
      }
    }
  };

  return (
    <Grid width={"100%"}>
      <form>
        <Grid
          xl={12}
          lg={12}
          md={9}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            lg={8.5}
            md={9}
            sm={9}
            xs={10}
            xl={9}
            paddingY={"15px"}
            display={"flex"}
            flexDirection={"column"}
          >
            <FormLabel
              htmlFor="Email"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              Email
            </FormLabel>

            <TextField
              id="Email"
              size="small"
              fullWidth
              name="Email"
              variant="outlined"
              type="text"
              value={userData.email}
              placeholder="Enter Lastname"
              onChange={(e) => {
                setPersonData({ ...userData, email: e.target.value });
                validateEmail(e.target.value);
              }}
              onBlur={() => validateEmail(userData.email)}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  // - The Input-root, inside the TextField-root
                  "& fieldset": {
                    // - The <fieldset> inside the Input-root
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
            />
            {errors.email && (
              <FormHelperText
                sx={{
                  color: theme.palette.error.main,
                  fontWeight: 520,
                }}
              >
                {errors.email}
              </FormHelperText>
            )}
          </Grid>
          <Button
            variant="contained"
            //disabled={!isSame}
            sx={{
              borderRadius: "16px",
              paddingX: "35px",
              marginTop: "6px",
              paddingY: "15px",
              textTransform: "initial",
              bgcolor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            }}
            onClick={()=>setOpenEmailDialog(true)}
          >
            <label
              style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#FFFFFF",
                lineHeight: "16px",
                textAlign: "center",
              }}
            >
              Verify
            </label>
          </Button>
          <Dialog
            open={openEmailDialog}
            onClose={()=>setOpenEmailDialog(false)}
            fullWidth
            maxWidth="xs"
            PaperProps={{ sx: { borderRadius: "20px" } }}
          >
            <DialogContent sx={{ bgcolor: theme.palette.primary.dark}}>
              <VerifyEmail setOpenEmailDialog={setOpenEmailDialog}/>
            </DialogContent>
          </Dialog>
        </Grid>
        <Grid
          xl={12}
          lg={12}
          md={9}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            lg={8.5}
            md={9}
            sm={9}
            xs={10}
            xl={9}
            paddingY={"15px"}
            display={"flex"}
            flexDirection={"column"}
          >
            <FormLabel
              htmlFor="PhoneNumber"
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              Phone Number
            </FormLabel>

            <TextField
              id="phoneNumber"
              size="small"
              fullWidth
              name="phone"
              variant="outlined"
              type="text"
              value={userData.usMobileNumber}
              placeholder="Your Mobile Number"
              onChange={(e) => {
                setPersonData({ ...userData, usMobileNumber: e.target.value });
                validatePhone(e.target.value);
              }}
              onBlur={() => validatePhone(userData.usMobileNumber)}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  // - The Input-root, inside the TextField-root
                  "& fieldset": {
                    // - The <fieldset> inside the Input-root
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
            />
            {errors.phone && (
              <FormHelperText
                sx={{
                  color: theme.palette.error.main,
                  fontWeight: 520,
                }}
              >
                {errors.phone}
              </FormHelperText>
            )}
          </Grid>
          <Button
            variant="contained"
            //disabled={!isSame}
            sx={{
              borderRadius: "16px",
              paddingX: "35px",
              marginTop: "6px",
              paddingY: "15px",
              textTransform: "initial",
              bgcolor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            }}
            onClick={()=>setOpenPhoneDialog(true)}
          >
            <label
              style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#FFFFFF",
                lineHeight: "16px",
                textAlign: "center",
              }}
            >
              Verify
            </label>
          </Button>
          <Dialog
            open={openPhoneDialog}
            onClose={()=>setOpenPhoneDialog(false)}
            fullWidth
            maxWidth="xs"
            PaperProps={{ sx: { borderRadius: "20px" } }}
          >
            <DialogContent sx={{ bgcolor: theme.palette.primary.dark}}>
              <VerifyMobile setOpenPhoneDialog={setOpenPhoneDialog}/>
            </DialogContent>
          </Dialog>
        </Grid>
      </form>
    </Grid>
  );
};
export default UpdatePersonal;
