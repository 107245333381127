import React from "react";
import { Grid, Box, Avatar } from "@mui/material";
import { useTheme } from "../assets/themeContext";

const UserCard: React.FC = () => {
  const { theme } = useTheme();
  return (
    <Grid
    sx={{
      padding: '20px',
      borderRadius: '20px',
      bgcolor: theme.palette.info.light,
    }}
    lg={12}
    md={12}
    sm={12}
    xs={12}
    xl={12}
  >
    <Grid lg={12} md={12} sm={12} xl={12} xs={12}> 
      <Grid lg={12} md={12} sm={12} xl={12} xs={12}
         sx={{
          backgroundImage: `url("https://res.cloudinary.com/dtobhrhpi/image/upload/v1710838681/milad-fakurian-nY14Fs8pxT8-unsplash_r7ralf.jpg")`,
          height: '131px',
          backgroundSize: 'cover',
          borderRadius: '16px',
          backgroundPosition: 'center center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Avatar
          sx={{
            width: 87,
            height: 87,
            border: '5px solid #fff',
            position: 'absolute',
            bottom: -40,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        />
      </Grid>
    </Grid>
    <Grid lg={12} md={12} marginTop={'45px'} sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', padding:'0'}}>
        <label style={{
            fontSize:'20px',
            fontWeight:700,
            lineHeight:'32px',
            color:theme.palette.primary.main,
        }}
        >
        Alex Parker
        </label>
        <label
        style={{
            fontSize:'14px',
            fontWeight:500,
            lineHeight:'24px',
            color:theme.palette.info.main, 
        }}
        >
        alexparker@gmail.com
        </label>
      </Grid>
      <Grid marginY={'20px'} lg={12} md={12} sx={{display:'flex', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <label style={{fontSize:'24px', fontWeight:700, lineHeight:'24px', color:theme.palette.primary.main, padding:'2px 0px'}}>
            $ 34
            </label>
            <label style={{fontSize:'14px', fontWeight:400, lineHeight:'20px', color:theme.palette.info.main, padding:'2px 0px'}}>
                Rewards
            </label>
        </Box>
        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <label style={{fontSize:'24px', fontWeight:700, lineHeight:'24px', color:theme.palette.primary.main, padding:'2px 0px'}}>
            $ 2500
            </label>
            <label style={{fontSize:'14px', fontWeight:400, lineHeight:'20px', color:theme.palette.info.main, padding:'2px 0px'}}>
                Transfers
            </label>
        </Box>
        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <label style={{fontSize:'24px', fontWeight:700, lineHeight:'24px', color:theme.palette.primary.main, padding:'2px 0px'}}>
            10
            </label>
            <label style={{fontSize:'14px', fontWeight:400, lineHeight:'20px', color:theme.palette.info.main, padding:'2px 0px'}}>
                Referrals
            </label>
        </Box>
      </Grid>
  </Grid>
  );
};
export default UserCard;
