import React from 'react';
import { PieChart} from '@mui/x-charts/PieChart';
import {Grid} from '@mui/material'

const data = [
  { value: 65, label: 'Madisson Clarke' },
  { value: 12.5, label: 'Jane Smith' },
  { value: 22.5, label: 'Anthony Hopkins' },
];

const PieCharts: React.FC = () => {
  const palette = ['#6D1ED4', '#553382', '#A36AFF'];
  return (
      <Grid sx={{display:"flex", flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
        <PieChart
      colors={palette}
      margin={{ left:70}}
      slotProps={{ legend: { hidden: true }}}
      series={[
        {
          data,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        },
      ]}
      height={250}
      width={370}
    />
      </Grid>
  );
}
export default PieCharts