import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  useMediaQuery,
  useTheme as T,
  FormLabel,
  FormHelperText,
  Checkbox,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { Email, Visibility, VisibilityOff } from "@mui/icons-material";
import { useTheme } from "../assets/themeContext";
import { useNavigate } from "react-router-dom";
import AuthBackground from "../components/AuthBackground";
import { contactData } from "../utils/Links";
import { toast, ToastContainer } from "react-toastify";
import { SignUp } from "../services/Authapis";
import { DatePicker } from "antd";
import dayjs, { Dayjs, PluginFunc } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter as PluginFunc);

dayjs.extend(isSameOrAfter);

interface Errors {
  date?:string | null;
  firstName?: string | null;
  lastName?: string | null;
  // middleName?: string | null;
  mail?: string | null;
  password?: string | null;
  phone?: string | null;
}

const HomeScreen: React.FC = () => {
  const t = T();
  const dateFormat = 'DD/MM/YYYY';
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [mail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [countryCode, setCountryCode] = React.useState("CA");
  const [phone, setPhoneNumber] = React.useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [refferalCode, setRefferalCode] = useState("");
  const [errors, setErrors] = useState<Errors>({});
  const isSmallScreen = useMediaQuery(t.breakpoints.down("sm"));
  const is900Screen = useMediaQuery(t.breakpoints.down(900));

  const isSame = password === confirmPassword;

  useEffect(() => {
    document.title = "AptRemit-SignUp";
  });

  const disabledDate = (current: Dayjs) => {
    return current && current.isSameOrAfter(dayjs().startOf('day'));
  };

  // for visibilty icons
  function handleTogglePasswordVisibility() {
    setShowPassword((prevState) => !prevState);
  }

  const confirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const handleSignUp = async (event: React.FormEvent) => {
    event.preventDefault()
    if (
      !firstName ||
      !lastName ||
      // !middleName ||
      !Email ||
      !password ||
      !confirmPassword ||
      !phone 
      //!isChecked ||
      //!refferalCode
    ) {
      toast.error("Fields are Empty", { autoClose: 2000 });
      return;
    }
    try {
      const response = await SignUp({
        username: mail,
        password: password,
        firstname: firstName,
        middlename: middleName,
        lastname: lastName,
        dob: dayjs(selectedDate).format("DD/MM/YYYY"),
        phone:phone,
        countryCode:countryCode,
      });
      console.log("Response:", response);
       toast.success("SignUp Successfull", { autoClose: 2000 });
       setTimeout(() => {
        navigate("/login");
      }, 1000);
      if (response.success) {
        console.log("message:", response.message);
      } else {
        console.log("message:", response.message);
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };


  const handleDateChange = (date: any) => {
    if (!date) {
      setSelectedDate(null);
      setErrors((prev) => ({ ...prev, date: "Date Of Birth is required" }));
    } else {
      setSelectedDate(date);
      setErrors((prev) => ({ ...prev, date: null }));
    }
  };

  const handleBlur = (date: Date | null) => {
    if (!date) {
      setErrors((prev) => ({ ...prev, date: "Date Of Birth is required" }));
    } else {
      setErrors((prev) => ({ ...prev, date: null }));
    }
  };

  /* Validations*/
  // first Name Validation
  const validateFirstName = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, firstName: "First name is required" }));
      return false;
    } else {
      if (text.length < 3) {
        setErrors((prev) => ({
          ...prev,
          firstName: "First Name must be at least 3 characters",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, firstName: null }));
        return true;
      }
    }
  };
  // last Name Validation
  const validateLastName = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, lastName: "Last name is required" }));
      return false;
    } else {
      if (text.length < 3) {
        setErrors((prev) => ({
          ...prev,
          lastName: "Last Name must be at least 3 characters",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, lastName: null }));
        return true;
      }
    }
  };
  // Middle Name Validation
  const validateMiddleName = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, middleName: "Middle name is required" }));
      return false;
    } else {
      if (text.length < 3) {
        setErrors((prev) => ({
          ...prev,
          middleName: "Middle Name must be at least 3 characters",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, middleName: null }));
        return true;
      }
    }
  };
  // mail Validation
  const validateEmail = (text: string): boolean => {
    // if there is no text on blur event
    if (!text) {
      setErrors((prev) => ({ ...prev, mail: "Email is required" }));
      return false;
      // check the mail pattern
    } else {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z.]{2,4}$/i;
      // if mail pattern is incorrect
      if (!emailPattern.test(text)) {
        setErrors((prev) => ({ ...prev, mail: "Invalid email format" }));
        return false;
        // if mail pattern is correct
      } else {
        setErrors((prev) => ({ ...prev, mail: null }));
        return true;
      }
    }
  };
  // password Validation
  const validatePassword = (text: string): boolean => {
    // if there is no text on blur event
    if (!text) {
      setErrors((prev) => ({ ...prev, password: "Password is required" }));
      return false;
    } else {
      if (text.length < 8) {
        // Password length validation
        setErrors((prev) => ({
          ...prev,
          password: "Password must be at least 8 characters long",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, password: null }));
        return true;
      }
    }
  };


  // Phone Number Validation
  const validatePhone = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, phone: "Phone number is required" }));
      return false;
    } else {
      const phonePattern = /^\d{10}$/;
      if (!phonePattern.test(text)) {
        setErrors((prev) => ({ ...prev, phone: "Invalid phone number" }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, phone: null }));
        return true;
      }
    }
  };

  

  return (
    // Main Grid
    <Grid
      container
      lg={12}
      md={12}
      sm={12}
      display={"flex"}
      flexDirection={"row"}
      sx={{ justifyContent: is900Screen ? "center" : null }}
    >
      {/* Container */}
      <Grid
        lg={6}
        md={8}
        sm={12}
        xs={12}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        height={is900Screen ? "100vh" : "null"}
      >
        {/* Form container */}
        <Grid
          lg={7}
          md={10}
          sm={10}
          xs={10}
          width={isSmallScreen ? "90%" : "60%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <label
            style={{
              fontSize: "36px",
              fontWeight: 700,
              lineHeight: "56px",
              color: theme.palette.secondary.main,
              paddingBottom: "10px",
            }}
          >
            Sign Up
          </label>

          <Grid lg={12} md={12} sm={12} xs={12}>
            {/* Form */}
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              onSubmit={handleSignUp}
            >
              {/* First name and Middle Name */}
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                marginBottom={"2px"}
              >
                <Grid
                  lg={5.8}
                  md={5.8}
                  sm={5.8}
                  xs={5.5}
                  paddingY={"15px"}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  {/* First Name */}
                  <FormLabel
                    htmlFor="First Name"
                    sx={{
                      fontSize: "14px",
                      lineHeight: "14px",
                      fontWeight: 500,
                      color: theme.palette.secondary.main,
                    }}
                  >
                    First Name
                  </FormLabel>
                  <TextField
                    id="First Name"
                    size="small"
                    name="First Name"
                    variant="outlined"
                    type="text"
                    value={firstName}
                    placeholder="Enter Firstname"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      validateFirstName(e.target.value);
                    }}
                    onBlur={() => validateFirstName(firstName)}
                    InputProps={{
                      style: {
                        borderRadius: "16px",
                        padding: "3px",
                        marginTop: "5px",
                        fontWeight: 400,
                        fontSize: "14px",
                        border: "1px  rgba(156, 174, 184, 0.5)",
                      },
                    }}
                  />
                  {errors.firstName && (
                    <FormHelperText
                      sx={{
                        color: theme.palette.error.main,
                        fontWeight: 520,
                      }}
                    >
                      {errors.firstName}
                    </FormHelperText>
                  )}
                </Grid>
                {/* Middle Name */}
                {/* <Grid
                  lg={5.8}
                  md={5.8}
                  sm={5.8}
                  xs={5.5}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <FormLabel
                    htmlFor="Middle Name"
                    sx={{
                      fontSize: "14px",
                      lineHeight: "14px",
                      fontWeight: 500,
                      color: theme.palette.secondary.main,
                    }}
                  >
                    Middle Name
                  </FormLabel>
                  <TextField
                    id="Middle Name"
                    size="small"
                    name="Middle Name"
                    variant="outlined"
                    type="text"
                    value={middleName}
                    placeholder="Enter Middlename"
                    onChange={(e) => {
                      setMiddleName(e.target.value);
                      validateMiddleName(e.target.value);
                    }}
                    onBlur={() => validateMiddleName(middleName)}
                    InputProps={{
                      style: {
                        borderRadius: "16px",
                        padding: "3px",
                        marginTop: "5px",
                        fontWeight: 400,
                        fontSize: "14px",
                        border: "1px  rgba(156, 174, 184, 0.5)",
                      },
                    }}
                  />
                  {errors.middleName && (
                    <FormHelperText
                      sx={{
                        color: theme.palette.error.main,
                        fontWeight: 520,
                      }}
                    >
                      {errors.middleName}
                    </FormHelperText>
                  )}
                </Grid> */}
                <Grid lg={5.8} md={5.8} sm={5.8} xs={5.5}  paddingY={"15px"} mt={1}
                  display={"flex"}
                  flexDirection={"column"}>
                  <Grid marginBottom={"15px"}>
                    <FormLabel
                      htmlFor="Last Name"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "14px",
                        fontWeight: 500,
                        color: theme.palette.secondary.main,
                      }}
                    >
                      Last Name
                    </FormLabel>
                    <TextField
                      fullWidth
                      id="Last Name"
                      size="small"
                      name="Last Name"
                      variant="outlined"
                      type="text"
                      value={lastName}
                      placeholder="Enter Lastname"
                      onChange={(e) => {
                        setLastName(e.target.value);
                        validateLastName(e.target.value);
                      }}
                      onBlur={() => validateLastName(lastName)}
                      InputProps={{
                        style: {
                          borderRadius: "16px",
                          padding: "3px",
                          marginTop: "5px",
                          fontWeight: 400,
                          fontSize: "14px",
                          border: "1px  rgba(156, 174, 184, 0.5)",
                        },
                      }}
                    />
                    {errors.lastName && (
                      <FormHelperText
                        sx={{
                          color: theme.palette.error.main,
                          fontWeight: 520,
                        }}
                      >
                        {errors.lastName}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                
                <Grid lg={5.8} md={5.8} sm={5.8} xs={5.5} sx={{display:'flex', flexDirection:'column'}}>
                  <FormLabel
                    sx={{
                      fontSize: "14px",
                      lineHeight: "14px",
                      fontWeight: 500,
                      color: theme.palette.secondary.main,
                      paddingBottom:'7px'
                    }}
                  >
                    Date Of Birth
                  </FormLabel>
                  <DatePicker
                    value={selectedDate}
                    onChange={
                      handleDateChange}
                    onBlur={()=>handleBlur(selectedDate)}
                    format={dateFormat}
                    disabledDate={disabledDate}
                    style={{ 
                    borderRadius: "16px",
                    fontFamily:'Inter',
                    fontSize:'20px',
                    padding: "15px",
                    fontWeight: 400,
                    height:'45px',
                    marginTop:'1px',
                  }}
                  />
                  {errors.date && (
                    <FormHelperText
                      sx={{
                        color: theme.palette.error.main,
                        fontWeight: 520,
                      }}
                    >
                      {errors.date}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid lg={5.8} md={5.8} sm={5.8} xs={5.5} sx={{display:'flex', flexDirection:'column'}} mt={0.5}>
                <FormLabel
                  htmlFor="Email"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "14px",
                    fontWeight: 500,
                    color: theme.palette.secondary.main,
                  }}
                >
                  Email
                </FormLabel>
                <TextField
                  fullWidth
                  id="Email"
                  size="small"
                  variant="outlined"
                  name="email"
                  value={mail}
                  type="email"
                  placeholder="mail@simmmple.com"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    validateEmail(e.target.value);
                  }}
                  onBlur={() => validateEmail(mail)}
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                      padding: "3px",
                      marginTop: "5px",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: "1px  rgba(156, 174, 184, 0.5)",
                    },
                  }}
                />
                {errors.mail && (
                  <FormHelperText
                    sx={{ color: theme.palette.error.main, fontWeight: 520 }}
                  >
                    {errors.mail}
                  </FormHelperText>
                )}
              </Grid>
              </Grid>
              {/* Email Input Field */}
             
              {/* Password */}
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                marginBottom={"2px"}
              >
                {/* Password Input Field */}
                <Grid
                  lg={5.8}
                  md={5.8}
                  sm={5.5}
                  xs={5.8}
                  paddingY={"15px"}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <FormLabel
                    htmlFor="Password"
                    sx={{
                      fontSize: "14px",
                      lineHeight: "14px",
                      fontWeight: 500,
                      color: theme.palette.secondary.main,
                    }}
                  >
                    Password
                    {/* <span style={{color:theme.palette.primary.main, fontSize:'14px', lineHeight:"14px", fontWeight:500}}>*</span> */}
                  </FormLabel>
                  <TextField
                    fullWidth
                    id="Password"
                    size="small"
                    variant="outlined"
                    name="password"
                    value={password}
                    type={showPassword ? "text" : "password"}
                    placeholder="Min. 8 characters"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      validatePassword(e.target.value);
                    }}
                    onBlur={() => validatePassword(password)}
                    InputProps={{
                      style: {
                        borderRadius: "16px",
                        padding: "3px",
                        marginTop: "5px",
                        fontWeight: 400,
                        fontSize: "14px",
                        border: "1px rgba(156, 174, 184, 0.5)",
                      },
                      endAdornment: (
                        <Button onClick={handleTogglePasswordVisibility}>
                          {showPassword ? (
                            <Visibility sx={{ color: "#9CAEB8" }} />
                          ) : (
                            <VisibilityOff sx={{ color: "#9CAEB8" }} />
                          )}
                        </Button>
                      ),
                    }}
                  />
                  <FormHelperText
                    sx={{ color: theme.palette.error.main, fontWeight: 520 }}
                  >
                    {errors.password}
                  </FormHelperText>
                </Grid>
                {/* Confirm Password Field */}
                <Grid
                  lg={5.8}
                  md={5.8}
                  sm={5.5}
                  xs={5.8}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <FormLabel
                    htmlFor="Confirm Password"
                    sx={{
                      fontSize: "14px",
                      lineHeight: "14px",
                      fontWeight: 500,
                      color: theme.palette.secondary.main,
                    }}
                  >
                    Confirm Password
                    {/* <span style={{color:theme.palette.primary.main, fontSize:'14px', lineHeight:"14px", fontWeight:500}}>*</span> */}
                  </FormLabel>
                  <TextField
                    fullWidth
                    id="Confirm Password"
                    size="small"
                    variant="outlined"
                    name="confirm password"
                    value={confirmPassword}
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Your Password"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      // validatePassword(e.target.value)
                    }}
                    // onBlur={()=>validatePassword(password)}
                    InputProps={{
                      style: {
                        borderRadius: "16px",
                        padding: "3px",
                        marginTop: "5px",
                        fontWeight: 400,
                        fontSize: "14px",
                        border: "1px rgba(156, 174, 184, 0.5)",
                      },
                      endAdornment: (
                        <Button onClick={confirmPasswordVisibility}>
                          {showConfirmPassword ? (
                            <Visibility sx={{ color: "#9CAEB8" }} />
                          ) : (
                            <VisibilityOff sx={{ color: "#9CAEB8" }} />
                          )}
                        </Button>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              {/* Mobile Number Field */}
              <Grid lg={12} md={12} sm={12} xs={12}>
                <FormLabel
                  htmlFor="country-code-select"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "14px",
                    fontWeight: 500,
                    color: theme.palette.secondary.main,
                  }}
                >
                  Phone Number
                </FormLabel>
                {/* country codes */}
                <Grid
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid lg={2.5} md={2.5} sm={2.5} xs={2.5} xl={3}>
                    <Select
                      id="country-code-select"
                      size="small"
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                      displayEmpty
                      sx={{
                        borderRadius: "16px",
                        padding: "3px",
                        marginTop: "5px",
                        fontWeight: 400,
                        fontSize: "14px",
                        border: "1px rgba(156, 174, 184, 0.5)",
                        gap: "10px",
                      }}
                    >
                      {contactData.map((country) => (
                        <MenuItem
                          key={country.code}
                          value={country.code}
                          sx={{ padding: "3px", display: "flex", gap: "10px" }}
                        >
                          {country.flag}
                          {country.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  {/* mobile number fields */}
                  <Grid lg={8} md={8.9} sm={8.9} xs={8.5} xl={9}>
                    <TextField
                      size="small"
                      variant="outlined"
                      value={phone}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                        validatePhone(e.target.value);
                      }}
                      onBlur={() => validatePhone(phone)}
                      placeholder="Your mobile number"
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: "16px",
                          padding: "3px",
                          marginTop: "5px",
                          fontWeight: 400,
                          fontSize: "14px",
                          border: "1px rgba(156, 174, 184, 0.5)",
                        },
                      }}
                    />
                    <FormHelperText
                      sx={{
                        color: theme.palette.error.main,
                        fontWeight: 520,
                      }}
                    >
                      {errors.phone}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                lg={12}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                pt={"15px"}
              >
                <Grid item lg={6} md={6} alignItems={"center"}>
                  <Checkbox
                    checked={isChecked}
                    id="checkBox"
                    onChange={() => setIsChecked(!isChecked)}
                    size="small"
                    sx={{
                      paddingRight: "15px",
                      paddingLeft: "0px",
                      "&.Mui-checked": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                  <label
                    htmlFor="checkBox"
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: theme.palette.secondary.main,
                      lineHeight: "20px",
                    }}
                  >
                    Have a referral code
                  </label>
                </Grid>
                {isChecked ? (
                  <Grid
                    lg={6}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                  >
                    <TextField
                      name="referal code"
                      size="small"
                      variant="outlined"
                      placeholder="Referral Code"
                      value={refferalCode}
                      onChange={(e) => setRefferalCode(e.target.value)}
                      InputProps={{
                        style: {
                          borderRadius: "16px",
                          padding: "3px",
                          marginTop: "5px",
                          fontWeight: 400,
                          fontSize: "14px",
                          border: "1px rgba(156, 174, 184, 0.5)",
                        },
                      }}
                    />
                  </Grid>
                ) : null}
              </Grid>
              <Button
                variant="contained"
                disabled={!isSame}
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                  color: theme.palette.text.secondary,
                  lineHeight: "16px",
                  textAlign: "center",
                  alignSelf: "center",
                  width: "70%",
                  borderRadius: "16px",
                  paddingX: "8px",
                  paddingY: "15px",
                  marginTop: "10px",
                  bgcolor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                type="submit"
                onClick={handleSignUp}
              >
                Sign Up
              </Button>
              <Grid textAlign={"center"}>
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "26px",
                  color: theme.palette.secondary.main,
                }}
              >
                Already have an account?
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "26px",
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/login")}
                >
                  {" "}
                  Sign in
                </span>
              </label>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
      <Grid lg={6} md={4}>
        {is900Screen ? null : <AuthBackground />}
      </Grid>
      <ToastContainer />
    </Grid>
  );
};

export default HomeScreen;
