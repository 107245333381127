import React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useTheme } from "../assets/themeContext";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { ChevronUp, ChevronDown } from "lucide-react";

interface Row {
  id: number;
  name: string;
  price: number;
}

export function SortedDescendingIcon() {
  const { theme } = useTheme();
  return <ExpandMoreIcon sx={{color:theme.palette.info.main,}} />;
}

export function SortedAscendingIcon() {
  const { theme } = useTheme();
  return <ExpandLessIcon  sx={{color:theme.palette.info.main,}}/>;
}



const ReferralTable: React.FC = () => {
  const { theme } = useTheme();



  const rows: Row[] = [
    { id: 1, name: "vamsi", price: 0.5 },
    { id: 2, name: "john", price: 0.3 },
    { id: 3, name: "harthick", price: 0.7 },
    { id: 4, name: "jack", price: 0.6 },
    { id: 5, name: "joshi", price: 0.8 },
  ];

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <label>{params.value}</label>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <label>{params.value}</label>
      ),
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <label>{params.value}</label>
      ),
    },
  ];

  return (
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        autoPageSize
        disableColumnMenu // disable column menu filter options
        disableColumnResize // disable column resizing
        // pagination
        hideFooterPagination={true}
        disableRowSelectionOnClick // disable row selection on click
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
        }}
        sx={{
          "& .MuiDataGrid-cell:focus": {
            outline: 'none',
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: 'none',
          },
          "& .MuiDataGrid-row": {
            border: "none", // Remove borders for rows
            flex: "1",
            // bgcolor: theme.palette.info.light
          },
          "& .MuiDataGrid-cell": {
            border: "none",
            bgcolor: theme.palette.info.light, // Adjust cell padding as needed
            flex: "1",
          },
          "& .MuiDataGrid-columnHeader": {
            border: "none", // Remove border from column headers
            bgcolor: theme.palette.info.light,
            fontFamily: "Inter",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
            flex: "1",
          },
          "& .MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
          },
          "& .MuiDataGrid-sortIcon": {
            opacity: "inherit !important",
            color:theme.palette.info.main,
          },
          "&, [class^=MuiDataGrid]": { border: "none" },
          border: "none",
          fontFamily: "Inter",
          color: theme.palette.info.main,
          fontWeight: 500,
          bgcolor: theme.palette.info.light,
          fontSize: "15px",
          lineHeight: "24px",
          flex: "1",
        }}
      />
  );
};

export default ReferralTable;
