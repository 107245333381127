import React, { useState } from "react";
import { Grid, Input, MenuItem, Select, Box, useTheme as T, useMediaQuery} from "@mui/material";
import {
  Calendar,
  ChevronUp,
  ChevronDown,
  BarChart2,
  LineChartIcon,
} from "lucide-react";
import { useTheme } from "../assets/themeContext";
import TotalLineChart from "./LineChart";
import TotalBarChart from "./TotalBarChart";

const AnalyticsCard: React.FC = () => {
  const { theme } = useTheme();
  const t  =T();
  const isMedium  = useMediaQuery(t.breakpoints.down('md'))
  const isLarge  = useMediaQuery(t.breakpoints.down('lg'))
  const filterOptions = [
    { id: "This Month" },
    { id: "Past 3 Months" },
    { id: "Past 6 Months" },
  ];

  const [filter, setFilter] = useState<string>(filterOptions[2].id);
  const [lineChart, isLineChart] = useState(false);

  const handleChart=()=>{
    isLineChart(!lineChart)
  }
  // Percentage Color
  const percentage = 2.3;
  const percentageSymbol = percentage > 0 ? "+" : "";

  return (
    <Grid
      container
      lg={12}
      sm={12}
      md={12}
      xs={12}
      xl={12}
      sx={{
        bgcolor: theme.palette.info.light,
        padding: "20px",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "row",
        flexWrap:'wrap',
        gap:"10px"
      }}
      marginTop={isMedium?'20px':'null'}
    >
      <Grid lg={3} md={12} xs={12} sm={12} xl={3} sx={{display:'flex', flexDirection:'row', flexWrap:"wrap", alignItems:"center",gap:'20px'}}>
        <Grid>
          <Select
            style={{
              color: theme.palette.info.main,
              fontFamily: "Inter",
              padding: "5px",
              backgroundColor: theme.palette.primary.dark,
              borderRadius: "7px",
              boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            input={
              <Input
                startAdornment={
                  <Calendar
                    style={{
                      height: "25px",
                      width: "25px",
                      marginRight: "5px",
                      marginLeft: "5px",
                    }}
                    strokeWidth={2}
                  />
                }
              />
            }
            variant="standard"
            value={filter}
            label="month"
            onChange={(e) => setFilter(e.target.value)}
            disableUnderline
            sx={{
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&:hover": {
                backgroundColor: "transparent", // Prevents background color change on hover
              },
              "& .MuiSelect-select": {
                "&:focus": {
                  backgroundColor: "transparent", // Ensures the background color doesn't change on focus within the select
                },
              },
              "& .MuiSelect-icon": {
                color: theme.palette.info.main,
                display: "none", // Change to desired color
              },

              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "24px",
              color: theme.palette.info.main,
            }}
          >
            {filterOptions.map((item) => (
              <MenuItem
                value={item.id}
                key={item.id}
                sx={{
                  borderRadius: "1px",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  lineHeight: "24px",
                  fontWeight: 500,
                  color: theme.palette.info.main,
                  backgroundColor: theme.palette.info.light,
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.primary.main,
                    color: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      color: "#FFFFFF",
                    },
                  },
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: "#FFFFFF",
                  },
                }}
              >
                {item.id}
              </MenuItem>
            ))}
          </Select>
        </Grid>

       
        <Grid 
          sx={{display: "flex", flexDirection: "column" }}
        >
          <Grid>
            <label
              style={{
                fontSize: isLarge?'28px':"32px",
                fontWeight: 700,
                lineHeight: "42px",
                color: "#6D1ED4",
              }}
            >
              $ 37.5K
            </label>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <label
              style={{
                fontSize:"14px",
                fontWeight: 500,
                lineHeight: "24px",
                color: theme.palette.info.main,
              }}
            >
              Total Transfers
            </label>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {percentage > 0 ? (
                <ChevronUp
                  style={{ height: "15px", width: "15px", color: "#6D1ED4" }}
                />
              ) : (
                <ChevronDown
                  style={{ height: "15px", width: "15px", color: "#6D1ED4" }}
                />
              )}
              <label
                style={{
                  fontSize: "12px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  color: "#6D1ED4",
                  padding: "1px 0px",
                }}
              >
                {percentageSymbol}
                {percentage}
                {"%"}
              </label>
            </Grid>
          </Grid>
        </Grid>

        <Grid 
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Grid>
            <label
              style={{
                fontSize: isLarge?'28px':"32px",
                fontWeight: 700,
                lineHeight: "42px",
                color: "#4318FF",
              }}
            >
              ₹ 212
            </label>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <label
              style={{
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "24px",
                color: theme.palette.info.main,
              }}
            >
              Total Rewards
            </label>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
              }}
            >
              {percentage > 0 ? (
                <ChevronUp
                  style={{ height: "15px", width: "15px", color: "#4318FF" }}
                />
              ) : (
                <ChevronDown
                  style={{ height: "15px", width: "15px", color: "#4318FF" }}
                />
              )}
              <label
                style={{
                  fontSize: "12px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  color: "#4318FF",
                  padding: "1px 0px",
                }}
              >
                {percentageSymbol}
                {percentage}
                {"%"}
              </label>
            </Grid>
          </Grid>
        </Grid>

       
      </Grid>


      <Grid
        lg={8.5}
        md={12}
        xs={12}
        sm={12}
        xl={8.8}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
       <Grid sx={{  alignSelf: "flex-end",}}>
       <Box
          sx={{
            padding: "3px 6px",
            bgcolor: theme.palette.primary.dark,
            borderRadius: "10px",
            cursor: "pointer",
          
          }}
          onClick={handleChart}
        >
          {lineChart ? (
            <LineChartIcon
              style={{
                width: "22px",
                height: "22px",
                color: "rgba(109, 30, 212, 1)",
              }}
              strokeWidth={2.5}
            />
          ) : (
            <BarChart2
              style={{
                width: "22px",
                height: "22px",
                color: "rgba(109, 30, 212, 1)",
              }}
              strokeWidth={2.5}
            />
          )}
        </Box>
       </Grid>
        <Grid lg={12} md={12} sm={12} xs={12} xl={12} marginTop={"20px"} sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
         {lineChart? <TotalBarChart/>: <TotalLineChart />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AnalyticsCard;
