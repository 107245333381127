import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useTheme } from "../assets/themeContext";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/reducers";
import { selectCard, deslectCard } from "../redux/slices/chooseRecSlice";
import {
  Grid,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Pagination,
  Avatar,
  useTheme as T,
  useMediaQuery,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getTotalRecipients, deleteRecipient } from "../services/RecipientApis";
// import { ChevronUp, ChevronDown } from "lucide-react";
import { useNavigate } from "react-router-dom";
import ProgressLoader from "./Loader";
import { CreditCard, QrCode } from "lucide-react";
import { toast } from "react-toastify";

interface Recipient {
  _id: string;
  first_name: string;
  last_name: string;
  mobile_number: string;
  account_number: string;
  is_upi: boolean;
  upi_id: string;
}

interface MappedRecipient {
  rowId: any;
  recipientID: string;
  name: string;
  mobileNumber: string;
  paymentMethods: string;
  paymentThrough: string;
}

interface RecipientInfo {
  id: any;
  name: string;
  paymentMethod: string;
}

export function SortedDescendingIcon() {
  const { theme } = useTheme();
  return <ExpandMoreIcon sx={{ color: theme.palette.info.main }} />;
}

export function SortedAscendingIcon() {
  const { theme } = useTheme();
  return <ExpandLessIcon sx={{ color: theme.palette.info.main }} />;
}

const RecipientsTable: React.FC = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [recipientData, setRecipientData] = useState<MappedRecipient[]>([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = React.useState(1);
  const dispatch = useDispatch();
  const t = T();
  const isMedium = useMediaQuery(t.breakpoints.down("md"));
  const { recipientName, paymentMethodId, recipientId } = useSelector(
    (state: RootState) => state.chooseRecipient
  );
  const [anchorEl, setAnchorEl] = useState<{
    [key: string]: HTMLElement | null;
  }>({});
  const [
    selectedRecipient,
    setSelectedRecipient,
  ] = useState<MappedRecipient | null>(null);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: MappedRecipient
  ) => {
    setAnchorEl({ ...anchorEl, [row.rowId]: event.currentTarget });
  };

  const handleCloseMenu = (rowId: string) => {
    setAnchorEl((prevAnchorEl) => ({
      ...prevAnchorEl,
      [rowId]: null,
    }));
  };

  const handleSecureTransfer = (row: MappedRecipient) => {
    navigate("/PaymentMethods");
    handleCloseMenu(row.rowId);
    setSelectedRecipient(row);
    const userName = row?.name;
    const paymentMethod = row?.paymentThrough;
    const recipientID = row?.recipientID;
    const recipientInfo: RecipientInfo = {
      id: recipientID,
      name: userName,
      paymentMethod: paymentMethod,
    };

    console.log(recipientInfo);

    if (
      userName === recipientName &&
      paymentMethod === paymentMethodId &&
      recipientID === recipientId
    ) {
      dispatch(deslectCard());
    } else {
      dispatch(
        selectCard({
          recipientName: userName,
          paymentMethodId: paymentMethod,
          recipientId: recipientID,
        })
      );
    }
  };

  const handleDelete = async (row: MappedRecipient) => {
    handleCloseMenu(row.rowId);
    console.log(row);
    // Handle delete action
    try {
      const response = await deleteRecipient(row.recipientID);
      console.log(response);
      console.log("recipient successfully removed");
      toast.success("Recipient Deleted");
    } catch (err) {
      console.error(err);
    }

    getAllRecipients(page);
  };

  const isXs = useMediaQuery(t.breakpoints.only("xs"));
  const isSm = useMediaQuery(t.breakpoints.only("sm"));
  const isMd = useMediaQuery(t.breakpoints.only("md"));
  const isLg = useMediaQuery(t.breakpoints.only("lg"));
  const isXl = useMediaQuery(t.breakpoints.only("xl"));

  // const getColumnWidth = () => {
  //   if (isXs) return 200;
  //   if (isSm) return undefined;
  //   if (isMd) return undefined;
  //   if (isLg) return 200;
  //   if (isXl) return undefined;
  //   return undefined;
  // };

  // const getColumnFlex =() =>{
  //   if (isXs) return undefined;
  //   if (isSm) return 1;
  //   if (isMd) return 1;
  //   if (isLg) return undefined;
  //   if (isXl) return 1;
  //   return undefined;
  // }

  // get width based on breakpoints and columns
  const getColumnWidth = (column: string) => {
    switch (column) {
      case "name":
        if (isXs) return 200;
        if (isSm) return undefined;
        if (isMd) return undefined;
        if (isLg) return 200;
        if (isXl) return undefined;
        return undefined;

      case "number":
        if (isXs) return 200;
        if (isSm) return undefined;
        if (isMd) return undefined;
        if (isLg) return 200;
        if (isXl) return undefined;
        return undefined;

      case "payment":
        if (isXs) return 200;
        if (isSm) return undefined;
        if (isMd) return undefined;
        if (isLg) return 200;
        if (isXl) return undefined;
        return undefined;

      case "actions":
        if (isXs) return 100;
        if (isSm) return undefined;
        if (isMd) return undefined;
        if (isLg) return 100;
        if (isXl) return undefined;
        return undefined;

      default:
        return undefined; 
    }
  };

   // get flex based on breakpoints and columns
  const getColumnFlex = (column: string) => {
    switch (column) {
      case "name":
        if (isXs) return undefined;
        if (isSm) return 1;
        if (isMd) return 1;
        if (isLg) return undefined;
        if (isXl) return 1;
        return undefined;

      case "number":
        if (isXs) return undefined;
        if (isSm) return 1;
        if (isMd) return 1;
        if (isLg) return undefined;
        if (isXl) return 1;
        return undefined;


      case "payment":
        if (isXs) return undefined;
        if (isSm) return 1;
        if (isMd) return 1;
        if (isLg) return undefined;
        if (isXl) return 1;
        return undefined;


      case "actions":
        if (isXs) return undefined;
        if (isSm) return 0.5;
        if (isMd) return 0.5;
        if (isLg) return undefined;
        if (isXl) return 0.5;
        return undefined;


      default:
        return undefined; 
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Recipient Name",
      width: getColumnWidth("name"),
      flex: getColumnFlex("name"),
      renderCell: (params: GridRenderCellParams) => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              width: 35,
              height: 35,
              bgcolor: "rgba(109, 30, 212, 0.3)",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            {params.value[0]}
          </Avatar>
          <label style={{ color: theme.palette.text.primary }}>
            {params.value}
          </label>
        </Grid>
      ),
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      width: getColumnWidth("number"),
      flex: getColumnFlex("number"),
      renderCell: (params: GridRenderCellParams) => (
        <label style={{ color: theme.palette.text.primary }}>
          {params.value}
        </label>
      ),
    },
    {
      field: "paymentMethods",
      headerName: "Payment Methods",
      width: getColumnWidth("payment"),
      flex: getColumnFlex("payment"),
      renderCell: (params: GridRenderCellParams) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            outline: "none",
          }}
        >
          {params.value.includes("@") ? (
            <QrCode
              style={{
                color: theme.palette.primary.main,
                outline: "none",
                height: 23,
                width: 23,
              }}
            />
          ) : (
            <CreditCard
              style={{
                color: theme.palette.primary.main,
                outline: "none",
                height: 23,
                width: 23,
              }}
            />
          )}
          <Tooltip
            title={params.value}
            placement="top"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: theme.palette.primary.dark,
                  color: theme.palette.text.primary,
                  fontSize: "12px",
                  padding: "10px",
                  margin: "0px",
                  "& .MuiTooltip-arrow": {
                    color: theme.palette.primary.dark,
                  },
                },
              },
            }}
          >
            <label
              style={{
                color: theme.palette.text.primary,
                outline: "none",
                textOverflow: "ellipsis",
                overflow: "hidden", // Ensures that the overflow text is hidden
                whiteSpace: "nowrap",
                maxWidth: "100%",
                display: "block",
              }}
            >
              {params.value}
            </label>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: getColumnWidth("actions"),
      flex: getColumnFlex("actions"),
      renderCell: (params: GridRenderCellParams) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            aria-label="actions"
            aria-controls={`menu-${params.row.rowId}`}
            aria-haspopup="true"
            onClick={(event) => handleMenuOpen(event, params.row)}
            sx={{
              color: theme.palette.secondary.main,
              marginRight: theme.spacing(2),
            }}
          >
            <MoreVertIcon
              style={{
                color: theme.palette.primary.main,
                cursor: "pointer",
              }}
            />
          </IconButton>
          <Menu
            id={`menu-${params.row.rowId}`}
            anchorEl={anchorEl[params.row.rowId]}
            open={Boolean(anchorEl[params.row.rowId])}
            onClose={() => handleCloseMenu(params.row.rowId)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: theme.palette.info.light,
                color: theme.palette.text.primary,
                borderRadius: theme.shape.borderRadius,
                width: "180px",
              },
            }}
          >
            <MenuItem onClick={() => handleSecureTransfer(params.row)}>
              Secure Transfer
            </MenuItem>
            <MenuItem onClick={() => handleDelete(params.row)}>Delete</MenuItem>
          </Menu>
        </Box>
      ),
    },
  ];

  const maskAccountNumber = (accNumber: string): string => {
    const visibleDigits = accNumber.slice(-4);
    const maskedDigits = "X".repeat(accNumber.length - 4);
    const formattedMaskedDigits = maskedDigits.replace(/(.{4})/g, "$1 ");
    return formattedMaskedDigits + visibleDigits;
  };

  const maskUpiNumber = (upiNumber: string): string => {
    // Mask UPI number as needed
    // For example, replacing all but the last four characters with 'X'
    const visibleDigits = upiNumber.slice(-6);
    const maskedPart = upiNumber.slice(0, -6).replace(/./g, "X");
    return maskedPart + visibleDigits;
  };

  const getAllRecipients = async (page: number) => {
    setLoading(true);
    try {
      const response = await getTotalRecipients(page);
      setCount(response.count);
      const mappedData: MappedRecipient[] = response.data.map(
        (recipient: Recipient, index: number) => {
          let paymentMethods: string;
          if (recipient.is_upi) {
            paymentMethods = maskUpiNumber(recipient.upi_id);
          } else {
            paymentMethods = maskAccountNumber(recipient.account_number);
          }
          return {
            rowId: index,
            recipientID: recipient._id,
            name: recipient.first_name + " " + recipient.last_name,
            mobileNumber: recipient.mobile_number,
            paymentMethods: paymentMethods,
            paymentThrough: recipient.is_upi
              ? recipient.upi_id
              : recipient.account_number,
          };
        }
      );

      setRecipientData(mappedData);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllRecipients(page);
  }, [page]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Grid>
      {loading ? (
        <Grid
          width={"100%"}
          height={"100%"}
          lg={12}
          sm={12}
          xs={12}
          xl={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ProgressLoader strokeColor="#6D1ED4" fillColor="#6D1ED4" />
        </Grid>
      ) : (
        <DataGrid
          rows={recipientData}
          columns={columns}
          autoHeight
          getRowId={(row) => row.rowId}
          // autoPageSize
          disableColumnMenu // disable column menu filter options
          disableColumnResize // disable column resizing
          // pagination
          hideFooterPagination={true}
          disableColumnSelector={true}
          hideFooter={true}
          disableRowSelectionOnClick // disable row selection on click
          slots={{
            columnSortedDescendingIcon: SortedDescendingIcon,
            columnSortedAscendingIcon: SortedAscendingIcon,
          }}
          sx={{
            "& .MuiDataGrid-columnsContainer": {
              backgroundColor: "none",
              borderColor: "none",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-row": {
              border: "none", // Remove borders for rows
              flex: "1",
              // bgcolor: theme.palette.info.light
            },
            "& .MuiDataGrid-cell": {
              border: "none",
              bgcolor: theme.palette.info.light, // Adjust cell padding as needed
              flex: "1",
            },
            "& .MuiDataGrid-columnHeader": {
              border: "none", // Remove border from column headers
              bgcolor: theme.palette.info.light,
              fontFamily: "Inter",
              color: theme.palette.info.main,
              fontWeight: 500,
              fontSize: "15px",
              lineHeight: "24px",
              flex: "1",
            },
            "& .MuiDataGrid-iconButtonContainer": {
              visibility: "visible",
              outline: "none",
              border: "none",
            },
            "& .MuiDataGrid-sortIcon": {
              opacity: "inherit !important",
              color: theme.palette.info.main,
              outline: "none",
            },
            "&, [class^=MuiDataGrid]": { border: "none" },
            border: "none",
            fontFamily: "Inter",
            color: theme.palette.info.main,
            fontWeight: 500,
            bgcolor: theme.palette.info.light,
            fontSize: "15px",
            lineHeight: "24px",
            flex: "1",
          }}
        />
      )}
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Pagination
          count={Math.ceil(count / 10)}
          page={page}
          onChange={handleChange}
          sx={{
            "& .MuiPaginationItem-root": {
              color: theme.palette.text.primary,
              "&.Mui-selected": {
                bgcolor: theme.palette.primary.main,
                color: "#FFFFFF",
              },
            },
          }}
        />
      </Box>
    </Grid>
  );
};

export default RecipientsTable;
