import React, { useEffect } from "react";
import NavBar from "../components/HeaderBar"; // Import your NavBar component
import { Grid, useMediaQuery, useTheme as T } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import ReferralLink from "../components/ReferralLink";
import RewardsTotal from "../components/RewardsTotal";
import RewardsBarChart from "../components/RewardsBarChart";
import RewardsPieChart from "../components/RewardsPieChart";
import LeaderBoard from "../components/LeaderBoard";

const RewardsPage: React.FC = () => {
  const { theme } = useTheme();
  const t = T();
  const isMediumScreen = useMediaQuery(t.breakpoints.down(1200))
  const isSmallScreen  =useMediaQuery(t.breakpoints.down(1200))

  useEffect(()=>{
    document.title='AptRemit-Rewards'
  })

  return (
    <Grid
      lg={12}
      sx={{ backgroundColor: theme.palette.primary.dark, height: "100vh" }}
    >
      <NavBar highlightDashboard={true} /> {/* Render NavBar component */}
      <Grid sx={{ backgroundColor: theme.palette.primary.dark }}>
        <label
          style={{
            color: theme.palette.primary.main,
            fontSize: "34px",
            fontWeight: 700,
            lineHeight: "42px",
            padding: "30px",
          }}
        >
          Rewards Page
        </label>
        
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          xl={12}
          padding={"30px"}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Grid lg={8} md={12} sm={12} xl={8} xs={12}>
            <Grid lg={12} md={12} xl={12} sm={12} xs={12}>
              <ReferralLink />
            </Grid>
            <Grid lg={12} md={12} xl={12} sm={12} xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} flexWrap={'wrap'}>
             <Grid lg={3} md={12} xl={3} sm={12} xs={12}>
             <RewardsTotal />
             </Grid>
             <Grid lg={8.5} md={12} xl={8.5} sm={12} xs={12}>
              <LeaderBoard/> 
             </Grid>
            </Grid>
          </Grid>

          <Grid lg={3.8} xl={3.8} sm={12} xs={12} md={12}sx={{display:'flex', flexDirection:'row', flexWrap:'wrap',justifyContent:isMediumScreen?'space-between':null, marginTop:isMediumScreen?'20px':null}}>
            <Grid sm={5.8} xs={12} xl={12} lg={12} md={5.5} sx={{ marginTop:isSmallScreen?'20px':null}}> 
              <RewardsBarChart />
            </Grid>
            <Grid sm={5.8} xs={12} xl={12} lg={12} md={5.5} sx={{ marginTop:isSmallScreen?'20px':null}}>
              <RewardsPieChart/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RewardsPage;
