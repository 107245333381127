const url = process.env.REACT_APP_API_URL;

export const getNotifications = async () => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(
      `${url}/notifications-api/api/notifications/?skip=0&limit=7&is_read=false&is_active=true&is_deleted=false`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("could not fetch notifications", error);
  }
};

export const deleteNotification = async (notificationId) => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(
      `${url}/notifications-api/api/notifications/${notificationId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("could not delete notification", error);
  }
};

export const getNotificationsCount = async () => {
  try {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await fetch(
      `${url}/notifications-api/api/notifications/?is_read=false&is_active=true&is_deleted=false`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data.length;
  } catch (error) {
    console.error("could not delete notification", error);
  }
};
