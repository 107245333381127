import React from "react";

import { Box, Grid } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import AuthRemitLogo from "../assets/icons/AuthRemitLogo";

const AuthBackground: React.FC = () => {
  const { theme } = useTheme();

  return (
    <Box
      style={{
        backgroundImage: `url(${"https://res.cloudinary.com/dtobhrhpi/image/upload/v1710838681/milad-fakurian-nY14Fs8pxT8-unsplash_r7ralf.jpg"})`,
        backgroundSize: "cover",
        height: "100vh",
        color: "#f5f5f5",
        borderTopLeftRadius: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        lg={8}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid>
          <AuthRemitLogo width={'100px'}/>
        </Grid>
        <label
          style={{
            fontSize: "24px",
            fontWeight: 500,
            lineHeight: "56px",
            color: theme.palette.text.secondary,
            padding: "5px",
          }}
        >
          Remittance made easy
        </label>
      </Grid>
      <Grid marginBottom={"35px"} marginTop={"20px"}>
        <label
          style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
            color: theme.palette.text.secondary,
          }}
        >
          Learn more about us at{" "}
          <span
            style={{
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "24px",
              color: theme.palette.primary.main,
              borderRadius: "20px",
              backgroundColor: theme.palette.text.secondary,
              paddingTop: "8px",
              paddingBottom: "8px",
              paddingLeft: "5px",
              paddingRight: "5px",
              cursor: "pointer",
            }}
            onClick={() => window.open("https://aptremit.io/", "_blank")}
          >
            aptremit.com
          </span>
        </label>
      </Grid>
    </Box>
  );
};

export default AuthBackground;
