import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { useTheme } from '../assets/themeContext';
import {useTheme as T, useMediaQuery} from '@mui/material'

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const months = ["Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"];

export default function TotalLineChart() {
    const {theme}  =useTheme()
    const t=T();
    const isLarge  = useMediaQuery(t.breakpoints.down('lg'))
  return (
    <LineChart
      width={isLarge?550:550}
      height={isLarge?250:310}
      series={[
        { data: pData, label: 'Transfers', color:'#6D1ED4', showMark: true,},
        { data: uData, label: 'Rewards', color:'#4318FF', showMark: true,},
      ]}
      xAxis={[{ scaleType: 'point', data: months }]}
      leftAxis={null}
      slotProps={{ legend: { hidden: true } }}
      axisHighlight={{
        x: 'line', 
        y: 'none', 
      }}
      sx={{
        // bottomAxis Line Styles
        "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
            strokeWidth: 0,
          },
          "& .MuiChartsAxis-tick": {
            strokeWidth: 0,
          },
          "& .MuiChartsAxis-tickLabel tspan": {
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "20px",
            color: theme.palette.info.main,
            fill: theme.palette.info.main,
          },
          "& .recharts-line .recharts-line-0, & .recharts-line .recharts-line-1": {
            strokeWidth: "3px", // Increase width by 2px
          }
      }}
    />
  );
}