import { createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#6D1ED4",
      light: "#D6B6FF",
      dark: "#0b1437",
    },
    secondary: {
      main: "rgb(99 108 115)",
    },
    error: {
      main: "#d32f2f",
    },
    info: {
      main: "#F4F7FE",
      light: "#111C44",
      dark: "rgba(156, 174, 184, 0.1)",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#1F1F1F",
    },
    background: {
      default: "#111C44",
      paper: "#FFFFFF",
    },
    divider: "#FFFFFF",
    action: {
      selected: "4px 9px 7px 7px rgba(13, 12, 13, 1)",
      active: "#FFFFFF",
      hover:'0px 18px 40px 0px rgba(11, 20, 55, 1)',
      disabled:'0px 18px 40px 0px rgba(11, 20, 55, 1)',
    },
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
});

export default darkTheme;

// interface CustomBreakpoints {
// xs: number;
// sm: number;
// md: number;
// lg: number;
// xl: number;
// }

// const darkTheme = createTheme({
// breakpoints: {
// values: {
// xs: 0,
// sm: 600,
// md: 900,
// lg: 1200,
// xl: 1536,
// } as CustomBreakpoints
// }}
