import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Avatar } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTheme } from "../assets/themeContext";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LandmarkIcon from "../assets/icons/LandmarkIcon";
import ProfileSetting from "../assets/icons/ProfileSettings";
import { BadgeCheck } from "lucide-react";
import { UserRound } from "lucide-react";

const Settings = () => {
  const { theme } = useTheme();
  const navigate = useNavigate()
  const [isMapMinimized, setIsMapMinimized] = useState(false);

  const toggleMapVisibility = () => {
    setIsMapMinimized(!isMapMinimized);
  };

  return (
    <Grid
      container
      sx={{
        padding: "20px",
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
        width: "100%",
      }}
      item
      lg={12}
      md={12}
      xs={12}
      sm={12}
      xl={12}
    >
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid>
          <label
            style={{
              fontSize: "24px",
              fontWeight: 700,
              color: theme.palette.primary.main,
            }}
          >
            Settings
          </label>
        </Grid>
        <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
          <KeyboardArrowUpIcon
            fontSize="medium"
            cursor="pointer"
            onClick={toggleMapVisibility}
            sx={{ color: theme.palette.primary.main }}
          />
        </Grid>
      </Grid>
      <Grid item paddingY={"10px"}>
        <label
          style={{
            flex: 1,
            fontSize: "16px",
            fontWeight: 400,
            color: theme.palette.info.main,
          }}
        >
          manage your profile and change your preferences here
        </label>
      </Grid>

      {!isMapMinimized && (
        <Grid lg={12} md={12} xs={12} xl={12} sm={12}>
          <Grid paddingTop={"10px"} item lg={12} sm={12} xs={12} xl={12} md={12} onClick={()=>navigate('/user')}>
            <Grid sx={{ paddingY: "0px" }}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent="space-between"
                sx={{
                  padding: "15px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  bgcolor: theme.palette.info.light,
                  transition: "box-shadow 0.3s ease",
                  boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                // onClick={() => handleRowClick(row)}
              >
                <Box display="flex" alignItems="center" flexDirection={'row'} gap={'15px'}>
                  <Avatar
                    sx={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "rgba(109, 30, 212, 0.4)",
                      alignItems: "center",
                      color: theme.palette.action.active,
                      cursor: "pointer",
                    }}
                  >
                    <ProfileSetting/>
                  </Avatar>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      paddingLeft: "4px",
                    }}
                  >
                    <Grid>
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          lineHeight:'16px',
                          color: theme.palette.primary.main,
                          cursor: "pointer",
                        }}
                      >
                        Profile Settings
                      </label>
                    </Grid>
                    <Grid>
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight:'20px',
                          color: theme.palette.info.main,
                          cursor: "pointer",
                        }}
                      >
                        Edit or Update your personal information here
                      </label>
                    </Grid>
                  </Grid>
                </Box>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: theme.palette.info.main,
                  }}
                >
                  <ArrowForwardIosIcon fontSize="small" />
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid paddingTop={'10px'} item lg={12} sm={12} xs={12} onClick={()=>navigate('/recipients')}>
            <Grid sx={{ paddingY: "0px" }}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent="space-between"
                sx={{
                  padding: "15px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  bgcolor: theme.palette.info.light,
                  transition: "box-shadow 0.3s ease",
                  boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                // onClick={() => handleRowClick(row)}
              >
                <Box display="flex" alignItems="center" flexDirection={'row'} gap={'15px'}>
                  <Avatar
                     sx={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "rgba(109, 30, 212, 0.4)",
                      alignItems: "center",
                      color: theme.palette.action.active,
                      cursor: "pointer",
                    }}
                  >
                    <UserRound style={{height:20, width:20 }}/>
                  </Avatar>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      paddingLeft: "4px",
                    }}
                  >
                    <Grid>
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          lineHeight:'16px',
                          color: theme.palette.primary.main,
                          cursor: "pointer",
                        }}
                      >
                        Recipients
                      </label>
                    </Grid>
                    <Grid>
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight:'20px',
                          color: theme.palette.info.main,
                          cursor: "pointer",
                        }}
                      >
                       Add or manage recipients
                      </label>
                    </Grid>
                  </Grid>
                </Box>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: theme.palette.info.main,
                  }}
                >
                  <ArrowForwardIosIcon fontSize="small" />
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid paddingTop={'10px'} item lg={12} sm={12} xs={12} onClick={()=>navigate('/PaymentMethods')}>
            <Grid sx={{ paddingY: "0px" }}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent="space-between"
                sx={{
                  padding: "15px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  bgcolor: theme.palette.info.light,
                  transition: "box-shadow 0.3s ease",
                  boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                // onClick={() => handleRowClick(row)}
              >
                <Box display="flex" alignItems="center" flexDirection={'row'} gap={'15px'}>
                  <Avatar
                     sx={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "rgba(109, 30, 212, 0.4)",
                      alignItems: "center",
                      color: theme.palette.action.active,
                      cursor: "pointer",
                    }}
                  >
                    <LandmarkIcon/>
                  </Avatar>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      paddingLeft: "4px",
                    }}
                  >
                    <Grid>
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          lineHeight:'16px',
                          color: theme.palette.primary.main,
                          cursor: "pointer",
                        }}
                      >
                        Payment Methods
                      </label>
                    </Grid>
                    <Grid>
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight:'20px',
                          color: theme.palette.info.main,
                          cursor: "pointer",
                        }}
                      >
                      Add or manage payment methods
                      </label>
                    </Grid>
                  </Grid>
                </Box>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: theme.palette.info.main,
                  }}
                >
                  <ArrowForwardIosIcon fontSize="small" />
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid paddingTop={'10px'} item lg={12} sm={12} xs={12}>
            <Grid sx={{ paddingY: "0px" }}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent="space-between"
                sx={{
                  padding: "15px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  bgcolor: theme.palette.info.light,
                  transition: "box-shadow 0.3s ease",
                  boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                // onClick={() => handleRowClick(row)}
              >
                <Box display="flex" alignItems="center" flexDirection={'row'} gap={'15px'}  onClick={()=>navigate('/kyc')}>
                  <Avatar
                     sx={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "rgba(109, 30, 212, 0.4)",
                      alignItems: "center",
                      color: theme.palette.action.active,
                      cursor: "pointer",
                    }}
                  >
                    <BadgeCheck style={{height:20, width:20}} />
                  </Avatar>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      paddingLeft: "4px",
                    }}
                  >
                    <Grid>
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          lineHeight:'16px',
                          color: theme.palette.primary.main,
                          cursor: "pointer",
                        }}
                      >
                       KYC Verification
                      </label>
                    </Grid>
                    <Grid>
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight:'20px',
                          color: theme.palette.info.main,
                          cursor: "pointer",
                        }}
                      >
                        Complete KYC Validation to verify your account
                      </label>
                    </Grid>
                  </Grid>
                </Box>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: theme.palette.info.main,
                  }}
                  onClick={()=>navigate('/kyc')}
                >
                  <ArrowForwardIosIcon fontSize="small" />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Settings;
