import React, { useState, useEffect } from "react";
import {
  Grid,
  useMediaQuery,
  useTheme as T,
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Avatar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import RightArrowIcon from "../assets/icons/RightArrowIcon";
import RightArrowSelected from "../assets/icons/RightArrowSelected";
import { UserRoundCog, RectangleEllipsis, Trash2,ChevronUp,ChevronDown } from "lucide-react";
import { useTheme } from "../assets/themeContext";
import NavBar from "./HeaderBar";
import UpdatePersonal from "./updatePersonal";
import UpdatePassword from "./updatePassword";
import DeleteUser from "./deleteUser";

const ProfileSettings: React.FC = () => {
  const { theme } = useTheme();
  const t = T();
  const [activeComponent, setActiveComponent] = useState<
    "personalDetails" | "password" | "delete" | null
  >("personalDetails");
  const [activeAccordian, setActiveAccordian] = useState<
    "personalDetails" | "password" | "delete" | null
  >("personalDetails");
  const isLarge = useMediaQuery(t.breakpoints.down("lg"));
  const isSmallScreen = useMediaQuery(t.breakpoints.down("sm"));
  useEffect(() => {
    document.title = "AptRemit-User";
  });

  return (
    <Grid
      lg={12}
      md={12}
      xs={12}
      xl={12}
      sm={12}
      sx={{ backgroundColor: theme.palette.primary.dark, height: "100vh" }}
    >
      <NavBar highlightDashboard={true} />
      <Grid
        lg={12}
        md={12}
        sm={12}
        xs={12}
        xl={12}
        height={"80%"}
        padding={isLarge ? t.spacing(2) : t.spacing(3)}
      >
        <label
          style={{
            color: theme.palette.primary.main,
            fontSize: "25px",
            fontWeight: 700,
            lineHeight: "42px",
          }}
        >
          Profile Settings
        </label>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          xl={12}
          container
          height={"100%"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLarge ? (
            <Grid md={12} xs={12} sm={12} width={"100%"} marginTop={'30px'}>
              <Accordion
                expanded={activeAccordian === "personalDetails"}
                onChange={() => setActiveAccordian("personalDetails")}
                style={{
                  marginTop: "20px",
                  marginBottom:'0',
                  boxShadow: "rgba(0, 0, 0, 0.15)",
                  border: "none",
                  backgroundColor: "#FFF",
                  borderRadius: "16px",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    activeComponent === "personalDetails" ? (
                      <ChevronDown style={{color:theme.palette.primary.main}}/>
                    ) : (
                      <ChevronUp style={{color:theme.palette.primary.main}}/>
                    )
                  }
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    backgroundColor: theme.palette.info.light,
                    paddingX: "20px",
                    paddingY: "10px",
                    gap: "15px",
                    flex: "1",
                    borderTopRightRadius:'16px',
                    borderTopLeftRadius:'16px'
                  }}
                >
                <Box sx={{display:'flex', flexDirection:'row', gap:'20px', alignItems:'center'}}>
                <Avatar
                    sx={{
                      width: 50,
                      height: 50,
                      bgcolor:
                        activeAccordian === "personalDetails"
                          ? theme.palette.primary.main
                          : "rgba(109, 30, 212, 0.3)",
                    }}
                  >
                    <UserRoundCog />
                  </Avatar>
                 
                  <label
                    style={{
                      fontFamily: "Inter",
                      fontSize: isSmallScreen ? "13px" : "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      textAlign:'center',
                      color:theme.palette.text.primary
                    }}
                  >
                    Update your personal details?
                  </label>
                </Box>
                 

                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: theme.palette.primary.dark,
                    padding: "30px",
                  }}
                >
                  <Box sx={{ paddingY: "3px" }}>
                    <UpdatePersonal />
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={activeAccordian === "password"}
                onChange={() => setActiveAccordian("password")}
                style={{
                  margin: "0",
                  boxShadow: "rgba(0, 0, 0, 0.15)",
                  border: "none",
                  backgroundColor: "#FFF",
                  borderRadius: "2px",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    activeAccordian === "password" ? (
                      <ChevronDown style={{color:theme.palette.primary.main}} />
                    ) : (
                      <ChevronUp style={{color:theme.palette.primary.main}} />
                    )
                  }
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    backgroundColor:  theme.palette.info.light,
                    paddingX: "20px",
                    paddingY: "10px",
                    gap: "15px",
                  }}
                >
                  <Box sx={{display:'flex', flexDirection:'row', gap:'20px', alignItems:'center'}}>
                  <Avatar
                    sx={{
                      width: 50,
                      height: 50,
                      bgcolor:
                        activeAccordian === "password"
                          ? theme.palette.primary.main
                          : "rgba(109, 30, 212, 0.3)",
                    }}
                  >
                    <RectangleEllipsis />
                  </Avatar>
                  <label
                    style={{
                      fontFamily: "Inter",
                      fontSize: isSmallScreen ? "13px" : "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color:theme.palette.text.primary
                    }}
                  >
                    Update your password?
                  </label>
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor:  theme.palette.primary.dark,
                    padding: "30px",
                  }}
                >
                  <Box sx={{ paddingY: "3px" }}>
                    <UpdatePassword />
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={activeAccordian === "delete"}
                onChange={() => setActiveAccordian("delete")}
                style={{
                  margin: "0",
                  boxShadow: "rgba(0, 0, 0, 0.15)",
                  border: "none",
                  backgroundColor: "#FFF",
                  borderRadius: "16px",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    activeAccordian === "delete" ? (
                      <ChevronDown style={{color:theme.palette.primary.main}} />
                    ) : (
                      <ChevronUp style={{color:theme.palette.primary.main}} />
                    )
                  }
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    backgroundColor:theme.palette.info.light,
                    paddingX: "20px",
                    paddingY: "10px",
                    gap: "15px",
                    borderBottomLeftRadius:'16px',
                    borderBottomRightRadius:'16px'
                  }}
                >
                  <Box sx={{display:'flex', flexDirection:'row', gap:'20px', alignItems:'center'}}>
                  <Avatar
                    sx={{
                      width: 50,
                      height: 50,
                      bgcolor:
                        activeAccordian === "delete"
                          ? theme.palette.primary.main
                          : "rgba(109, 30, 212, 0.3)",
                    }}
                  >
                    <Trash2 />
                  </Avatar>
                  <label
                    style={{
                      fontFamily: "Inter",
                      fontSize: isSmallScreen ? "13px" : "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color:theme.palette.text.primary
                    }}
                  >
                    Delete?
                  </label>
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: theme.palette.primary.dark,
                    padding: "30px",
                  }}
                >
                  <Box sx={{ paddingY: "3px" }}>
                    <DeleteUser />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "20px",
                height: "100%",
              }}
            >
              <Grid
                lg={6}
                md={5.5}
                xl={5}
                sx={{ position: "relative", left: isLarge ? "2%" : "5%" }}
              >
                <List sx={{ padding: "0" }}>
                  <ListItemButton
                    selected={activeComponent === "personalDetails"}
                    onClick={() => setActiveComponent("personalDetails")}
                    style={{
                      backgroundColor: theme.palette.info.light,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "0.5px solid #FFFFFF",
                    }}
                    sx={{
                      padding: "15px",
                      boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.15)",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 50,
                          height: 50,
                          bgcolor:
                            activeComponent === "personalDetails"
                              ? theme.palette.primary.main
                              : "rgba(109, 30, 212, 0.3)",
                        }}
                      >
                        <UserRoundCog />
                      </Avatar>
                      <ListItemText
                        primary="Update your personal details?"
                        primaryTypographyProps={{
                          fontFamily: "Inter",
                          fontSize: 18,
                          fontWeight: "medium",
                          letterSpacing: 0,
                          color: theme.palette.text.primary,
                        }}
                      />
                    </Box>
                    <ListItemIcon
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {activeComponent === "personalDetails" ? (
                        <RightArrowSelected />
                      ) : (
                        <RightArrowIcon />
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                  <ListItemButton
                    selected={activeComponent === "password"}
                    onClick={() => setActiveComponent("password")}
                    style={{
                      backgroundColor: theme.palette.info.light,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "0.5px solid #FFFFFF",
                    }}
                    sx={{
                      padding: "15px",
                      boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 50,
                          height: 50,
                          bgcolor:
                            activeComponent === "password"
                              ? theme.palette.primary.main
                              : "rgba(109, 30, 212, 0.3)",
                        }}
                      >
                        <RectangleEllipsis />
                      </Avatar>
                      <ListItemText
                        primary="Update your password?"
                        primaryTypographyProps={{
                          fontFamily: "Inter",
                          fontSize: 18,
                          fontWeight: "medium",
                          letterSpacing: 0,
                          color: theme.palette.text.primary,
                        }}
                      />
                    </Box>
                    <ListItemIcon
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {activeComponent === "password" ? (
                        <RightArrowSelected />
                      ) : (
                        <RightArrowIcon />
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                  <ListItemButton
                    selected={activeComponent === "delete"}
                    onClick={() => setActiveComponent("delete")}
                    style={{
                      backgroundColor: theme.palette.info.light,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "0.5px solid #FFFFFF",
                    }}
                    sx={{
                      padding: "15px",
                      boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.15)",
                      borderBottomRightRadius: "10px",
                      borderBottomLeftRadius: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 50,
                          height: 50,
                          bgcolor:
                            activeComponent === "delete"
                              ? theme.palette.primary.main
                              : "rgba(109, 30, 212, 0.3)",
                        }}
                      >
                        <Trash2 />
                      </Avatar>
                      <ListItemText
                        primary="Delete?"
                        primaryTypographyProps={{
                          fontFamily: "Inter",
                          fontSize: 18,
                          fontWeight: "medium",
                          letterSpacing: 0,
                          color: theme.palette.text.primary,
                        }}
                      />
                    </Box>
                    <ListItemIcon
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {activeComponent === "delete" ? (
                        <RightArrowSelected />
                      ) : (
                        <RightArrowIcon />
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                </List>
              </Grid>

              <Grid
                lg={6}
                md={6}
                xl={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: theme.palette.info.light,
                  borderRadius: "55px",
                  boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.15)",
                  height: "80%",
                  width: "100%",
                }}
              >
                <Grid
                  lg={9}
                  md={10}
                  xl={8}
                  sx={{
                    backgroundColor: theme.palette.info.light,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  height={"100%"}
                  width={"100%"}
                >
                  {activeComponent === "personalDetails" && <UpdatePersonal />}
                  {activeComponent === "password" && <UpdatePassword />}
                  {activeComponent === "delete" && <DeleteUser />}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ProfileSettings;
