import { createSlice , PayloadAction} from "@reduxjs/toolkit";

interface ChooseRecipientState {
  rate: number;
  recipientId: string;
  recipientName: string;
  paymentMethodId: string;
}

interface SelectCardPayload {
  recipientId:string;
  recipientName: string;
  paymentMethodId: string;
}

const initialState: ChooseRecipientState = {
  rate: 0.0,
  recipientId: "",
  recipientName: "",
  paymentMethodId: "",
};

const chooseRecSlice = createSlice({
  name: "chooseRecipient",
  initialState,
  reducers: {
    setRate: (state, action: PayloadAction<number>) => {
      state.rate = action.payload;
    },
    setRecipientName: (state, action: PayloadAction<string>) => {
      state.recipientName = action.payload;
    },
    setRecipientId: (state, action: PayloadAction<string>) => {
      state.recipientId = action.payload;
    },
    setPaymentMethod: (state, action: PayloadAction<string>) => {
      state.paymentMethodId = action.payload;
    },

    selectCard: (state, action: PayloadAction<SelectCardPayload>) => {
      state.recipientId = action.payload.recipientId;
      state.recipientName = action.payload.recipientName;
      state.paymentMethodId = action.payload.paymentMethodId;
    },

    deslectCard: (state) => {
      state.rate = 0.0;
      state.recipientName = "";
      state.paymentMethodId = "";
      state.recipientId = "";
    },
  },
});

export const {
  setRate,
  setRecipientName,
  setPaymentMethod,
  setRecipientId,
  selectCard,
  deslectCard,
} = chooseRecSlice.actions;

export default chooseRecSlice.reducer;
