import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  AppBar,
  Toolbar,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme as T,
  IconButton,
  Divider,
  ListItemIcon,
  Dialog,
  Box,
  DialogContent,
  Badge,
} from "@mui/material";
import HomeIcon from "../assets/icons/HomeIcon";
import CloseIcon from "@mui/icons-material/Close";
import RecipuentsIcon from "../assets/icons/RecipuentsIcon";
import LandmarkIcon from "../assets/icons/LandmarkIcon";
import TransactionIcon from "../assets/icons/TransactionIcon";
import NotificationModal from "./NotificationModal";
import {
  Award,
  CircleUserRound,
  LogOut,
  Menu as MenuIcon,
  ArrowLeftRight,
  Landmark,
  UsersRound,
  Home,
  SunMoon,
  Moon,
  Bell,
} from "lucide-react";
import { Menu, MenuItem } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NotificationIcon from "../assets/icons/NotificationIcon";
import { Settings } from "lucide-react";
import MoonIcon from "../assets/icons/MoonIcon";
import { useTheme } from "../assets/themeContext";
import ThemeContext from "../assets/themeContext";
import MoonLightIcon from "../assets/icons/MoonLightIcon";
import RemitLogo from "../assets/icons/RemitLogo";
import { toast } from "react-toastify";
import { getNotificationsCount } from "../services/Notificationapis";
import { persistor } from "../redux/store";

interface NavBarProps {
  highlightDashboard: boolean; // Active page
}

const NavBar: React.FC<NavBarProps> = () => {
  const { theme } = useTheme();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [count, setCount] = useState();
  const t = T();
  const navigate = useNavigate();
  const isXsScreen = useMediaQuery(t.breakpoints.down("lg"));
  const isSmall = useMediaQuery(t.breakpoints.down("sm"));
  const themeContext = useContext(ThemeContext);

  const isMenuOpen = Boolean(menuAnchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
    setAnchorEl(null);
  };

  const handlelogout = () => {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
    persistor.purge();
    toast.success("Logout SuccesFull!", {
      autoClose: 3000,
    });
    navigate("/login");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const fetchCount = async () => {
    const result = await getNotificationsCount();
    setCount(result);
  };

  useEffect(() => {
    fetchCount();
  });

  if (!themeContext) {
    // Handle the case where ThemeContext is not available
    return null; // or render an appropriate fallback
  }
  const { toggleTheme, isDarkMode } = themeContext;

  return (
    <Grid>
      <AppBar
        position="static"
        sx={{
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          height: "100px",
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            "&.MuiToolbar-root": { paddingRight: "20px", paddingLeft: "0px" },
          }}
        >
          <Grid
            sx={{ flex: "1", cursor: "pointer" }}
            onClick={() => navigate("/home")}
          >
            {isSmall ? (
              <RemitLogo height="200px" width="230px" />
            ) : (
              <RemitLogo height="250px" width="250px" />
            )}
          </Grid>
          <Grid sx={{ paddingTop: "15px" }}>
            {isXsScreen ? (
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid>
                  <IconButton
                    edge="start"
                    sx={{
                      color: theme.palette.secondary.main,
                      marginRight: theme.spacing(2),
                    }}
                    aria-label="menu"
                    onClick={handleMenu}
                  >
                    <MenuIcon
                      style={{
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={menuAnchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={isMenuOpen}
                    onClose={handleClose}
                    sx={{
                      "& .MuiPaper-root": {
                        backgroundColor: theme.palette.info.light,
                        color: theme.palette.text.primary,
                        borderRadius: theme.shape.borderRadius,
                        width: 300,
                      },
                    }}
                  >
                    {/* DashBoard */}
                    <Link
                      to="/home"
                      className="nav-link"
                      style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "30px",
                        width: "100%",
                        textDecoration: "none",
                        color:
                          location.pathname === "/home"
                            ? theme.palette.primary.main
                            : "rgba(156, 174, 184, 1)",
                      }}
                    >
                      <MenuItem
                        onClick={handleClose}
                        sx={{
                          width: "100%",
                          "&:hover": {
                            backgroundColor: theme.palette.info.dark,
                          },
                          backgroundColor:
                            location.pathname === "/home"
                              ? theme.palette.info.dark
                              : theme.palette.info.light,
                          textDecoration: "none",
                        }}
                      >
                        <ListItemIcon>
                          <Home
                            style={{
                              height: "25px",
                              width: "25px",
                              color:
                                location.pathname === "/home"
                                  ? theme.palette.primary.main
                                  : "rgba(156, 174, 184, 1)",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                      </MenuItem>
                    </Link>

                    {/* Recipients */}
                    <Link
                      to="/recipients"
                      className="nav-link"
                      style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "30px",
                        width: "100%",
                        textDecoration: "none",
                        color:
                          location.pathname === "/recipients"
                            ? theme.palette.primary.main
                            : "rgba(156, 174, 184, 1)",
                      }}
                    >
                      <MenuItem
                        onClick={handleClose}
                        sx={{
                          width: "100%",
                          "&:hover": {
                            backgroundColor: theme.palette.info.dark,
                          },
                          backgroundColor:
                            location.pathname === "/recipients"
                              ? "rgba(244, 247, 254, 1)"
                              : theme.palette.info.light,
                          textDecoration: "none",
                        }}
                      >
                        <ListItemIcon>
                          <UsersRound
                            style={{
                              height: "25px",
                              width: "25px",
                              color:
                                location.pathname === "/recipients"
                                  ? theme.palette.primary.main
                                  : "rgba(156, 174, 184, 1)",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Recipients" />
                      </MenuItem>
                    </Link>

                    {/* Payment Methods */}
                    <Link
                      to="/PaymentMethods"
                      className="nav-link"
                      style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "30px",
                        width: "100%",
                        textDecoration: "none",
                        color:
                          location.pathname === "/PaymentMethods"
                            ? theme.palette.primary.main
                            : "rgba(156, 174, 184, 1)",
                      }}
                    >
                      <MenuItem
                        onClick={handleClose}
                        sx={{
                          width: "100%",
                          "&:hover": {
                            backgroundColor: theme.palette.info.dark,
                          },
                          backgroundColor:
                            location.pathname === "/PaymentMethods"
                              ? "rgba(244, 247, 254, 1)"
                              : theme.palette.info.light,
                          textDecoration: "none",
                        }}
                      >
                        <ListItemIcon>
                          <Landmark
                            style={{
                              height: "25px",
                              width: "25px",
                              color:
                                location.pathname === "/Payment Methods"
                                  ? theme.palette.primary.main
                                  : "rgba(156, 174, 184, 1)",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Payment Methods" />
                      </MenuItem>
                    </Link>

                    {/* Transactions */}
                    <Link
                      to="/transactions"
                      className="nav-link"
                      style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "30px",
                        width: "100%",
                        textDecoration: "none",
                        color:
                          location.pathname === "/transactions"
                            ? theme.palette.primary.main
                            : "rgba(156, 174, 184, 1)",
                      }}
                    >
                      <MenuItem
                        onClick={handleClose}
                        sx={{
                          width: "100%",
                          "&:hover": {
                            backgroundColor: theme.palette.info.dark,
                          },
                          backgroundColor:
                            location.pathname === "/transactions"
                              ? "rgba(244, 247, 254, 1)"
                              : theme.palette.info.light,
                          textDecoration: "none",
                        }}
                      >
                        <ListItemIcon>
                          <ArrowLeftRight
                            style={{
                              height: "25px",
                              width: "25px",
                              color:
                                location.pathname === "/transactions"
                                  ? theme.palette.primary.main
                                  : "rgba(156, 174, 184, 1)",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Transactions" />
                      </MenuItem>
                    </Link>

                    {/* Rewards */}
                    <Link
                      to="/rewardsPage"
                      className="nav-link"
                      style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "30px",
                        width: "100%",
                        textDecoration: "none",
                        color:
                          location.pathname === "/rewardsPage"
                            ? theme.palette.primary.main
                            : "rgba(156, 174, 184, 1)",
                      }}
                    >
                      <MenuItem
                        onClick={handleClose}
                        sx={{
                          width: "100%",
                          "&:hover": {
                            backgroundColor: theme.palette.info.dark,
                          },
                          backgroundColor:
                            location.pathname === "/rewardsPage"
                              ? "rgba(244, 247, 254, 1)"
                              : theme.palette.info.light,
                          textDecoration: "none",
                        }}
                      >
                        <ListItemIcon>
                          <Award
                            style={{
                              height: "25px",
                              width: "25px",
                              color:
                                location.pathname === "/rewardsPage"
                                  ? theme.palette.primary.main
                                  : "rgba(156, 174, 184, 1)",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Rewards" />
                      </MenuItem>
                    </Link>

                    <Divider sx={{ borderColor: "rgba(109, 30, 212, 0.3)" }} />

                    {/* Profile */}
                    <Link
                      to="/profile"
                      className="nav-link"
                      style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "30px",
                        width: "100%",
                        textDecoration: "none",
                        color:
                          location.pathname === "/profile"
                            ? theme.palette.primary.main
                            : "rgba(156, 174, 184, 1)",
                      }}
                    >
                      <MenuItem
                        onClick={handleClose}
                        sx={{
                          width: "100%",
                          "&:hover": {
                            backgroundColor: theme.palette.info.dark,
                          },
                          backgroundColor:
                            location.pathname === "/profile"
                              ? "rgba(244, 247, 254, 1)"
                              : theme.palette.info.light,
                          textDecoration: "none",
                        }}
                      >
                        <ListItemIcon>
                          <CircleUserRound
                            style={{
                              height: "25px",
                              width: "25px",
                              color:
                                location.pathname === "/profile"
                                  ? theme.palette.primary.main
                                  : "rgba(156, 174, 184, 1)",
                            }}
                          />
                        </ListItemIcon>
                        Profile
                      </MenuItem>
                    </Link>

                    {/* Notification */}
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOpenModal(true);
                      }}
                      sx={{
                        width: "100%",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: theme.palette.info.dark,
                        },
                        backgroundColor:
                          location.pathname === "/notification"
                            ? "rgba(244, 247, 254, 1)"
                            : theme.palette.info.light,
                        textDecoration: "none",
                      }}
                    >
                      <ListItemIcon>
                        <Badge
                          badgeContent={count}
                          sx={{
                            "& .MuiBadge-badge": {
                              backgroundColor: "#6D1ED4",
                              color: "#FFFFFF",
                              fontSize: "10px",
                            },
                          }}
                        >
                          <Bell
                            style={{
                              height: 25,
                              width: 25,
                              color: "rgba(156, 174, 184, 1)",
                            }}
                            strokeWidth={2}
                          />
                        </Badge>
                      </ListItemIcon>
                      Notification
                    </MenuItem>

                    {/* LogOut */}
                    <MenuItem onClick={handlelogout}>
                      <ListItemIcon>
                        <LogOut
                          style={{
                            height: "25px",
                            width: "25px",
                            color: theme.palette.info.main,
                          }}
                        />
                      </ListItemIcon>{" "}
                      Logout
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid>
                  <button
                    onClick={toggleTheme}
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      paddingTop: "3px",
                      color:
                        location.pathname === "/HomePage"
                          ? "rgba(109, 30, 212, 1)"
                          : "rgba(156, 174, 184, 1)",
                    }}
                  >
                    {isDarkMode ? (
                      <Moon
                        style={{
                          height: "25px",
                          width: "25px",
                        }}
                      />
                    ) : (
                      <SunMoon
                        style={{
                          height: "25px",
                          width: "25px",
                        }}
                      />
                    )}
                  </button>
                </Grid>
              </Grid>
            ) : (
              <Card
                sx={{
                  display: "flex",
                  height: "60px",
                  alignItems: "center",
                  paddingTop: 1,
                  borderRadius: "30px",
                  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
                  backgroundColor: theme.palette.info.light,
                }}
              >
                <CardContent sx={{ paddingTop: "20px" }}>
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      paddingY: "2px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Grid sx={{ paddingX: 0.4, paddingY: 0.4 }}>
                        <Link
                          to="/home"
                          className="nav-link"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            textDecoration: "none",
                            color:
                              location.pathname === "/home"
                                ? theme.palette.primary.main
                                : "rgba(156, 174, 184, 1)",
                          }}
                        >
                          <ListItem
                            button
                            sx={{
                              "&:hover": {
                                backgroundColor: theme.palette.info.dark,
                                borderRadius: "200px", // Change background color on hover
                              },
                              backgroundColor:
                                location.pathname === "/home"
                                  ? "rgba(244, 247, 254, 1)"
                                  : theme.palette.info.light,
                              borderRadius: "200px", // Apply purple color if on the receipts page
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                paddingTop: " 5px",
                                paddingRight: "6.5px",
                              }}
                            >
                              <HomeIcon />
                            </div>
                            <ListItemText
                              primary="Dashboard"
                              sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "16px",
                                  fontWeight: 500,
                                },
                              }}
                            />
                          </ListItem>
                        </Link>
                      </Grid>

                      <Grid sx={{ paddingX: 0.4, paddingY: 0.4 }}>
                        <Link
                          to="/recipients"
                          className="nav-link"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            textDecoration: "none",
                            color:
                              location.pathname === "/recipients"
                                ? "rgba(109, 30, 212, 1)"
                                : "rgba(156, 174, 184, 1)",
                          }}
                        >
                          <ListItem
                            sx={{
                              "&:hover": {
                                backgroundColor: theme.palette.info.dark,
                                borderRadius: "200px", // Change background color on hover
                                box: "0px 4px 10px rgba(255, 0, 0, 0.5)",
                              },
                              backgroundColor:
                                location.pathname === "/recipients"
                                  ? "rgba(244, 247, 254, 1)"
                                  : theme.palette.info.light,
                              borderRadius: "200px", // Apply purple color if on the receipts page
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                paddingTop: " 5px",
                                paddingRight: "6.5px",
                              }}
                            >
                              <RecipuentsIcon />
                            </div>
                            <ListItemText
                              primary="Recipients"
                              sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "16px",
                                  fontWeight: 500,
                                },
                              }}
                            />
                          </ListItem>
                        </Link>
                      </Grid>
                      <Grid sx={{ paddingX: 0.4, paddingY: 0.4 }}>
                        <Link
                          to="/PaymentMethods"
                          className="nav-link"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            textDecoration: "none",
                            color:
                              location.pathname === "/PaymentMethods"
                                ? "rgba(109, 30, 212, 1)"
                                : "rgba(156, 174, 184, 1)",
                          }}
                        >
                          <ListItem
                            button
                            sx={{
                              "&:hover": {
                                backgroundColor: theme.palette.info.dark,
                                borderRadius: "200px", // Change background color on hover
                              },
                              backgroundColor:
                                location.pathname === "/PaymentMethods"
                                  ? "rgba(244, 247, 254, 1)"
                                  : theme.palette.info.light,
                              borderRadius: "200px", // Apply purple color if on the receipts page
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                paddingTop: " 5px",
                                paddingRight: "6.5px",
                              }}
                            >
                              <LandmarkIcon />
                            </div>
                            <ListItemText
                              primary="Payment Methods"
                              sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "16px",
                                  fontWeight: 500,
                                },
                              }}
                            />
                          </ListItem>
                        </Link>
                      </Grid>
                      <Grid sx={{ paddingX: 0.4, paddingY: 0.4 }}>
                        <Link
                          to="/transactions"
                          className="nav-link"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            textDecoration: "none",
                            color:
                              location.pathname === "/transactions"
                                ? "rgba(109, 30, 212, 1)"
                                : "rgba(156, 174, 184, 1)",
                          }}
                        >
                          <ListItem
                            button
                            sx={{
                              "&:hover": {
                                backgroundColor: theme.palette.info.dark,
                                borderRadius: "200px", // Change background color on hover
                              },
                              backgroundColor:
                                location.pathname === "/transactions"
                                  ? "rgba(244, 247, 254, 1)"
                                  : theme.palette.info.light,
                              borderRadius: "200px", // Apply purple color if on the receipts page
                              textDecoration: "none",
                            }}
                          >
                            <div
                              style={{
                                paddingTop: " 5px",
                                paddingRight: "6.5px",
                              }}
                            >
                              <TransactionIcon />
                            </div>
                            <ListItemText
                              primary="Transaction"
                              sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "16px",
                                  fontWeight: 500,
                                },
                              }}
                            />
                          </ListItem>
                        </Link>
                      </Grid>
                      <Grid sx={{ paddingX: 0.4, paddingY: 0.4 }}>
                        <Link
                          to="/rewardsPage"
                          className="nav-link"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            textDecoration: "none",
                            color:
                              location.pathname === "/rewardsPage"
                                ? "rgba(109, 30, 212, 1)"
                                : "rgba(156, 174, 184, 1)",
                          }}
                        >
                          <ListItem
                            sx={{
                              "&:hover": {
                                backgroundColor: theme.palette.info.dark,
                                borderRadius: "200px", // Change background color on hover
                              },
                              backgroundColor:
                                location.pathname === "/rewardsPage"
                                  ? "rgba(244, 247, 254, 1)"
                                  : theme.palette.info.light,
                              borderRadius: "200px", // Apply purple color if on the receipts page
                              textDecoration: "none",
                            }}
                          >
                            {" "}
                            <div
                              style={{
                                paddingTop: " 4px",
                                paddingRight: "6.5px",
                              }}
                            >
                              <Award />
                            </div>
                            <ListItemText
                              primary="Rewards"
                              sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "16px",
                                  fontWeight: 500,
                                },
                              }}
                            />
                          </ListItem>
                        </Link>
                      </Grid>
                    </div>
                    {/*--------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textDecoration: "none",
                          color: "rgba(156, 174, 184, 1)",
                          paddingTop: "3px",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        onClick={() => setOpenModal(true)}
                      >
                        <div
                          style={{
                            cursor: "pointer",
                            margin: "5px",
                          }}
                        >
                          <IconButton>
                            <Badge
                              badgeContent={count}
                              sx={{
                                "& .MuiBadge-badge": {
                                  backgroundColor: "#6D1ED4",
                                  color: "#FFFFFF",
                                },
                              }}
                            >
                              <Bell
                                style={{ color: "rgba(156, 174, 184, 1)" }}
                                size={20}
                              />
                            </Badge>
                          </IconButton>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textDecoration: "none",
                          cursor: "pointer",
                          paddingTop: "3px",
                        }}
                        onClick={handleClick}
                      >
                        <div
                          style={{
                            cursor: "pointer",
                            margin: "5px",
                          }}
                        >
                          <IconButton>
                            <Settings
                              style={{ color: "rgba(156, 174, 184, 1)" }}
                              size={20}
                            />
                          </IconButton>
                        </div>
                      </div>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        MenuListProps={{
                          style: { backgroundColor: theme.palette.info.light },
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Link
                          to="/profile"
                          className="nav-link"
                          style={{
                            display: "flex",
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "30px",
                            justifyContent: "center",
                            textDecoration: "none",
                          }}
                        >
                          <MenuItem onClick={handleClose}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                color: theme.palette.info.main,
                              }}
                            >
                              <CircleUserRound />
                              <div
                                style={{
                                  display: "flex",
                                  marginLeft: "10px",
                                  color: theme.palette.info.main,
                                }}
                              >
                                Profile
                              </div>
                            </div>
                          </MenuItem>
                        </Link>
                        <MenuItem onClick={handlelogout}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              color: theme.palette.info.main,
                            }}
                          >
                            <LogOut />
                            <div
                              style={{
                                display: "flex",
                                marginLeft: "10px",
                                color: theme.palette.info.main,
                              }}
                            >
                              Logout
                            </div>
                          </div>
                        </MenuItem>
                      </Menu>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textDecoration: "none",
                        }}
                      >
                        <button
                          onClick={toggleTheme}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            cursor: "pointer",
                            paddingTop: "3px",
                            margin: "5px",
                            color:
                              location.pathname === "/HomePage"
                                ? "rgba(109, 30, 212, 1)"
                                : "rgba(156, 174, 184, 1)",
                          }}
                        >
                          {isDarkMode ? <MoonLightIcon /> : <MoonIcon />}
                        </button>
                      </div>
                    </div>
                  </List>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth
        fullScreen={isSmall}
        PaperProps={{
          sx: {
            borderRadius: isSmall ? "0px" : "20px",
            position: "absolute",
            top: 0,
            right: 0,
            width: "100%",
            margin: isSmall ? "0" : "",
          },
        }}
      >
        <Box
          sx={{
            bgcolor: theme.palette.primary.dark,
            padding: "15px",
          }}
        >
          <label
            style={{
              color: theme.palette.primary.main,
              fontSize: "19px",
              fontWeight: 500,
            }}
          >
            Notifications
          </label>
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => setOpenModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon style={{ color: theme.palette.text.primary }} />
        </IconButton>
        <DialogContent
          sx={{
            bgcolor: theme.palette.primary.dark,
            "&.MuiDialogContent-root": { padding: "10px" },
          }}
        >
          <NotificationModal />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default NavBar;
