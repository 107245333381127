import React,{useEffect, useState} from "react";
import { Grid, Box, Button, Avatar} from "@mui/material";
import { useTheme } from "../assets/themeContext";
import { useNavigate } from "react-router-dom";
import { getRecentTransaction } from "../services/Transactionapis";
import { format } from "date-fns";
interface RecentTransaction {
  name: string;
  date: string;
  time: string;
  usdAmount: number;
  inrAmount: number;
}

const LastTransactionCard: React.FC = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  // const{paymentTime, setPaymentTime} = useState("24 Feb 2024 3:02 PM")

    const [loading, setLoading] = useState<boolean>(false);
    const [transactions, setTransactions] =  useState<RecentTransaction | null>(null);
    // masked card logic TODO: use in api call
    const card: string = "123456789109";
    const lastFourDigits = card.slice(-4); // Extract last four digits
    const maskedDigits = "X".repeat(card.length - 4); // Mask all digits except the last four
    const formattedMaskedDigits = maskedDigits.replace(/(.{4})/g, "$1 "); // Add space after every four X's
    const result = formattedMaskedDigits + lastFourDigits; // Concatenate masked digits with last four digits
  
    const fetchLastTransaction = async () => {
      setLoading(true);
      try {
        const data = await getRecentTransaction();
        const lastData  =data.data
        const recent: RecentTransaction = {
          name: lastData[0].recipients_id,
          date: format(new Date(lastData[0].transaction_initiation_at), 'dd MMM yyyy'),
          time: format(new Date(lastData[0].transaction_initiation_at), 'hh:mm a'),
          usdAmount: lastData[0].usd_transfer_amount,
          inrAmount: lastData[0].inr_transfer_amount
        };
        setTransactions(recent); // Ensure recent is inside an array
        
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(()=>{
      fetchLastTransaction()
    }, [])
 

  return (
    <Grid
      lg={12}
      md={12}
      sx={{
        paddingX: "20px",
        paddingTop:'20px',
        paddingBottom:'10px',
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
      }}
    >
      {/* First */}
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid>
          <label
            style={{
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: "30px",
              color: theme.palette.primary.main,
            }}
          >
            Last Transaction
          </label>
        </Grid>
        <Grid>
          <label
            style={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              color: theme.palette.info.main,
            }}
          >
          {transactions?.date} {transactions?.time}
          </label>
        </Grid>
      </Grid>
      {/* Second */}
      <Grid
        lg={12}
        md={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "20px",
          marginY: "18px",
        }}
      >
        <Avatar
          sx={{ width: 40, height: 40, bgcolor: "rgba(109, 30, 212, 0.3)" }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "16px",
              color: theme.palette.text.primary,
            }}
          >
            {transactions?.name}
          </label>
          <label
            style={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              color: theme.palette.info.main,
              paddingTop: "5px",
            }}
          >
            {result}
          </label>
        </Box>
      </Grid>
      {/* Third */}
      <Grid sx={{ display: "flex", flexDirection: "column" }}>
        <Grid sx={{ display: "flex", flexDirection: "column" }}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              color: theme.palette.info.main,
              padding: "5px",
            }}
          >
            Transfer Exchange Price
          </label>
          <label
            style={{
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "24px",
              color: theme.palette.text.primary,
              padding: "5px",
            }}
          >
            ₹ 83.75
          </label>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "5px",
          }}
        >
          <Grid sx={{ display: "flex", flexDirection: "column" }}>
            <label
              style={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px",
                color: theme.palette.info.main,
                padding: "5px",
              }}
            >
              Amount Sent
            </label>
            <label
              style={{
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "24px",
                color: theme.palette.text.primary,
                padding: "5px",
              }}
            >
             ${transactions?.usdAmount}
            </label>
          </Grid>
          <Grid sx={{ display: "flex", flexDirection: "column" }}>
            <label
              style={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px",
                color: theme.palette.info.main,
                padding: "5px",
              }}
            >
              They Received
            </label>
            <label
              style={{
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "24px",
                color: theme.palette.text.primary,
                padding: "3px",
              }}
            >
                ₹{transactions?.inrAmount}
            </label>
          </Grid>
        </Grid>
      </Grid>
      {/* Fourth */}
      <Grid sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}} lg={9} marginTop={'10px'}>
        <Grid>
          <Button
            variant="contained"
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: "#FFFFFF",
              lineHeight: "24px",
              textAlign: "center",
              borderRadius: "16px",
              paddingX: " 25px",
              paddingY: "5px",
              marginY: "10px",
              textTransform: "initial",
              bgcolor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            }}
            onClick={()=>navigate('/PaymentMethods')}
          >
            Transfer Again
          </Button>
        </Grid>
        <Grid>
            <label style={{fontSize:'14px', fontWeight:600, lineHeight:'24px', color:theme.palette.primary.main, cursor:'pointer'}}>More Details</label>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default LastTransactionCard
