import React,{useEffect} from "react";
import NavBar from "../components/HeaderBar"; // Import your NavBar component
import { Grid, useTheme as T, useMediaQuery} from "@mui/material";
import { useTheme } from "../assets/themeContext";
import LivePriceCard from "../components/LivePriceCard";
import ReferralCard from "../components/ReferralCard";
import LastTransactionCard from "../components/LastTransactionCard";
import FrequentRecipient from "../components/FrequentReceipients";
import Addrecipients from "../components/Addrecipients";

const Recipients: React.FC = () => {
  const { theme } = useTheme();
  const t = T();
  const isSmall = useMediaQuery(t.breakpoints.down('sm'))
  const isMedium = useMediaQuery(t.breakpoints.down('lg'))

  useEffect(()=>{
    document.title='AptRemit-Recipients'
  })


  return (
    <Grid
      lg={12}
      sx={{ backgroundColor: theme.palette.primary.dark, height: "100vh" }}
    >
      <NavBar highlightDashboard={true} /> {/* Render NavBar component */}
      <Grid
        sx={{ backgroundColor: theme.palette.primary.dark }}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <label
          style={{
            color: theme.palette.primary.main,
            fontSize: "34px",
            fontWeight: 700,
            lineHeight: "42px",
            paddingLeft:'20px'
          }}
        >
          Recipients
        </label>
        {/* Add your dashboard content here */}
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          xl={12}
          sx={{ display: "flex", flexDirection: "row", flexWrap:'wrap', justifyContent:"space-between"}}
          paddingX={'20px'}
          paddingY={'20px'}
        >
            <Grid sx={{ display: "flex", flexDirection: "row", flexWrap:'wrap', gap:'20px', justifyContent:'space-between'}} lg={3.5} md={12} sm={12} xs={12} xl={3}>
              <Grid lg={12} md={5.5} sm={12} xs={12} xl={12}>
                <LivePriceCard />
              </Grid>
              <Grid marginTop={isSmall?'20px':'0px'} lg={12} md={5.5} sm={12} xs={12} xl={12}>
                <LastTransactionCard />
              </Grid>
            </Grid>
            <Grid  lg={4.5} md={12} sm={12} xs={12} xl={5.2} marginY={isMedium?'20px':'0px'}>
              <Addrecipients />
            </Grid>

            <Grid lg={3.5} md={12} sm={12} xs={12} xl={3.3} sx={{display: "flex", flexDirection: "row", flexWrap:'wrap', justifyContent:'space-between'}}> 
          <Grid sm={12} md={5.8} xl={12} xs={12} lg={12} >
          < FrequentRecipient />
          </Grid>
           <Grid sm={12} md={5.6} xl={12} xs={12} lg={12} marginTop={isSmall?'20px':'0px'}>
            <ReferralCard/>
           </Grid>
          </Grid>
          </Grid>
       </Grid>
      </Grid>
  );
};

export default Recipients;
