import React from "react";
import { useTheme } from "../assets/themeContext";

interface SVGProps {
    strokeColor: string;
    fillColor: string;
  }

const ProgressLoader: React.FC<SVGProps> =({ strokeColor, fillColor }) => {
  const { theme }  = useTheme()
  return (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 595.28 478.95"
          enableBackground="new 0 0 595.28 478.95"
          xmlSpace="preserve"
          width="100px" // Adjust width here
          height="100px" // Adjust height here
        >
          <style>
            {`
              .st0, .st1 {
                fill: none;
                stroke-width: 2;
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
              }
              
              .st0 {
                stroke:${theme.palette.text.primary};
                animation: drawFill 2s linear infinite;
              }
              
              .st1 {
                stroke:${strokeColor};
                animation: drawFill2 2s linear infinite;
              }
              
              @keyframes drawFill {
                0%, 100% {
                  stroke-dashoffset: 1000;
                  fill: none;
                }
                50% {
                  stroke-dashoffset: 0;
                  fill: ${theme.palette.text.primary} /* Fill color for .st0 */
                }
              }
              
              @keyframes drawFill2 {
                0%, 100% {
                  stroke-dashoffset: 1000;
                  fill: none;
                }
                50% {
                  stroke-dashoffset: 0;
                  fill: ${fillColor}/* Fill color for .st1 */
                }
              }
            `}
          </style>
          <g>
            <path
              className="st0"
              d="M493.62,154.8c-11.99,10.47-22.96,26.33-36.17,34.83c-9.33,6-23.04-8.71-33.6-11.57
              c-31-8.4-52.79,8.29-72.5,29.72c-4.73-10.21-7.73-20.98-13.17-30.94c-3.19-5.84-16.02-18.98-14.38-24.8
              c1.83-6.49,18.36-14.89,24.12-18.21c9.33-5.38,19.33-9.51,29.77-12.16c43.91-11.16,81.53,4.39,115.68,30.98
              C493.44,153.36,493.53,154.08,493.62,154.8z"
            />
            <g id="XMLID_00000005966872255259233830000003235666134979283356_">
              <g>
                <path
                  className="st0"
                  d="M311.78,157.35c21.86,22.83,31.71,51.63,32.25,82.86c0.7,39.98,2.12,80.82-0.29,120.74
                      c-17.31-1.77-32.38-7.21-43.97-20.86c-12.82-15.09-13.53-31.43-13.39-50.28c0.2-26.53,2.76-57.79-8.9-82.03
                      C286.24,187.76,296.01,168.95,311.78,157.35z"
                />
                <path
                  className="st1"
                  d="M311.78,157.35c-15.77,11.6-25.54,30.41-34.3,50.43c-4.6-9.58-11.43-18.06-21.49-24.72
                      c-27.56-18.24-66.31-12.25-87.1,13.23c-20.92,25.64-19.44,64.33,3.69,88.1c26.54,27.29,79.89,27.03,100.73-7.59
                      c0.63,0.34,1.27,0.68,1.9,1.01c3,16.12-1.12,32.07,4.45,47.95c5.14,14.67,8.56,16.64-7.2,23.82
                      c-21.38,9.75-45.37,13.1-68.58,9.31c-47.69-7.8-87.63-44.75-99.04-91.73c-12.16-50.03,11.49-104.75,55.4-131.23
                      C209.52,106.22,272.28,116.09,311.78,157.35z"
                />
              </g>
            </g>
          </g>
        </svg> 
  )
};

export default ProgressLoader;

// for White : #6D1ED4
// for Dark: #CF9FFF