import React from 'react'

const IndianFlagIcon: React.FC<React.SVGProps<SVGSVGElement>>=()=>{
    return(

<svg width="21px" height="15px" viewBox="0 0 21 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>IN</title>
    <desc>Created with sketchtool.</desc>
    <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stop-color="#FFFFFF" offset="0%"></stop>
            <stop stop-color="#F0F0F0" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-2">
            <stop stop-color="#FFA44A" offset="0%"></stop>
            <stop stop-color="#FF9934" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-3">
            <stop stop-color="#1A9F0B" offset="0%"></stop>
            <stop stop-color="#138806" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="IN">
            <rect id="FlagBackground" fill="url(#linearGradient-1)" x="0" y="0" width="21" height="15"></rect>
            <rect id="Rectangle-2" fill="#FF671F" x="0" y="0" width="21" height="5"></rect>
            <rect id="Rectangle-2" fill="#046A38" x="0" y="10" width="21" height="5"></rect>
            <rect id="Rectangle-2" fill="url(#linearGradient-1)" x="0" y="5" width="21" height="5"></rect>
            <circle id="Oval-1" fill-opacity="0.15" fill="#181A93" cx="10.5" cy="7.5" r="1.5"></circle>
            <path d="M10.5,9.5 C9.3954305,9.5 8.5,8.6045695 8.5,7.5 C8.5,6.3954305 9.3954305,5.5 10.5,5.5 C11.6045695,5.5 12.5,6.3954305 12.5,7.5 C12.5,8.6045695 11.6045695,9.5 10.5,9.5 Z M10.5,9 C11.3284271,9 12,8.32842712 12,7.5 C12,6.67157288 11.3284271,6 10.5,6 C9.67157288,6 9,6.67157288 9,7.5 C9,8.32842712 9.67157288,9 10.5,9 Z" id="Oval-1" fill="#181A93" fill-rule="nonzero"></path>
            <circle id="Oval-8" fill="#181A93" cx="10.5" cy="7.5" r="1"></circle>
        </g>
    </g>
</svg>
    
    )
}
export default IndianFlagIcon;
export{};