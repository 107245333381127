import React, { useState } from "react";
import {
  Grid,
  Button,
  FormLabel,
  TextField,
  FormHelperText,
} from "@mui/material";
import { Eye, EyeOff } from "lucide-react";
import { useTheme } from "../assets/themeContext";

interface Errors {
  current?: string | null;
  new?:string | null;
  confirm?:string | null;
}

const UpdatePassword: React.FC = () => {
  const { theme } = useTheme();
  const [showCurrent, setShowCurrent] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [currentPwd, setCurrentPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [errors, setErrors] = useState<Errors>({});

  const validateCurrent = (text: string): boolean => {
    // if there is no text on blur event
    if (!text) {
      setErrors((prev) => ({ ...prev, current: "Current Password is required" }));
      return false;
    } else {
      if (text.length < 8) {
        // Password length validation
        setErrors((prev) => ({
          ...prev,
          current: "Password must be at least 8 characters long",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, current: null }));
        return true;
      }
    }
  };

  const validateNew = (text: string): boolean => {
    if (!text) {
      setErrors((prev) => ({ ...prev, new: "New Password is required" }));
      return false;
    } else if (text === currentPwd) {
      setErrors((prev) => ({
        ...prev,
        new: "New password must be different from current password",
      }));
      return false;
    }
    setErrors((prev) => ({ ...prev, new: null }));
    return true;
  };

  const validateConfirm = (text: string): boolean => {
    // if there is no text on blur event
    if (!text) {
      setErrors((prev) => ({ ...prev, confirm: "Password is required" }));
      return false;
    } else {
      if (text.length < 8) {
        // Password length validation
        setErrors((prev) => ({
          ...prev,
          confirm: "Password must be at least 8 characters long",
        }));
        return false;
      } else if (text === newPwd) {
        setErrors((prev) => ({
          ...prev,
          new: "Confirm password must be same as new password",
        }));
        return false;
      }else {
        setErrors((prev) => ({ ...prev, confirm: null }));
        return true;
      }
    }
  };

  const shouldDisableSaveButton = () => {
    return currentPwd === newPwd || newPwd !== confirmPwd;
  };

  return (
    <Grid width={"100%"}>
      <form style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:''}}>
        <Grid lg={12} md={12} sm={12} xs={12} xl={12} >
          <FormLabel
            htmlFor="current"
            sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
            }}
          >
           Current Password
            <span
              style={{
                color: theme.palette.primary.main,
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
              }}
            >
              *
            </span>
          </FormLabel>
          <TextField
            fullWidth
            id="current"
            size="small"
            variant="outlined"
            name="current"
            value={currentPwd}
            type={showCurrent ? "text" : "password"}
            placeholder="Min. 8 characters"
            onChange={(e) => {
              setCurrentPwd(e.target.value);
              validateCurrent(e.target.value);
            }}
            onBlur={() => validateCurrent(currentPwd)}
            InputProps={{
              style: {
                borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
              },
              endAdornment: (
                <Button onClick={()=>setShowCurrent(!showCurrent)}>
                  {showCurrent ? (
                    <Eye style={{ color:"#9CAEB8"  }} />
                  ) : (
                    <EyeOff style={{ color: "#9CAEB8"  }} />
                  )}
                </Button>
              ),
            }}
            sx={{
                "& .MuiOutlinedInput-root": {
                  // - The Input-root, inside the TextField-root
                  "& fieldset": {
                    // - The <fieldset> inside the Input-root
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
          />
          <FormHelperText
            sx={{ color: theme.palette.error.main, fontWeight: 520 }}
          >
            {errors.current}
          </FormHelperText>
        </Grid>
        <Grid lg={12} md={12} sm={12} xs={12} xl={12} marginTop={'20px'}>
          <FormLabel
            htmlFor="new"
            sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
            }}
          >
           New Password
            <span
              style={{
                color: theme.palette.primary.main,
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
              }}
            >
              *
            </span>
          </FormLabel>
          <TextField
            fullWidth
            id="new"
            size="small"
            variant="outlined"
            name="new"
            color={currentPwd === newPwd?"secondary":"success"}
            value={newPwd}
            type={showNew ? "text" : "password"}
            placeholder="Min. 8 characters"
            onChange={(e) => {
              setNewPwd(e.target.value);
              validateNew(e.target.value);
            }}
            onBlur={() => validateNew(newPwd)}
            InputProps={{
              style: {
                borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
              },
              endAdornment: (
                <Button onClick={()=>setShowNew(!showNew)}>
                  {showNew ? (
                    <Eye style={{ color: "#9CAEB8" }} />
                  ) : (
                    <EyeOff style={{ color: "#9CAEB8" }} />
                  )}
                </Button>
              ),
            }}
            sx={{
                "& .MuiOutlinedInput-root": {
                  // - The Input-root, inside the TextField-root
                  "& fieldset": {
                    // - The <fieldset> inside the Input-root
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
          />
          <FormHelperText
            sx={{ color: theme.palette.error.main, fontWeight: 520 }}
          >
            {errors.new}
          </FormHelperText>
        </Grid>
        <Grid lg={12} md={12} sm={12} xs={12} xl={12} marginTop={'20px'}>
          <FormLabel
            htmlFor="confirm"
            sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                color: theme.palette.text.primary,
            }}
          >
            Confirm Password
            <span
              style={{
                color: theme.palette.primary.main,
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
              }}
            >
              *
            </span>
          </FormLabel>
          <TextField
            fullWidth
            id="confirm"
            size="small"
            variant="outlined"
            name="confirm"
            value={confirmPwd}
            type={showConfirm ? "text" : "password"}
            placeholder="Min. 8 characters"
            onChange={(e) => {
              setConfirmPwd(e.target.value);
              validateConfirm(e.target.value);
            }}
            onBlur={() => validateConfirm(confirmPwd)}
            InputProps={{
              style: {
                borderRadius: "16px",
                  padding: "3px",
                  marginTop: "5px",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: "1px",
                  color: theme.palette.text.primary,
              },
              endAdornment: (
                <Button onClick={()=>setShowConfirm(!showConfirm)}>
                  {showConfirm ? (
                    <Eye style={{ color: "#9CAEB8" }} />
                  ) : (
                    <EyeOff style={{ color: "#9CAEB8" }} />
                  )}
                </Button>
              ),
            }}
            sx={{
                "& .MuiOutlinedInput-root": {
                  // - The Input-root, inside the TextField-root
                  "& fieldset": {
                    // - The <fieldset> inside the Input-root
                    borderColor: theme.palette.text.primary,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.primary, // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor: theme.palette.text.primary,
                  },
                },
              }}
          />
          <FormHelperText
            sx={{ color: theme.palette.error.main, fontWeight: 520 }}
          >
            {errors.confirm}
          </FormHelperText>
        </Grid>
        <Button
            variant="contained"
            disabled={shouldDisableSaveButton()}
            sx={{
              borderRadius: "16px",
              paddingX: "35px",
              marginTop:'20px',
              paddingY: "15px",
              textTransform:'initial',
              bgcolor: theme.palette.primary.main,
              alignSelf:'flex-end',
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            }}
            //onClick={handleSignUp}
          >
            <label
              style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#FFFFFF",
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              Save
            </label>
          </Button>
      </form>
    </Grid>
  );
};
export default UpdatePassword;
