import { RouteObject } from 'react-router-dom';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// Define the type for the routes array
type RoutesArray = RouteObject[];

// Combine MainRoutes and LoginRoutes into an array of routes
const routes: RoutesArray = [
  ...MainRoutes.children,
  ...LoginRoutes.children
];

export default routes;
