
interface Image {
  src: string;
  alt: string;
}
// const axisLogo: Image = {
//     src: require('../assets/banks/AXISBANK.png'),
//     alt: "Axis Bank Logo", 
// };

const indianFlag:Image={
  src: require('../assets/flags/india-flag.png'),
  alt: "india flag", 
}
const usaFlag:Image={
  src: require('../assets/flags/usa-flag.png'),
  alt: "usa flag", 
}




const images: { [key: string]: Image } = {
// axisLogo,
indianFlag,
usaFlag
};

export default images;
 export{};

