import React from 'react'
import {Grid, Box, Button, useMediaQuery, useTheme as T} from '@mui/material';
import ReferImage from '../assets/icons/ReferImage';
import { useNavigate } from 'react-router-dom';

const UserReferral: React.FC=()=>{
  const navigate = useNavigate();
  const t = T()
  const isSmall = useMediaQuery(t.breakpoints.down('xs'))
    return(
        <Grid container lg={12} sm={12} md={12} xs={12} xl={12} marginTop={isSmall?'20px':'none'}
        sx={{
            background: "linear-gradient(to bottom , #6D5290,#6D1ED4 )",
            borderRadius: "20px",
            paddingRight:'30px',
            paddingLeft:'20px',
            paddingTop:'20px',
            display:'flex', 
            flexDirection:'column',

        }}
        >
            <Grid> 
            <Box>
            <label
             style={{
                fontSize:'25px',
                fontWeight:600,
                lineHeight:'42px',
                color:'#FFFFFF'
             }}
            >
                Refer AptRemit and enjoy
            </label>
            </Box>

            <Box>
            <label
             style={{
                fontSize:'25px',
                fontWeight:600,
                lineHeight:'30px',
                color:'#FFFFFF'
             }}
            >
              exclusive rewards
            </label>
            </Box>

            </Grid>

            <Grid lg={12} md={12} paddingTop={"10px"} sx={{display:'flex', flexDirection:'column'}}>
          <Grid>
          <label
            style={{
              fontSize: "15px",
              fontWeight: 500,
              lineHeight: "28px",
              color: "#E3DAFF",
            }}
          >
            Share us with your contacts and friends. Let them experience
          </label>
          </Grid>
          <Grid>
          <label
            style={{
              fontSize: "15px",
              fontWeight: 500,
              lineHeight: "28px",
              color: "#E3DAFF",
            }}
          >
            seamless cross border payments while you enjoy our rewards.
          </label>
          </Grid>
        </Grid>
            <Grid  display={'flex'} flexDirection={'row'}  width={'100%'}  lg={8} md={8} sm={8} xs={8} xl={8}>
               <Grid>
               <Button 
               variant="contained"
               sx={{
                 fontSize: "14px",
                 fontWeight: 500,
                 color: '#1F1F1F',
                 lineHeight: "25px",
                 textAlign: "center",
                 borderRadius: "16px",
                 paddingX:'28px',
                 width:'140px',
                 paddingY:"12px",
                 marginTop:'25px',
                 alignSelf:'center',
                 textTransform: "initial",
                 bgcolor: '#FFFFFF',
                 "&:hover": {
                   backgroundColor: '#FFFFFF',
                 },
             }}
             onClick={()=>navigate('/RewardsPage')}
               >
               Refer Now
               </Button>
               </Grid>
                <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-end'}}>
                <ReferImage height={250} width={200} viewBox="0 0 200 350"/>
                </div>
            </Grid>
        </Grid>
    );
}
export default UserReferral