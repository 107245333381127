import React from 'react';
import NavBar from '../components/HeaderBar'; // Import your NavBar component
import { Grid } from '@mui/material';
import { useTheme } from '../assets/themeContext';

const ConversionPage: React.FC = () => {
  const { theme } = useTheme();
  return (
    <Grid lg={12} sx={{  backgroundColor: theme.palette.primary.dark, height: '100vh' }} padding={'20px'}>
    {/* <div style={{ backgroundColor: theme.palette.primary.dark }}> */}
      <NavBar  highlightDashboard={true}/> {/* Render NavBar component */}
      <h1 style={{ color: theme.palette.primary.main }}>Transfer</h1>
      {/* Add your dashboard content here */}
    {/* </div> */}
    </Grid>
  );
}

export default ConversionPage;