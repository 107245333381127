import React from 'react';
import { Container,Grid, useMediaQuery,useTheme as T } from '@mui/material';
// import TrustedBar from '../pages/TrustedBar';
// import images from '../utils/Images';
import HeaderBar from '../components/HeaderBar';
// import { useTheme } from '../assets/themeContext';


const MyComponent: React.FC = () => {
  // const theme = useTheme();
  const t= T();
  const isSmallScreen = useMediaQuery(t.breakpoints.down('xs'));

  return (
    <>
    <Grid  item xs={12}>
    {/* <Promotion/> */}
    </Grid>
    <Container maxWidth="lg" sx={{  
      paddingLeft: isSmallScreen ? t.spacing(0) : t.spacing(4), 
      paddingRight: isSmallScreen ? t.spacing(0) : t.spacing(4),
    }}>
      </Container>
     <Grid>
      <HeaderBar  highlightDashboard/>
    </Grid>
    
    </>
  );
};

export default MyComponent;
