import React from 'react';

const AUFlagIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => {
    return (
        <svg width="21px" height="15px" viewBox="0 0 55.2 38.4" xmlns="http://www.w3.org/2000/svg">
            <style type="text/css">
                {`.st0{fill:#00008B;} .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;} .st2{fill:#FFFFFF;} .st3{fill:#FF0000;}`}
            </style>
            <g>
                <path className="st0" d="M52.17,0H3.03C1.36,0,0,1.36,0,3.03v32.33c0,1.67,1.36,3.03,3.03,3.03h49.13c1.67,0,3.03-1.37,3.03-3.03V3.03 C55.2,1.36,53.83,0,52.17,0z"/>
                <polygon className="st1" points="16.07,22.94 17.08,26.1 20.19,24.92 18.35,27.7 21.21,29.39 17.9,29.68 18.36,32.98 16.07,30.56 13.77,32.98 14.23,29.68 10.92,29.39 13.78,27.7 11.94,24.92 15.05,26.1 16.07,22.94"/>
                <polygon className="st1" points="43.16,28.64 43.64,30.14 45.12,29.58 44.25,30.9 45.61,31.71 44.03,31.85 44.25,33.42 43.16,32.27 42.07,33.42 42.28,31.85 40.71,31.71 42.07,30.9 41.19,29.58 42.67,30.14 43.16,28.64"/>
                <polygon className="st1" points="43.16,5.17 43.64,6.67 45.12,6.11 44.25,7.43 45.61,8.24 44.03,8.38 44.25,9.95 43.16,8.8 42.07,9.95 42.28,8.38 40.71,8.24 42.07,7.43 41.19,6.11 42.67,6.67 43.16,5.17"/>
                <polygon className="st1" points="36.25,14.7 36.74,16.21 38.22,15.65 37.34,16.97 38.7,17.78 37.13,17.91 37.34,19.48 36.25,18.33 35.16,19.48 35.38,17.91 33.8,17.78 35.16,16.97 34.29,15.65 35.77,16.21 36.25,14.7"/>
                <polygon className="st1" points="50.98,12.35 51.47,13.86 52.95,13.3 52.07,14.62 53.43,15.43 51.86,15.57 52.07,17.13 50.98,15.99 49.89,17.13 50.11,15.57 48.53,15.43 49.89,14.62 49.02,13.3 50.5,13.86 50.98,12.35"/>
                <polygon className="st1" points="46.68,19.42 47.06,20.36 48.07,20.43 47.3,21.08 47.54,22.07 46.68,21.54 45.82,22.07 46.06,21.08 45.28,20.43 46.3,20.36 46.68,19.42"/>
                <path className="st2" d="M0.23,1.87l27.62,13.81h3.51v-1.75L3.51,0H3.03C1.78,0,0.69,0.77,0.23,1.87z"/>
                <path className="st2" d="M31.36,0v1.75L3.51,15.68H0v-1.75L27.85,0H31.36z"/>
                <path className="st2" d="M13.07,0v15.68h5.23V0H13.07z"/>
                <path className="st2" d="M0,5.23v5.23h31.36V5.23H0z"/>
                <path className="st3" d="M0,6.27v3.14h31.36V6.27H0z"/>
                <path className="st3" d="M14.11,0v15.68h3.14V0H14.11z"/>
                <path className="st3" d="M0,15.68l10.45-5.23h2.34L2.34,15.68H0z"/>
                <path className="st3" d="M0.48,1.41c0.2-0.31,0.45-0.58,0.74-0.8l9.24,4.62H8.12L0.48,1.41z"/>
                <path className="st3" d="M18.57,5.23L29.02,0h2.34L20.9,5.23H18.57z"/>
                <path className="st3" d="M31.36,15.68L20.9,10.45h2.34l8.11,4.06V15.68z"/>
            </g>
        </svg>
    );
};

export default AUFlagIcon;
