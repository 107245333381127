import * as React from "react";
import { Grid} from "@mui/material";
import { useTheme } from "../assets/themeContext";
import ReferralTable from "./ReferralTable";


const LeaderBoard: React.FC = () => {
  const { theme } = useTheme();

  return (
    <Grid
    lg={12}
    sm={12}
    md={12}
    xs={12}
    xl={12}
      sx={{
        paddingTop: "20px",
        paddingX:'20px',
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
        height:'100%',
        marginTop:'20px'
      }}
    >
      <Grid sx={{display:'flex',flexDirection:"row", justifyContent:'space-between', alignItems:"center"}}>
        <label style={{fontSize:'24px',fontWeight:700,lineHeight:'32px',color:theme.palette.text.primary}}>
        Referral Leaderboard
        </label>
      </Grid>
      <Grid marginTop={'20px'} lg={12} md={12} sm={12} xs={12} xl={12}>
        <ReferralTable/>
      </Grid>
    </Grid>
  );
};

export default LeaderBoard;
