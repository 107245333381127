import React from 'react';
import { Grid, AppBar, Toolbar, Typography, Button} from '@mui/material';
import { Link } from 'react-router-dom';
import HomeScreen from '../pages/HomeScreen'

//import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//   title: {
//     flexGrow: 1, 
//   },
// }));

const Navbar: React.FC = () => {
  //const classes = useStyles();

  return (
    <Grid>
    <AppBar position="fixed">
    <Toolbar sx={{ bgcolor: '#fff', boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.5)'}}>
    {/* sx={{ bgcolor: '#fff', color: 'black', boxShadow: '0px 5px 5px -5px rgba(0,0,0,0.5)', }} */}
        <Typography variant="h6" sx={{flexGrow: 6,paddingLeft: '60px',color: 'blue'}}>
           Apt Remit
        </Typography>
         <div>
         <Button   color="inherit" component={Link} to="/"  
          sx={{
               color: 'blue',
               marginRight: '10px'
             }}>
               Login
         </Button>
         </div>
         <div>
         <Button   variant="contained"  component={Link} to="/sendmoney" 
            sx={{
               marginRight: '60px'
             }}>
               Get Started
         </Button>
         </div>
         
         
         
      </Toolbar>
    </AppBar>
    <Grid item sx={{paddingTop: 5}}>
     <HomeScreen />
    </Grid>
    </Grid>
  );
};

export default Navbar;





// import React from 'react';
// import { Grid, AppBar, Toolbar, Typography, Button, makeStyles } from '@mui/material';
// import { Link } from 'react-router-dom';
// import HomeIcon from '@mui/icons-material/Home';
// import Receipt from '@mui/icons-material/Receipt';
// import SettingsIcon from '@mui/icons-material/Settings';
// import HomeScreen from '../pages/HomeScreen'
// import SendMoney from '../pages/SendMoney'
// //import { makeStyles } from '@mui/styles';

// // const useStyles = makeStyles((theme) => ({
// //   title: {
// //     flexGrow: 1, 
// //   },
// // }));

// const Navbar: React.FC = () => {
//   //const classes = useStyles();

//   return (
//     <Grid>
//     <AppBar position="fixed">
//     <Toolbar sx={{ bgcolor: '#fff', color: 'black', boxShadow: '0px 5px 5px -5px rgba(0,0,0,0.5)', }}>
       
//         <Typography variant="h6" sx={{flexGrow: 6,paddingLeft: '60px'}}>
//            Money Transfer
//         </Typography>
        
//          <Button color="inherit" component={Link} to="/"  >
//               <HomeIcon /> Home
//          </Button>
//          <Button color="inherit" component={Link} to="/sendmoney" >
//               <Receipt />Transactions
//          </Button>
//          <Button color="inherit" component={Link} to="/sendmoney" sx={{paddingRight: '60px'}}>
//               <SettingsIcon />Settings
//          </Button>
         
         
//       </Toolbar>
//     </AppBar>
//     <Grid item sx={{paddingTop: 5}}>
//      <HomeScreen />
//     </Grid>
//     </Grid>
//   );
// };

// export default Navbar;










// import React from 'react';
// import { Grid, Typography } from '@mui/material';
// import HomeIcon from '@mui/icons-material/Home';
// import SwapHorizIcon from '@mui/icons-material/AccountBalanceWallet';
// import SettingsIcon from '@mui/icons-material/Settings';
// import { Settings } from '@mui/icons-material';
// import { Link } from 'react-router-dom';
// import HomeScreen from '../pages/HomeScreen'


// interface NavigationProps {
//   activeTab: string;
//   onTabChange: (tab: string) => void;
// }
// //activeTab, onTabChange

// const Navbar: React.FC= (activeTab, onTabChange) => {
//   return (
//     <Grid>
//     <Grid container spacing={3} sx={{ position: 'fixed', bgcolor: '#fff', color: 'black' ,padding: '10px 20px',textAlign:'center', boxShadow: '0px 5px 5px -5px rgba(0,0,0,0.5)'}}>
      
//       <Grid item alignItems="center" sx={{ cursor: 'pointer', justifyContent: 'flex-end' }} flexDirection= 'row'>
//        <Link to="/home" style={{textDecoration: 'none', color : activeTab === 'Home' ? 'primary' : 'inherit'}}>
//         <div style={{ display: 'flex', alignItems: 'center',  }}>
//          <HomeIcon fontSize="small" />
//          <Typography variant="subtitle1" >
//           Home
//          </Typography>
//          </div> 
//         </Link>
//       </Grid>
     
//       <Grid item onClick={() => onTabChange('Send Money')} sx={{ cursor: 'pointer', justifyContent: 'flex-end' }}>
//        <div style={{ display: 'flex', alignItems: 'center' }}>
//         <SwapHorizIcon fontSize="small" color={activeTab === 'Home' ? 'primary' : 'inherit'}/>
//          <Typography variant="subtitle1" sx={{ fontWeight: activeTab === '' ? '700' : 'normal' }}>
//           Transactions
//          </Typography>
//         </div>
//       </Grid>
    
//       <Grid item onClick={() => onTabChange('Account')} sx={{ cursor: 'pointer', justifyContent: 'flex-end' }}>
//         <Typography variant="subtitle1" sx={{ fontWeight: activeTab === 'Account' ? 'bold' : 'normal', display:'flex' }}>
//           SVPDd
//         </Typography>
//       </Grid>
     
//       <Grid item onClick={() => onTabChange('Help')} sx={{ cursor: 'pointer', justifyContent: 'flex-end' }}>
//       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
//         <SettingsIcon fontSize="small" color={activeTab === 'Home' ? 'primary' : 'inherit'}/>
//         <Typography variant="subtitle1" style={{display:'flex' ,justifyContent: 'flex-end'}}>
//           Settings
//         </Typography>
//         </div>
//       </Grid>
     
//     </Grid>
//     <Grid item sx={{paddingTop: 5}}>
//     <HomeScreen />
//     </Grid>
//     </Grid>
//   );
// };

// export default Navbar;


// import React from 'react';
// import { makeStyles } from '@mui/styles';
// import { AppBar, Toolbar, Typography, Button } from '@mui/material';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   title: {
//     flexGrow: 1,
//   },
// }));

// const Navbar: React.FC = () => {
//   const classes = useStyles();

//   return (
//     <div className={classes.root}>
//       <AppBar position="static">
//         <Toolbar>
//           <Typography variant="h6" className={classes.title}>
//             Your Logo
//           </Typography>
//           <Button color="inherit">Home</Button>
//           <Button color="inherit">About</Button>
//           <Button color="inherit">Services</Button>
//           <Button color="inherit">Contact</Button>
//         </Toolbar>
//       </AppBar>
//     </div>
//   );
// };

// export default Navbar;






// import React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import Grid from '@mui/material/Grid';
// import Star from '@mui/icons-material/Home';

// const Navbar: React.FC = () => {
//   return (
//     <AppBar position="static">
//       <Toolbar>
//         <Grid container alignItems="center" spacing={2}>
//           <Grid item style={{justifyContent:'flex-end'}}>
//             <IconButton edge="start" color="inherit" aria-label="" >
//               <Star />
//             </IconButton>
//           </Grid>
//           <Grid item sx={{ flexGrow: 1 }}>
//             <Typography variant="h6" align="right">
//               Your App Name
//             </Typography>
//           </Grid>
//           {/* You can add more Grid items for additional navbar elements */}
//         </Grid>
//       </Toolbar>
//     </AppBar>
//   );
// }

// export default Navbar;