// store.js
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/reducers";
//import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});


export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
export default store;
